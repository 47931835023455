<template>
    <section class="join-us-section">
        <div class="container title-container">
            <div class="cont-heading text-center">
                <h1>Join Us</h1>
                <p>
                    At SOYNET we thrive towards becoming leader in the inference accelerator market and hence we encourage talent who can resonate with similar passion. We strive towards maintaining equal opportunity irrespective of nationality, color, gender, sexual orientation etc. as talent is foremost important for us
                </p>
            </div>
        </div>

        <div class="divider mx-auto"></div>

        <!--<div class="job-swiper text-center">-->
        <!--    <swiper navigation :pagination="{clickable: true}" :centeredSlides="true" :slidesPerView="1" :spaceBetween="30" class="career-swiper">-->
        <!--        <swiper-slide v-for="{ thumbnail, text, name } in employees" class="bg-swiper">-->
        <!--            <div class="container">-->
        <!--                <div class="job-slide">-->
        <!--                    <div class="text-center mb-4">-->
        <!--                        <span class="fa-stack fa-3x">-->
        <!--                            <i class="fas fa-circle fa-stack-2x text-white"></i>-->
        <!--                            <i class="fas fa-user fa-stack-1x text-muted"></i>-->
        <!--                        </span>-->
        <!--                    </div>-->
        <!--                    <p>-->
        <!--                        {{ text }}-->
        <!--                    </p>-->
        <!--                    <h5>-->
        <!--                        {{ name }}-->
        <!--                    </h5>-->
        <!--                </div>-->

        <!--            </div>-->
        <!--        </swiper-slide>-->
        <!--    </swiper>-->
        <!--</div>-->
    </section>
</template>
<style scoped>
    .cont-heading {
        max-width: 957px;
        margin: 0 auto;
    }

    .cont-heading h1 {
        font-weight: bold;
        font-size: 36px;
        line-height: 54px;
        letter-spacing: -0.01em;

        color: #EA5B33;
    }

    .cont-heading p {
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.01em;

        color: #000000;
    }

    .job-swiper {
        background: #F0F0F0;
        padding: 30px 0 50px;
    }

    .job-slide {
        background: #F0F0F0;
        max-width: 723px;
        margin: 0 auto;
    }

    .job-slide p {
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.01em;
    }

    .job-slide h5 {
        font-size: 14px;
        line-height: 30px;
        letter-spacing: -0.01em;
    }

    .title-container {
        margin: 90px auto 78px;
    }

    .divider {
        width: 50px;
        margin: 90px 0px;
        height: 1px;
        border: 2px solid;
        border-color: rgb(249, 99, 50);
    }

    @media screen and (max-width : 576px) {
        .cont-heading {
            max-width: 319px;
        }

        .cont-heading h1 {
            font-size: 24px;
            line-height: 36px;
        }

        .cont-heading p {
            font-size: 14px;
        }

        .title-container {
            margin: 40px auto 55px;
        }

        .job-slide p {
            font-size: 14px;
        }

        .job-slide h5 {
            font-size: 12px;
        }

        .job-slide {
            max-width: 330px;
        }

        .job-swiper {
            background: #F0F0F0;
            padding: 0 0 45px;
        }
    }
</style>
<script>
    import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';
    import image1 from '@/assets/images/career1.png'

    export default {
        components: {
            Swiper,
            SwiperSlide
        },
        data() {
            return {
                employees: [{
                    name: 'Chief Strategy Officer , Alexander Park',
                    text: 'Solution SoyNet is a high-speed reasoning (execution) engine developed based on C/C++ designed to bring speed to your AI Inference and help your reduce time to market in AI. ',
                    thumbnail: image1
                }, ]
            }
        }
    }
</script>
