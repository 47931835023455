import User from "./User";
import { ref } from "vue";
import { setApiEndpoint } from "./setFunction";

const api_hostname = setApiEndpoint(); // api 주소
const userList = ref({}); // userList 원본
const filterUserList = ref({}); // userList에 검색데이터 필터링
const sliceUserList = ref({}); // userList를 정렬, 페이지 수만큼
const userListPages = ref({}); // userList 페이징 버튼
const sliceUserPageBtn = ref({}); // 페이지 버튼 최대개수만큼 담긴다
const showPageBtnLen = 5; // 페이지 버튼 개수 제한
const showItemLen = 10; // 페이지당 보여질 사람수

// api-gateway endpoint
const API_ENDPOINT = `${api_hostname}/admin/userlist`;

// userList 데이터 받기 Input : 페이지 번호, 오름/내림 차순, 정렬 종류, 카테코리, 검색 입력값
const getUserList = async (
    pageNum,
    sortAscParam,
    sortKindParam,
    categoryParam,
    inputDataParam
) => {
    const response = await fetch(API_ENDPOINT, {
        headers: {
            Authorization: await User.getAccessToken(),
        },
    });

    if (!response.ok) {
        throw new Error("network error");
    }

    userList.value = await response.json(); // 받아온 데이터를 객체로 변환
    await sortingList(sortAscParam, sortKindParam); // 받아온 데이터를 정렬

    // 사용자가 검색 했을경우 데이터 필터링
    filterUserList.value = await userList.value.filter((valueParam) =>
        categoryParam === null
            ? valueParam
            : valueParam[categoryParam]
                  .toLowerCase()
                  .indexOf(inputDataParam) !== -1
    );

    // 페이지 버튼 개수 구하기
    const pageBtn =
        filterUserList.value.length % showItemLen === 0
            ? Math.floor(filterUserList.value.length / showItemLen)
            : Math.floor(filterUserList.value.length / showItemLen + 1);

    // 페이지 버튼
    userListPages.value = new Array(pageBtn).fill(0).map((res, cnt) => cnt + 1);

    // 페이지 버튼이 최대 정해진수 만큼 나오게 하기 위해 정해진수마다 block이 1씩 추가.
    const pageBlock = Math.ceil(pageNum / showPageBtnLen);

    // 페이지 버튼 블락에 수에 따라 시작점을 찾아 그 순서부터 개수만큼 나옴.
    sliceUserPageBtn.value = userListPages.value.slice(
        pageBlock * showPageBtnLen - showPageBtnLen,
        pageBlock * showPageBtnLen
    );

    // page당 보여주는 유저 모델
    sliceUserList.value = filterUserList.value.slice(
        pageNum * showItemLen - showItemLen,
        pageNum * showItemLen
    );
};

// 목록 정렬 Input : 오름/내림 차순, 정렬 종류
const sortingList = async (sortAscParam, sortKindParam) => {
    // 내림차순일경우 아니면 오름차순
    userList.value =
        sortAscParam === true
            ? await userList.value.sort((a, b) => {
                  let o1 = b[sortKindParam];
                  let o2 = a[sortKindParam];

                  if (o1 < o2) return -1;
                  if (o1 > o2) return 1;
              })
            : await userList.value.sort((a, b) => {
                  let o1 = b[sortKindParam];
                  let o2 = a[sortKindParam];

                  if (o1 > o2) return -1;
                  if (o1 < o2) return 1;
              });
};

export default {
    getUserList,
    userList,
    sliceUserList,
    userListPages,
    showPageBtnLen,
    showItemLen,
    sliceUserPageBtn,
};
