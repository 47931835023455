<template>
  <div>
    <teleport to="#destination">
      <base-modal ref="modal" modalClass="modal-lg modal-dialog-centered">
        <template #title> Update Duration </template>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">User Id</th>
                <th scope="col">Model Name</th>
                <th scope="col">License</th>
                <th scope="col">Subscription Year</th>
                <th scope="col">Activation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ model.id }}
                </td>
                <td>
                  {{ model.modelName }}
                </td>
                <td>
                  {{ model.license }}
                </td>
                <td>{{ model.subYear }} Year</td>
                <td>
                  {{ (model.modelActivation || "").toUpperCase() }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row justify-content-center">
          <div class="col-4">
            <label>
              Start Date
              <input class="w-100" type="date" v-model="startDate" />
            </label>
          </div>
          <div class="col-4">
            <label>
              End Date
              <input class="w-100" type="date" v-model="endDate" />
            </label>
          </div>
        </div>

        <template #footer>
          <base-button-with-loading :loading="loading" @confirmed="setDuration">
            Confirm
          </base-button-with-loading>
        </template>
      </base-modal>
    </teleport>
  </div>
</template>

<style scoped>
td,
th {
  max-width: 100px;
  word-break: break-word;
}

label {
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 5px;

  color: #000000;

  display: block;
}

.theme-btn {
  padding: 13.5px;
}
</style>

<script>
import BaseModal from "@/components/BaseModal.vue";
import { ref } from "vue";

import Swal from "sweetalert2";

import AdminPage from "@/compositions/AdminPage.js";

import BaseButtonWithLoading from "@/components/BaseButtonWithLoading.vue";

export default {
  components: { BaseModal, BaseButtonWithLoading },
  setup(p, { emit }) {
    const modal = ref(null); // 사용자 모델 사용기간 수정 모달 스위치
    const loading = ref(false);

    const model = ref({}); // 사용자가 사용하는 모델
    const startDate = ref(null); // 사용자가 사용하는 모델 시작 날짜
    const endDate = ref(null); // 사용자가 사용하는 모델 끝나는 날짜

    // 사용자 모델 사용기간 수정 모달 화면 띄움, 모델 정보 저장 Input : 기간 변경할 모델 데이터
    const show = (targetModel, findUserModel) => {
      model.value = targetModel;
      startDate.value = targetModel.duration.startDate
      endDate.value = targetModel.duration.endDate;
      modal.value.show();
    };

    const hide = () => modal.value.hide(); // 사용자 모델 사용기간 수정 모달 화면 숨김

    // 사용자 모델 사용기간 수정
    const setDuration = async () => {
      try {
        loading.value = true;

        if (startDate.value == null || startDate.value.length < 1) {
          throw new Error("Invalind Start Date");
        }

        if (endDate.value == null || endDate.value.length < 1) {
          throw new Error("Invalind End Date");
        }

        const { isConfirmed } = await Swal.fire({
          title: "Update Duration?",
          showCancelButton: true,
          confirmButtonText: "Yes",
          confirmButtonColor: "#E36159",
        });

        if (!isConfirmed) return;

        await AdminPage.setDuration({
          id: model.value.id,
          index: model.value.index,
          startDate: startDate.value,
          endDate: endDate.value,
        });

        emit("setDurationCompleted");

        hide();
      } catch (err) {
        Swal.fire({
          title: err.message,
          icon: "error",
          confirmButtonColor: "#E36159",
        });
      } finally {
        loading.value = false;
      }
    };

    return {
      modal,
      show,
      hide,
      loading,
      model,
      startDate,
      endDate,
      setDuration,
    };
  },
};
</script>
