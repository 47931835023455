<template>
  <teleport to="#destination">
    <base-modal ref="modal" modalClass="modal-md modal-dialog-centered">
      <template #title> Edit Referer Code </template>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">REFERER CODE</th>
              <th scope="col">PERCENT</th>
            </tr>
          </thead>
          <tbody class="keys">
            <tr>
              <td>
                <input type="text" v-model="refererCode" readonly />
              </td>
              <td>
                <input
                  type="number"
                  v-model="discountPercent"
                  placeholder="Enter Item Discount Percent"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <template #footer>
        <base-button-with-loading
          class="w-100"
          :loading="loading"
          @confirmed="editRefererCode"
        >
          Edit
        </base-button-with-loading>
      </template>
    </base-modal>
  </teleport>
</template>

<style scoped>
.keys input {
  background: #ffffff;
  border: 1.5px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;

  font-size: 15px;
  line-height: 21px;
  letter-spacing: -0.01em;

  padding: 12px;
}

.theme-btn {
  padding: 13.5px;
}

.keys td {
  word-break: break-all;
}
</style>

<script>
import BaseModal from "@/components/BaseModal.vue";
import { inject, ref } from "vue";

import RefererCodeJs from "@/compositions/RefererCode.js";
import Swal from "sweetalert2";

import BaseButtonWithLoading from "@/components/BaseButtonWithLoading.vue";

export default {
  components: { BaseModal, BaseButtonWithLoading },
  setup() {
    const id = inject("userInfo").value?.email; // 사용자 이메일

    const modal = ref(null); // 할인 코드 수정 모달 스위치
    const loading = ref(false);

    const refererCode = ref(null); // 할인코드
    const discountPercent = ref(null); // 할인율

    // 할인 코드 수정 모달 화면띄우기, 기존 할인코드와 할인율 받기 Input : 할인코드 데이터
    const show = (referer) => {
      refererCode.value = referer.code;
      discountPercent.value = referer.discountPercent;
      modal.value.show();
    };

    const hide = () => modal.value.hide(); // 할인 코드 수정 모달 화면 숨김

    // 할인 코드 수정
    const editRefererCode = async () => {
      try {
        loading.value = true;

        const { isConfirmed } = await Swal.fire({
          title: "Edit Referer Code?",
          showCancelButton: true,
          confirmButtonText: "Yes",
          confirmButtonColor: "#E36159",
        });

        if (!isConfirmed) return;

        // 할인수치가 100% 초과 0% 미만이면 에러 발생
        if (discountPercent.value > 100 || discountPercent.value < 0) {
          throw new Error("discount percent 0 ~ 100");
        }

        // 할인수치가 공백일 경우 에러발생
        if (
          discountPercent.value === "" ||
          discountPercent.value === undefined ||
          discountPercent.value === null
        ) {
          throw new Error("Input discount percent");
        }

        await RefererCodeJs.editRefererCode({
          id,
          refererCode: refererCode.value,
          discountPercent: discountPercent.value,
        });

        discountPercent.value = null; // 퍼센트 값 초기화
        await RefererCodeJs.scanRefererCode(1); // 목록 불러오기

        hide();
      } catch (err) {
        Swal.fire({
          title: err.message,
          icon: "error",
          confirmButtonColor: "#E36159",
        });
      } finally {
        loading.value = false;
      }
    };

    return {
      modal,
      show, 
      hide,
      loading,
      editRefererCode,
      refererCode,
      discountPercent,
    };
  },
};
</script>
