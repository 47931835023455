<template>
  <section class="card-section">
    <div class="container">
      <div class="cont-heading text-md-center mb-5">
        <h1>Case Studies</h1>
      </div>
      <router-link
        class="edit-controls"
        to="/case-study-editor"
        v-if="isConetentWriter"
      >
        <h1>Add Case Study</h1>
        <label>
          <i class="far fa-plus-square"></i>
        </label>
      </router-link>
      <div class="row">
        <div
          class="col-lg-4 col-md-6 col-12"
          v-for="{ customer, thumbnailKey, title, id } in caseStudies"
          :key="id"
        >
          <div class="card border-0">
            <figure>
              <img class="rounded" :src="thumbnailKey" alt="" />
            </figure>
            <div class="content">
              <div class="d-flex justify-content-between">
                <h1>{{ customer }}</h1>
                <div v-if="isConetentWriter">
                  <router-link
                    class="admin-control me-3"
                    :to="{ name: 'Case Study Editor', query: { id } }"
                    ><i class="fas fa-pencil-alt"></i
                  ></router-link>
                  <a class="admin-control" @click="deleteCaseStudy(id)"
                    ><i class="far fa-trash-alt"></i
                  ></a>
                </div>
              </div>
              <h6>
                {{ title }}
              </h6>
              <router-link
                class="router-link"
                :to="{ name: 'Case Study Post', params: { id } }"
              >
                <div class="learn-more">
                  Learn More<i class="fas fa-chevron-right"></i>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.edit-controls {
  display: block;
  text-decoration: none;
  height: 20vh;
  min-height: 145px;
  border: 1.5px dashed #bbbbbb;
  box-sizing: border-box;
  border-radius: 2px;
  margin: 0 0 50px;
}

.edit-controls h1 {
  font-size: 40px;
  font-weight: bold;
  color: #bbbbbb;
  text-align: center;

  margin: 12px 0;
}

.edit-controls label {
  width: 100%;
  font-size: 60px;
  color: #bbbbbb;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.admin-control {
  display: inline-block;
  font-size: 18px;
  cursor: pointer;
}

.router-link {
  text-decoration: none;
}

.card figure {
  width: 100%;
  padding-bottom: 70%;
  position: relative;
}

.card figure img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

.cont-heading h1 {
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  letter-spacing: -0.01em;

  color: #ea5b33;
}

.card-section {
  margin: 100px 0;
}

.content h1 {
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.01em;
  color: #000000;
}

.content h6 {
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.01em;
  color: #000000;
}

.content .learn-more {
  font-weight: bold;
  color: #e36159;
}

.content .learn-more:hover i {
  margin-left: 10px;
}

.content .learn-more i {
  margin-left: 5px;
  transition: all 0.4s ease-in-out;
}

.card {
  margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .card-section {
    margin: 32px 0 70px;
  }

  .content h6 {
    font-size: 14px;
  }
}
</style>

<script>
import CaseStudy from "@/compositions/CaseStudy.js";

import { computed, inject } from "vue";

import Swal from "sweetalert2";

export default {
  setup() {
    CaseStudy.listCaseStudies();

    const allowedRoles = ["admin", "bloger", "developer"];
    const userInfo = inject("userInfo");

    const deleteCaseStudy = async (id) => {
      const { isConfirmed } = await Swal.fire({
        title: "Delete this post?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#E36159",
      });

      if (isConfirmed && (await CaseStudy.deleteCaseStudy(id))) {
        await CaseStudy.listCaseStudies();
      }
    };

    const sortedPosts = computed(() =>
      [...CaseStudy.caseStudies.value].sort(
        ({ id: id1 }, { id: id2 }) => id2 - id1
      )
    );

    const isConetentWriter = computed(() =>
      allowedRoles.includes(userInfo.value?.authority)
    );

    return {
      caseStudies: CaseStudy.caseStudies,
      sortedPosts,
      deleteCaseStudy,
      isConetentWriter,
    };
  },
};
</script>
