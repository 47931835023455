// dateTime 구하기
export const getDateTime = () => {
    let nowTime = new Date(); // ex) Thu Apr 21 2022 10:36:59 GMT+0900 (한국 표준시)
    let nowYear = nowTime.getFullYear(); // ex) 2022
    let nowMonth = nowTime.getMonth(); // ex) 3
    let nowDate = nowTime.getDate(); // ex) 21
    let nowHours = nowTime.getHours(); // ex) 10
    let nowMinutes = nowTime.getMinutes(); // ex) 38
    let nowSeconds = nowTime.getSeconds(); // ex) 30

    // 날짜 데이터 구하기 ex) 2022.04.21
    let date_value = `${nowYear}-${("00" + (nowMonth + 1)).slice(-2)}-${(
        "00" + nowDate
    ).slice(-2)}`;

    // 시간 데이터 구하기 ex) 10:03:01
    let time_value = `${("00" + nowHours).slice(-2)}:${(
        "00" + nowMinutes
    ).slice(-2)}:${("00" + nowSeconds).slice(-2)}`;

    return `${date_value} ${time_value}`;
};

// API Endpoint 설정
export const setApiEndpoint = () => {
    const hostname = document.location.hostname; // 현재 도메인 주소 ex) soynet.io
    const API_ENDPOINT =
        hostname === "soynet.io"
            ? process.env.VUE_APP_API_ENDPOINT
            : process.env.VUE_APP_API_ENDPOINT_TEST;

    return API_ENDPOINT;
};

// 본서버일경우 true를 리턴하는 함수
export const serverClassification = () => {
    const hostname = document.location.hostname; // 현재 도메인 주소 ex) soynet.io
    if (hostname === "www.soynet.io" || hostname === "soynet.io") return true;
    return false;
};

// 객체 깊은복사, Input : 객체
export const objectClone = (objectData) => {
    let cloneObjectData = {};
    for (let cnt in objectData) {
        cloneObjectData[cnt] = objectData[cnt];
    }
    return cloneObjectData;
};

/** 객체를 URLencode형식으로 변환, Input: 객체데이터 */
export const urlencodedData = (datas) => {
    let dataArr = [];
    for (let data in datas) {
        let encodeKey = encodeURIComponent(data);
        let encodeValue = encodeURIComponent(datas[data]);
        dataArr.push(`${encodeKey}=${encodeValue}`);
    }

    dataArr = dataArr.join("&"); // ex) data=123&message=1231
    return dataArr;
};

/** 이번달에 첫날, 마지막날 구하기, Input: 첫날/마지막날 타입 데이터 Output: 20220312 */
export const getMonthFLday = (dateType) => {
    let date = new Date(); // ex) Thu Apr 21 2022 10:36:59 GMT+0900 (한국 표준시)
    let thisDateTime;
    let thisYear;
    let thisMonth;
    let thisDate;

    switch (dateType) {
        case "first":
            thisDateTime = new Date(date.getFullYear(), date.getMonth(), 1);
            break;
        case "last":
            thisDateTime = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            break;
    }

    thisYear = thisDateTime.getFullYear(); // ex) 2022
    thisMonth = ("00" + (thisDateTime.getMonth() + 1)).slice(-2); // ex) 1
    thisDate = ("00" + thisDateTime.getDate()).slice(-2); // ex) 01 OR 31

    return `${thisYear}${thisMonth}${thisDate}`;
};

/** 기준일부터 특정개월뒤 날짜 구하기, Input: x Output: {start_dt : 20220312, end_dt : 20220512} */
export const getFreeDuration = () => {
    let totalDays = 0; // 3달간의 총 일수 ex) 92 -> 92일
    const CURRENT_DATE = new Date(); // ex) Thu Apr 21 2022 10:36:59 GMT+0900 (한국 표준시)
    const CURRENT_YEAR = CURRENT_DATE.getFullYear(); // ex) 2022
    const CURRENT_MONTH = ("00" + (CURRENT_DATE.getMonth() + 1)).slice(-2); // ex) 1
    const CURRENT_DAY = ("00" + CURRENT_DATE.getDate()).slice(-2); // ex) 01 OR 31

    // 3달간 월별 일수를 구하는 로직
    for (let i = 0; i < 3; i++) {
        const thisMonthDays = new Date(
            CURRENT_YEAR,
            CURRENT_DATE.getMonth() + 1 + i,
            0
        ).getDate();

        totalDays += thisMonthDays;
    }

    // 3달뒤 끝나는 날짜 구하기 ex) Thu Aug 21 2022 10:36:59 GMT+0900 (한국 표준시)
    const CURRENT_END_DATE = new Date(
        CURRENT_DATE.getTime() + 1000 * (totalDays * 60 * 60 * 24)
    );
    const CURRENT_END_YEAR = CURRENT_END_DATE.getFullYear(); // ex) 2022
    const CURRENT_END_MONTH = ("00" + (CURRENT_END_DATE.getMonth() + 1)).slice(
        -2
    ); // ex) 1
    const CURRENT_END_DAY = ("00" + CURRENT_END_DATE.getDate()).slice(-2); // ex) 05

    return {
        start_dt: `${CURRENT_YEAR}${CURRENT_MONTH}${CURRENT_DAY}`,
        end_dt: `${CURRENT_END_YEAR}${CURRENT_END_MONTH}${CURRENT_END_DAY}`,
    };
};

// 남은 기간 측정 로직
export const durationCheck = (end_dt) => {
    const NOW_DATE = new Date(); // 현재 시간
    const splitEndDate = end_dt.split("-"); // 만료되는 날짜

    // 만료 되는 시간
    const END_DATE = new Date(
        splitEndDate[0],
        parseInt(splitEndDate[1] - 1),
        splitEndDate[2]
    );

    // 남은 기간
    const remainingDuration = END_DATE.getTime() - NOW_DATE.getTime();

    if (remainingDuration > 0) {
        return Math.floor(remainingDuration / (1000 * 60 * 60 * 24) + 1);
    }

    return false;
};
