<template>
  <div class="notification-container" v-show="isShow">
    <div ref="notification" class="toast w-100 bg-secondary text-white" role="alert" aria-live="assertive"
      aria-atomic="true" data-bs-autohide="false">
      <div class="toast-body p-3">
        <div class="row">
          <div class="col-12 col-md-11 d-flex align-items-center">
            <p>
              Notice: SOYNET uses cookie to provide necessary website
              functionality, improve your experience and analyze our traffic. By
              using our website, you agree to our
              <!-- <a href="#" @click="openPrivacyModal">Privacy policy</a> and
              <a href="#" @click="openCookiesModal">cookie policy</a>. -->
              <a href="/policy/privacy-policy">Privacy policy</a> and
              <a href="/policy/cookie-policy">cookie policy</a>.
            </p>
          </div>
          <div class="justify-content-end justify-content-md-center col-12 col-md-1 d-flex align-items-center">
            <button type="button" class="btn btn-light btn-sm my-auto" data-bs-dismiss="toast"
              @click="hideNotification">
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
    <teleport to="#destination">
      <base-modal ref="modal" modalClass="modal-xl">
        <template #title>
          <h1>{{ modalTitle }}</h1>
        </template>
        <component :is="modalComponent"></component>
      </base-modal>
    </teleport>
  </div>
</template>

<style scoped>
.notification-container {
  width: 100vw;
  z-index: 100;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 25px;
}

.toast-body p {
  font-size: 15px;
  padding: 0 21px;
  margin: 0;
}

a {
  color: #f9776e;
}

a:hover {
  color: #e36159;
}

@media screen and (max-width: 576px) {
  .toast-body p {
    font-size: 12px;
  }
}
</style>
<script>
import { ref, onMounted, markRaw } from "vue";
import { Toast } from "bootstrap";

import BaseModal from "@/components/BaseModal.vue";
import PrivacyPolicy from "@/components/PrivacyPolicy.vue";
import CookiesPolicy from "@/components/CookiesPolicy.vue";

export default {
  components: {
    BaseModal,
  },
  setup() {
    const notification = ref(null);
    const modal = ref(null);
    const modalTitle = ref(null);
    const modalComponent = ref(null);

    const toast = ref(null);
    const userCookieCheck = sessionStorage.getItem("userAgreeCookie");
    const isShow = userCookieCheck !== "true" ? ref(true) : ref(false);

    const hideNotification = () => {
      (isShow.value = false), sessionStorage.setItem("userAgreeCookie", "true");
    };

    onMounted(() => {
      toast.value = new Toast(notification.value);
      toast.value.show();
    });

    const openPrivacyModal = (e) => {
      e.preventDefault();

      modalComponent.value = markRaw(PrivacyPolicy);
      modalTitle.value = "Privacy Policy";

      modal.value.show();
    };

    const openCookiesModal = (e) => {
      e.preventDefault();

      modalComponent.value = markRaw(CookiesPolicy);
      modalTitle.value = "Cookies Policy";

      modal.value.show();
    };

    return {
      notification,
      modal,
      modalComponent,
      modalTitle,
      openPrivacyModal,
      openCookiesModal,
      hideNotification,
      isShow,
    };
  },
};
</script>
