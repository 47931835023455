import { ref, readonly } from "vue";
import User from "./User";

import { setApiEndpoint } from "./setFunction";

const api_hostname = setApiEndpoint(); // api주소
const API_ENDPOINT = `${api_hostname}/blog`; // api endpoint

const blogPosts = ref([]); // blog게시물

// blog게시물 상세 내용
const blogPost = ref({
    title: null,
    thumbnailKey: null,
    markdown: null,
    // urlTitle: null,
});

// blog게시물 목록 가져오기
const listBlogPosts = async () => {
    const response = await fetch(API_ENDPOINT, { method: "GET" });

    if (response.ok) {
        blogPosts.value = (await response.json()).Items;
    }
};

// blog게시물 상세 내용 가져오기 Input : 블로그 포스트 id
const getBlogPost = async (postId) => {
    // 블로그 목록에 post가 없을때 목록 불러오기
    if (blogPosts.value.length < 1) {
        await listBlogPosts();
    }

    const postDetails = blogPosts.value.find(({ id }) => id === postId); // post 상세 내용 받기

    const {
        title,
        contentKey,
        thumbnailKey: thumbnail,
        urlTitle,
    } = postDetails; // post 상세내용에서 데이터 받기

    const content = await fetch(contentKey, { method: "GET" }); // contentKey로 markdown 데이터 받기

    const markdown = await content.text(); // markdown에 text부분 추출

    if (content.ok) {
        blogPost.value = {
            title,
            thumbnail,
            markdown,
            urlTitle,
        };
    }
};

// blog게시물 삭제 Input : 블로그 포스트 id
const deleteBlogPost = async (postId) => {
    const request = await fetch(`${API_ENDPOINT}/${postId}`, {
        headers: {
            Authorization: await User.getAccessToken(),
        },
        method: "DELETE",
    });

    return request.ok;
};

export default {
    blogPosts: readonly(blogPosts),
    blogPost: readonly(blogPost),
    listBlogPosts,
    getBlogPost,
    deleteBlogPost,
};
