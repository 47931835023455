import User from "./User";
import { ref } from "vue";

import { setApiEndpoint } from "./setFunction";

const api_hostname = setApiEndpoint(); // api 주소
const API_ENDPOINT = `${api_hostname}/admin`; // api endpoint

const userModels = ref({}); // 사용자들이 사용하는 모델 저장
const sliceUserModels = ref({}); // 사용자들이 사용하는 모델을 페이지별 개수로 저장
const userModelpage = ref({}); // 사용자들이 사용하는 모델을 페이지별 개수 + 1 로 나눈수로 페이지 버튼 생성
const sliceModelPageBtn = ref({}); // 페이지 버튼 최대개수만큼 담긴다
const insiderChecked = ref(true); // 내부관계자 제외 체크확인
const showComponent = ref(1); // 관리자 페이지 사이드바 지정
const showItemLen = 50; // 페이지당 보여질 목록 개수
const showPageBtnLen = 5; // 페이지 버튼 개수 제한

/**  사용자가 사용하는 모델 목록 추출 Input : page번호, 검색 값, 차순 확인, 정렬 기준, 카테고리,
 *  contract 모델 기준 카테고리, contratct모델  값 카테고리 */
const scanUserModels = async (
    pageNum,
    inputDataParam,
    sortAsc,
    sortKind,
    categoryParam,
    modelOptionBase,
    modelOption
) => {
    const response = await fetch(API_ENDPOINT, {
        headers: {
            Authorization: await User.getAccessToken(),
        },
    });

    if (!response.ok) {
        throw new Error("network error");
    }
    userModels.value = (await response.json()).reduce(
        (acc, { id, models, authority }) => [
            ...acc,
            ...models.map((model, index) => ({
                id,
                index,
                ...model,
                authority: !!authority ? authority : "",
            })),
        ],
        []
    );

    // 내부 사용자 모델 보기 스위치
    userModels.value = userModels.value.filter(
        (modelValue) => authorityCheck(modelValue.authority) === true
    );

    // 검색한 데이터로만 userModels를 가져옵니다.
    userModels.value = await userModels.value.filter((valueParam) =>
        !!categoryParam === false
            ? valueParam
            : valueParam[categoryParam]
                  .toLowerCase()
                  .indexOf(inputDataParam) !== -1
    );

    // 모델의 상태 카테고리를 선택할 경우 실행
    if (!!modelOption) {
        userModels.value = await userModels.value.filter((valueParam) => {
            return !!modelOptionBase
                ? valueParam[modelOptionBase]?.toLowerCase() === modelOption
                : valueParam;
        });
    }

    await sortingList(sortAsc, sortKind);

    // page당 보여주는 유저 모델
    sliceUserModels.value = userModels.value.slice(
        pageNum * showItemLen - showItemLen,
        pageNum * showItemLen
    );

    // 페이지 버튼 개수 구하기
    const pageBtn =
        userModels.value.length % showItemLen === 0
            ? userModels.value.length / showItemLen
            : userModels.value.length / showItemLen + 1;

    // 페이지 버튼
    userModelpage.value = userModels.value
        .slice(0, pageBtn)
        .map((res, cnt) => cnt + 1);

    // 페이지 버튼이 최대 정해진수 만큼 나오게 하기 위해 정해진수마다 block이 1씩 추가.
    const block = Math.ceil(pageNum / showPageBtnLen);

    // 페이지 버튼 블락에 수에 따라 시작점을 찾아 그 순서부터 개수만큼 나옴.
    sliceModelPageBtn.value = userModelpage.value.slice(
        block * showPageBtnLen - showPageBtnLen,
        block * showPageBtnLen
    );
};

// 목록 정렬
const sortingList = async (sortAsc, kind) => {
    // 내림차순일경우 아니면 오름차순
    userModels.value =
        sortAsc === true
            ? await userModels.value.sort((a, b) => {
                  let o1 = b[kind];
                  let o2 = a[kind];
                  let p1 = a["statusFlag"];
                  let p2 = b["statusFlag"];

                  if (o1 > o2) return -1;
                  if (o1 < o2) return 1;
                  if (p1 > p2) return -1;
                  if (p1 < p2) return 1;
              })
            : await userModels.value.sort((a, b) => {
                  let o1 = b[kind];
                  let o2 = a[kind];
                  let p1 = a["statusFlag"];
                  let p2 = b["statusFlag"];

                  if (o1 < o2) return -1;
                  if (o1 > o2) return 1;
                  if (p1 > p2) return -1;
                  if (p1 < p2) return 1;
              });
};

// 사용자가 사용하는 model목록 가져오기 Input : userId
const getUserModels = async (targetUser) => {
    const response = await fetch(`${API_ENDPOINT}/${targetUser}`, {
        headers: {
            Authorization: await User.getAccessToken(),
        },
    });

    if (!response.ok) {
        throw new Error("network error");
    }

    const { id, models = [] } = await response.json();

    userModels.value = models.map((e, index) => ({
        ...e,
        index,
        id,
    }));
};

// 사용자 모델 수정시 작동 Input : userid, 모델 사용 가능 여부, 순서 번호
const setActivation = async ({ id, activationValue, index }) => {
    const response = await fetch(`${API_ENDPOINT}/${id}/activation`, {
        method: "PUT",
        headers: {
            Authorization: await User.getAccessToken(),
        },
        body: JSON.stringify({
            activationValue,
            index,
        }),
    });

    if (!response.ok) {
        throw new Error("network error");
    }
};

// 사용자 사용 일자 변경 작동 Input : userid, 순서 번호, 시작 날짜, 끝나는 날짜
const setDuration = async ({ id, index, startDate, endDate }) => {
    const response = await fetch(`${API_ENDPOINT}/${id}/duration`, {
        method: "PUT",
        headers: {
            Authorization: await User.getAccessToken(),
        },
        body: JSON.stringify({
            index,
            startDate,
            endDate,
        }),
    });

    if (!response.ok) {
        throw new Error("network error");
    }
};

// 사용자 권한 확인
const authorityCheck = (userAuthority) => {
    const allowedRoles = ["admin", "developer", "soynet"]; // 사용자 모델 목록에 안보여질 권한 등급

    return insiderChecked.value ? !allowedRoles.includes(userAuthority) : true;
};

export default {
    userModels,
    sliceUserModels,
    userModelpage,
    scanUserModels,
    getUserModels,
    setActivation,
    setDuration,
    showComponent,
    sortingList,
    sliceModelPageBtn,
    insiderChecked,
    showPageBtnLen,
};
