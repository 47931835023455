<template>
    <section class="customer-section" data-aos="fade-up">
        <div class="container">
            <div class="cont-heading text-center">
                <h1>Customers</h1>
                <h6>Customers across all the industries are using SoyNet.</h6>
            </div>

            <swiper
                navigation
                loop
                :pagination="{ clickable: true }"
                :centeredSlides="true"
                :breakpoints="breakpoints"
            >
                <swiper-slide
                    class="customer-card"
                    v-for="{ link, thumbnail, name, descriptions } in customers"
                >
                    <a :href="link" target="_blank">
                        <div class="card border-0">
                            <figure>
                                <img :src="thumbnail" alt="thumnail Img" />
                            </figure>
                            <div class="d-flex">
                                <h1>
                                    {{ name }}
                                </h1>
                                <p class="pill">customer</p>
                            </div>

                            <h6>
                                {{ descriptions }}
                            </h6>
                        </div>
                    </a>
                </swiper-slide>
            </swiper>
        </div>
    </section>
</template>

<style scoped>
.cont-heading {
    margin-bottom: 50px;
}

.cont-heading h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
    align-items: center;
    letter-spacing: -0.01em;

    color: #ea5b33;
}

.cont-heading h6 {
    max-width: 453px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    margin: 0 auto;
    letter-spacing: -0.01em;

    color: #000000;
}

.customer-section {
    padding: 90px 0;
    background: #f0f2f3;
}

.customer-card {
    margin-bottom: 60px;
}

.card {
    background: #f0f2f3;
}

.customer-section .card figure {
    width: 100%;
    height: 200px;
    background: #fafafa;
    border-radius: 5px;
    position: relative;
    margin-bottom: 20px;
}

.customer-section .card figure img {
    max-width: 70%;
    max-height: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.customer-section .card h1 {
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    color: #000000;
}

.customer-section .card .pill {
    font-weight: 500;
    font-size: 12px;
    line-height: 30px;
    color: #e36159;
    background: rgba(227, 97, 89, 0.1);
    border-radius: 5px;
    padding: 0 6px;
    height: fit-content;
    margin-left: 10px;
}

.customer-section .learn-more {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #e36159;
}

.customer-section .learn-more:hover i {
    margin-left: 10px;
}

.customer-section .learn-more i {
    margin-left: 5px;
    transition: all 0.4s ease-in-out;
}

.customer-section .card h6 {
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: #000000;
}

.customer-section a {
    text-decoration: none;
}

@media screen and (max-width: 576px) {
    .customer-section,
    .card {
        background: #ffffff;
    }

    .customer-card {
        margin-bottom: 30px;
    }

    .customer-section {
        padding: 0;
    }

    .cont-heading {
        text-align: left !important;
    }

    .cont-heading h1 {
        font-size: 24px;
    }

    .cont-heading h6 {
        margin: 0;
    }

    .customer-section .card h6 {
        font-size: 14px;
    }
}
</style>

<script>
import posco from "@/assets/images/posco.png";
import hyundai from "@/assets/images/hyundai.png";
import neuromeka from "@/assets/images/neuromeka.png";
import saltlux from "@/assets/images/saltlux.png";
import medicalstandard from "@/assets/images/medicalstandard.png";
import pintel from "@/assets/images/pintel.jpeg";
import mobiis from "@/assets/images/mobiis.png";
import seculayer from "@/assets/images/seculayer.png";
import hntech from "@/assets/images/hntech.png";
import vivans from "@/assets/images/vivans.png";
import impix from "@/assets/images/impix.png";
import aissgo from "@/assets/images/aissgo.png";

import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            // 슬라이드에 포함될 목록
            customers: [
                {
                    link: "https://www.posco.co.kr",
                    thumbnail: posco,
                    name: "POSCO",
                    descriptions: "Surface Detection",
                },
                {
                    link: "https://www.hyundai-steel.com/en/index.hds",
                    thumbnail: hyundai,
                    name: "Hyundai Steel",
                    descriptions: "Robotic Arm Setup",
                },
                {
                    link: "https://www.neuromeka.com/about",
                    thumbnail: neuromeka,
                    name: "Neuromeka",
                    descriptions: "Neuromeka",
                },
                {
                    link: "http://saltlux.com/en",
                    thumbnail: saltlux,
                    name: "Saltlux",
                    descriptions: "Saltlux",
                },
                {
                    link: "http://medicalstandard.com/ai_solution_soynet.php",
                    thumbnail: medicalstandard,
                    name: "Medical Standard",
                    descriptions: "Medical Standard",
                },
                {
                    link: "http://www.pintel.co.kr/en/index/",
                    thumbnail: pintel,
                    name: "Pintel",
                    descriptions: "Pintel",
                },
                {
                    link: "http://www.mobiis.com/",
                    thumbnail: mobiis,
                    name: "Mobiis",
                    descriptions: "Mobiis",
                },
                {
                    link: "http://www.seculayer.co.kr/",
                    thumbnail: seculayer,
                    name: "Seculayer",
                    descriptions: "Seculayer",
                },
                {
                    link: "https://hanatek.co.kr/kor/company/company.php",
                    thumbnail: hntech,
                    name: "HANATECH SYSTEM",
                    descriptions: "HANATECH SYSTEM",
                },
                {
                    link: "https://www.vivans.net/",
                    thumbnail: vivans,
                    name: "VIVANS",
                    descriptions: "VIVANS",
                },
                {
                    link: "http://www.impix.co.kr/",
                    thumbnail: impix,
                    name: "IMPIX",
                    descriptions: "IMPIX",
                },
                {
                    link: "http://www.aissgo.com",
                    thumbnail: aissgo,
                    name: "AISSGO",
                    descriptions: "AISSGO",
                },
            ],
            breakpoints: {
                // width사이즈별 슬라이드 속도, 보여지는 card수
                576: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                992: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
            },
        };
    },
};
</script>
