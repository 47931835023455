<template>
  <teleport to="#destination">
    <base-modal ref="modal" modalClass="modal-md modal-dialog-centered">
      <template #title> Change Password </template>

      <div class="row h-100 align-items-start details">
        <div>
          <h2>Email :</h2>
          <p>
            {{ email }}
          </p>
        </div>
        <div class="row h-100 align-items-start details">
          <label>
            Current Password :
            <input placeholder="Enter Current Password" type="password" v-model="currentPW"/>
          </label>
        </div>
        <div class="row h-100 align-items-start details">
          <label>
            New Password :
            <input placeholder="Enter New Password" type="password" v-model="newPW"/>
          </label>
        </div>
        <div class="row h-100 align-items-start details">
          <label>
            Confirm New Password :
            <input placeholder="Enter Confirm New Password" type="password" v-model="confirmNewPW"/>
          </label>
        </div>
      </div>

      <template #footer>
        <base-button-with-loading :loading="loading" @confirmed="confirmChangePW">
          Submit
        </base-button-with-loading>
      </template>
    </base-modal>
  </teleport>
</template>

<style scoped>
input {
  background: #ffffff;
  border: 1.5px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;

  font-size: 15px;
  line-height: 21px;
  letter-spacing: -0.01em;

  padding: 12px;

  margin: 0 0 12px;
}

input:focus {
  outline: none;
}

label,
h2 {
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 5px;

  color: #000000;

  display: block;
}
</style>

<script>
import { validateSpace, validateEmpty } from "@/utils/validator.js"; // 조건식
import BaseModal from "@/components/BaseModal.vue";
import User from "@/compositions/User.js";

import { ref, inject } from "vue";

import BaseButtonWithLoading from "@/components/BaseButtonWithLoading.vue";

import Swal from "sweetalert2";

export default {
  components: { BaseModal, BaseButtonWithLoading },
  setup() {
    const userInfo = inject("userInfo");

    const modal = ref(null);
    const email = ref(userInfo.value.email); // 사용자 이메일
    const currentPW = ref(null); // 현재 비밀번호
    const newPW = ref(null); // 바꿀 비밀번호
    const confirmNewPW = ref(null); // 바꿀 비밀번호 확인

    const loading = ref(false);

    const show = () => modal.value.show();

    // 비밀번호 변경 처리
    const confirmChangePW = async () => {
      try {
        loading.value = true;

        const { isConfirmed } = await Swal.fire({
          title: "Are you sure you want to change your password?",
          showCancelButton: true,
          confirmButtonText: "Yes",
          confirmButtonColor: "#E36159",
        });

        if (!isConfirmed) return;

        // 비밀번호 조건
        if (!validateEmpty(newPW.value, confirmNewPW.value)) {
          throw new Error("empty input");
        }

        if (!validateSpace(newPW.value, confirmNewPW.value)) {
          throw new Error("space in password");
        }

        if (newPW.value !== confirmNewPW.value) {
          throw new Error("wrong confirm password");
        }

        if (newPW.value.length < 8) {
          throw new Error("password must be at least 8 characters");
        }

        await User.changePassword({
          email: email.value,
          oldPassword: currentPW.value,
          newPassword: newPW.value,
        });

        Swal.fire({
          title : "Password change complete",
          icon : "success",
          confirmButtonColor: "#E36159",
        });

        currentPW.value = null;
        newPW.value = null;
        confirmNewPW.value = null;

        modal.value.hide();
      } catch (err) {
        Swal.fire({
          title: err.message,
          icon: "error",
          confirmButtonColor: "#E36159",
        });
      } finally {
        loading.value = false;
      }
    };

    return {
      modal,
      show,
      loading,
      email,
      currentPW,
      newPW,
      confirmNewPW,
      confirmChangePW,
    };
  },
};
</script>