<template>
  <div class="thank-you-page container text-center">
    <h1>Thank you.</h1>
    <h2 class="mb-4">Your order is successfully completed</h2>
    <p class="mb-4 message">
      You will receive an email from DocuSign with the model details please sign
      the document and complete the payment. Once the payment is completed, we
      will activate your subscription support.
    </p>
    <p class="note">
      Note: Payment is applicable only for Standard and Enterprise Support. In
      case you chose free subscription but need support in future please contact
      us by sending us email on
      <a href="mailto:support@soynet.io">support@soynet.io</a> or fill query on
      contact us page. We will definitely get back to you.
    </p>

    <hr />
    <router-link class="theme-btn-2 d-inline-block" to="/market"
      >Continue To Model Market</router-link
    >
  </div>
</template>
<style scoped>
.thank-you-page {
  margin: 200px auto;
}

h1 {
  font-weight: bold;
  font-size: 48px;
  line-height: 60px;
  color: #ea5b33;
}

h2 {
  font-size: 28px;
  line-height: 36px;
  font-weight: bold;
  color: #ea5b33;
}

.message {
  max-width: 950px;
  margin: 0 auto;
  font-size: 20px;
}

.note {
  max-width: 750px;
  margin: 0 auto;
  font-size: 15px;
}

hr {
  background-color: #ea5b33;
  height: 3px;
  width: 30px;
  margin: 30px auto;
  opacity: 1;
}

@media screen and (max-width: 576px) {
  .thank-you-page {
    margin: 100px auto;
  }

  h1 {
    font-size: 36px;
    line-height: 40px;
  }

  h2 {
    font-size: 24px;
    line-height: 30px;
  }

  p {
    font-size: 16px;
  }
}
</style>

<script>
export default {};
</script>
