<template>
  <section class="other-section">
    <div class="container">
      <div class="heading-cont">
        <h1>See our other models</h1>
        <h6>Select and compare the best suited model optimized by SoyNet</h6>
      </div>
      <div class="card-cont">
        <div class="row">
          <template v-for="{
            modelName,
            modelSequence,
            thumbnailKey,
            summary,
            disableFlag
          } in models" :key="modelSequence">
            <div class="col-lg-4 col-md-6 col-12 mb-5" v-if="!disableFlag">
              <a :href="`/market-detail/${modelSequence}`">
                <div class="card border-0">
                  <figure class="m-0">
                    <img class="w-100 rounded" :src="thumbnailKey" alt="" />
                  </figure>
                  <div class="content">
                    <div class="d-flex justify-content-between">
                      <h1>{{ modelName }}</h1>
                    </div>
                    <h6>
                      {{ summary }}
                    </h6>
                    <p>View More <i class="fas fa-chevron-right"></i></p>
                  </div>
                </div>
              </a>
            </div>
          </template>

        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.other-section {
  padding-top: 120px;
}

.heading-cont h1 {
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  align-items: center;
  letter-spacing: -0.01em;
  color: #e36159;
}

.heading-cont h6 {
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.01em;
  color: #000000;
}

.card-cont {
  padding-top: 50px;
}

.other-section a {
  text-decoration: none;
}

.other-section .card h1 {
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.01em;
  color: #000000;
}

.other-section .card h6 {
  font-size: 16px;
  line-height: 30px;
  /* or 187% */
  letter-spacing: -0.01em;
  color: #000000;
}

.other-section .card p {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #e36159;
  margin: 20px 0 0;
}

.other-section .card figure {
  position: relative;
  padding-top: 70%;
}

.other-section .card figure img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.other-section .card p:hover i {
  margin-left: 8px;
}

.other-section .card p i {
  margin-left: 2px;
  transition: all 0.4s ease-in-out;
}

@media screen and (max-width: 576px) {
  .other-section {
    padding-top: 60px;
  }

  .other-section .card h6 {
    font-size: 14px;
  }

  .heading-cont h1 {
    font-size: 24px;
  }

  .heading-cont h6 {
    font-size: 14px;
  }
}
</style>

<script>
import model from "@/compositions/Model.js";

export default {
  setup() {
    model.listModels();

    return {
      models: model.models,
    };
  },
};
</script>
