<template>
    <div class="auth-form">
        <div class="d-flex justify-content-between">
            <h1>Sign Up</h1>
            <div class="sign-up-link">
                <p>
                    Have an Account yet?
                    <a href="/auth/sign-in">Sign In</a>
                </p>
            </div>
        </div>

        <div class="sign-up-form-input">
            <label>
                Full Name
                <input
                    type="text"
                    v-model="name"
                    placeholder="Enter your name"
                />
            </label>
        </div>
        <div class="sign-up-form-input">
            <label>
                Work Email
                <input
                    type="email"
                    v-model="email"
                    placeholder="Enter your work email"
                />
            </label>
        </div>
        <div class="sign-up-form-input">
            <label>
                Country
                <country-select
                    v-model="country"
                    :countryName="true"
                    :country="country"
                    :autocomplete="true"
                    class="country-select"
                />
            </label>
        </div>
        <div class="sign-up-form-input">
            <label>
                Job Title
                <input
                    type="tel"
                    v-model="jobtitle"
                    placeholder="Enter your job title"
                />
            </label>
        </div>
        <div class="sign-up-form-input">
            <label>
                Company Name
                <input
                    type="text"
                    v-model="companyName"
                    placeholder="Enter your company name"
                />
            </label>
        </div>
        <!-- <div class="sign-up-form-input">
      <label>
        Phone Number (optional)
        <input type="tel" v-model="phoneNumber" placeholder="Enter your phone number" />
      </label>
    </div> -->
        <div class="sign-up-form-input">
            <label>
                Password
                <input
                    type="password"
                    v-model="password"
                    placeholder="Create your password"
                />
            </label>
        </div>
        <div class="sign-up-form-input">
            <label>
                Confirm Password
                <input
                    type="password"
                    v-model="confirmPassword"
                    placeholder="Confirm your password"
                />
            </label>
        </div>
        <div class="sign-up-form-input" style="text-align: center">
            <VueRecaptcha
                :sitekey="siteKey"
                :load-recaptcha-script="true"
                @verify="handleSuccess"
                @error="handleError"
            >
            </VueRecaptcha>
        </div>
        <div class="form-check sign-up-form-checkbox">
            <input
                class="form-check-input"
                type="checkbox"
                id="agree-checkbox"
                v-model="agreeWithTerms"
            />
            <label class="form-check-label" for="agree-checkbox">
                Yes, I understand and accept the
                <a href="#" @click="openTermsAndConditionsModal"
                    >Terms of Service</a
                >
                and <a href="#" @click="openPrivacyModal">Privacy Policy</a> and
                consent to my information being used as above.
            </label>
        </div>
        <div>
            <base-button-with-loading
                class="w-100"
                :loading="loading"
                @confirmed="signUp"
            >
                Sign Up
            </base-button-with-loading>
        </div>

        <teleport to="#destination">
            <base-modal ref="modal" modalClass="modal-xl">
                <template #title>
                    <h1>{{ modalTitle }}</h1>
                </template>
                <component :is="modalComponent"></component>
            </base-modal>
        </teleport>
    </div>
</template>

<style scoped>
.auth-form {
    -ms-overflow-style: none;
    position: absolute;
    padding: 15px 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-height: 100%;
    overflow: auto;
    max-width: 500px;
}

.auth-form::-webkit-scrollbar {
    display: none;
}

.auth-form h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.01em;
    margin-bottom: 25px;

    color: #000000;
}

.sign-up-link {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.sign-up-link p {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.01em;
    margin: 0;

    color: #000000;
}

.sign-up-link a {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.01em;
    color: #e36159;
    text-decoration: none;
}

.forgot-password {
    display: inline-block;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 40px;
}

.sign-up-form-input label {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #000000;
    margin-bottom: 15px;

    display: block;
}

.sign-up-form-input input,
.sign-up-form-input .country-select {
    background: #ffffff;
    border: 1.5px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;

    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.01em;

    padding: 15px;

    margin-top: 5px;
}

.sign-up-form-checkbox {
    margin-bottom: 40px;
}

.sign-up-form-checkbox label {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: -0.01em;
}

@media screen and (max-width: 991px) {
    .auth-form {
        padding: 17px;
    }

    .auth-form h1 {
        font-size: 24px;
        line-height: 30px;
    }

    .auth-form p {
        font-size: 14px;
        line-height: 16px;
    }

    .auth-form p a {
        font-size: 14px;
        line-height: 16px;
    }

    .sign-up-form-checkbox {
        margin-bottom: 19px;
    }
}
</style>

<script>
import { Auth } from "aws-amplify";
import {
    validateSpace,
    validateEmail,
    validateEmpty,
} from "@/utils/validator.js";
import BaseModal from "@/components/BaseModal.vue";
import BaseButtonWithLoading from "@/components/BaseButtonWithLoading.vue";
import PrivacyPolicy from "@/components/PrivacyPolicy.vue";
import TermsAndConditions from "@/components/TermsAndConditions.vue";
import { VueRecaptcha } from "vue-recaptcha";

import { shallowRef } from "vue";

import Swal from "sweetalert2";

export default {
    components: {
        BaseModal,
        BaseButtonWithLoading,
        VueRecaptcha,
    },
    data() {
        return {
            email: null, // 사용자 이메일
            password: null, // 사용자 비밀번호
            confirmPassword: null, // 비밀번호 확인
            name: null, // 사용자 이름
            // phoneNumber: null, // 사용자 전화번호
            country: null, // 사용자 국적
            companyName: null, // 사용자 회사
            agreeWithTerms: false, // 약관 동의 여부
            jobtitle: null, // 직업 분야
            modalComponent: null, // 약관 내용 본문
            modalTitle: null, // 약관 내용 제목
            botCheck: false, // 봇체크
            loading: false,
            siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY,
        };
    },
    methods: {
        async signUp() {
            // 사용자 회원 가입
            try {
                this.loading = true;
                if (
                    !validateEmpty(
                        this.password,
                        this.confirmPassword,
                        this.name,
                        this.country,
                        this.companyName,
                        this.jobtitle,
                        this.email
                    )
                ) {
                    throw new Error("empty input");
                }

                if (!this.botCheck) {
                    throw new Error("check the bot");
                }

                if (!validateEmail(this.email)) {
                    throw new Error("wrong email");
                }

                if (!validateSpace(this.password, this.confirmPassword)) {
                    throw new Error("space in password");
                }

                if (this.password !== this.confirmPassword) {
                    throw new Error("wrong confirm password");
                }

                if (this.password.length < 8) {
                    throw new Error("password must be at least 8 characters");
                }

                if (!this.agreeWithTerms) {
                    throw new Error("please agree with terms");
                }

                const { isConfirmed } = await Swal.fire({
                    title: `Is the email you entered correct?\n EMAIL : ${this.email}`,
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    icon: "question",
                    confirmButtonColor: "#E36159",
                });

                if (!isConfirmed) return;

                await Auth.signUp({
                    username: this.email,
                    password: this.password,
                    attributes: {
                        email: this.email,
                        name: this.name,
                        // "custom:phoneNumber": this.phoneNumber,
                        "custom:company": this.companyName,
                        "custom:country": this.country,
                        "custom:jobtitle": this.jobtitle,
                    },
                });

                this.$router.push({
                    path: "/auth/sign-up-confirm/",
                    query: { email: this.email, type: "emailConfirmation" },
                });
            } catch (err) {
                Swal.fire({
                    title: err.message,
                    icon: "error",
                    confirmButtonColor: "#E36159",
                });
            } finally {
                this.loading = false;
            }
        },
        openPrivacyModal(e) {
            // 사용자 개인정보 동의
            e.preventDefault();

            this.modalComponent = shallowRef(PrivacyPolicy);
            this.modalTitle = "Privacy Policy";

            this.$refs.modal.show();
        },
        openTermsAndConditionsModal(e) {
            // 사용자 이용약관
            e.preventDefault();

            this.modalComponent = shallowRef(TermsAndConditions);
            this.modalTitle = "Terms And Conditions";

            this.$refs.modal.show();
        },
        handleSuccess(response) {
            this.botCheck = true;
            // Do some validation
        },
        handleError(response) {
            // Do some validation
        },
    },
};
</script>
