<template>
    <teleport to="#destination">
        <base-modal ref="modal" modalClass="modal-md modal-dialog-centered">
            <template #title> Edit Model Config </template>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Type</th>
                            <th scope="col">ID</th>
                            <th scope="col">Name</th>
                        </tr>
                    </thead>
                    <tbody class="keys">
                        <tr>
                            <td>
                                <input
                                    type="text"
                                    v-model="configType"
                                    readonly
                                    class="border-0"
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    v-model="itemId"
                                    readonly
                                    class="border-0"
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    v-model="name"
                                    placeholder="Enter Item Name"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <template #footer>
                <base-button-with-loading
                    class="w-100"
                    :loading="loading"
                    @confirmed="editModelConfig"
                >
                    Edit
                </base-button-with-loading>
            </template>
        </base-modal>
    </teleport>
</template>

<style scoped>
.keys input {
    background: #ffffff;
    border: 1.5px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;

    font-size: 15px;
    line-height: 21px;
    letter-spacing: -0.01em;

    padding: 12px;
}

.theme-btn {
    padding: 13.5px;
}

.keys td {
    word-break: break-all;
}
</style>

<script>
import BaseModal from "@/components/BaseModal.vue";
import { ref } from "vue";

import ModelConfig from "@/compositions/ModelConfig.js";
import Swal from "sweetalert2";

import BaseButtonWithLoading from "@/components/BaseButtonWithLoading.vue";

export default {
    components: { BaseModal, BaseButtonWithLoading },
    setup() {
        const modal = ref(null); // 모델 설정 수정 모달 스위치
        const loading = ref(false);

        const configType = ref(null); // 모델 설정 항목 타입
        const itemId = ref(null); // 모델 설정 항목 id
        const name = ref(null); // 모델 설정 항목 이름

        // 모델 설정 수정 모달창 화면 띄우기, 기존 데이터를 저장 Input : 모델 설정 데이터
        const show = (configs) => {
            configType.value = configs.configType;
            itemId.value = configs.id;
            name.value = configs.name;
            modal.value.show();
        };

        const hide = () => modal.value.hide(); // 모델 설정 수정 모달 닫기

        // 모델 설정 수정
        const editModelConfig = async () => {
            try {
                loading.value = true;

                const { isConfirmed } = await Swal.fire({
                    title: "Edit Model Config?",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    confirmButtonColor: "#E36159",
                });

                if (!isConfirmed) return;

                await ModelConfig.editModelConfig({
                    configType: configType.value,
                    itemId: itemId.value,
                    name: name.value,
                });

                hide();
            } catch (err) {
                Swal.fire({
                    title: err.message,
                    icon: "error",
                    confirmButtonColor: "#E36159",
                });
            } finally {
                loading.value = false;
            }
        };

        return {
            modal,
            show,
            hide,
            loading,
            editModelConfig,
            configType,
            itemId,
            name,
        };
    },
};
</script>
