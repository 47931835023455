<template>
    <div class="my-models container-xxl">
        <h1 class="title">My Models</h1>

        <!-- <p class="remaining-count">Remaining Count : {{ remainingCount }}</p> -->
        <p class="paid-button-area">
            <button
                class="theme-btn"
                @click="showpaidModal(signedModelList)"
                :disabled="signedModelList.length < 1"
            >
                Payment Models
            </button>
        </p>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Model Name</th>
                        <th scope="col">Model ID</th>
                        <th scope="col">Soynet<br />Version</th>
                        <th scope="col">License</th>
                        <th scope="col">Support<br />Plan</th>
                        <th scope="col">Pricing<br />Amount ($)</th>
                        <th scope="col">Duration</th>
                        <th scope="col">Discount Rate<br />(%,D/C)</th>
                        <th scope="col">Contract <br />Status</th>
                        <th scope="col">Model <br />Activation</th>
                        <th scope="col">Subscription<br />Extend</th>
                        <th scope="col">License Keys</th>
                        <th scope="col">Delete<br />Model</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(model, index) in models"
                        :key="model.modelSequence"
                    >
                        <template v-if="model.statusFlag !== 0">
                            <td>
                                {{ model.modelName }}
                            </td>
                            <td>
                                {{ model.modelId }}
                            </td>
                            <td>
                                {{ model.soynetversion }}
                            </td>
                            <td>
                                {{ model.license }}
                            </td>
                            <td>
                                {{ model.supportPlan }}
                            </td>
                            <td>
                                {{ model.pricingAmount?.toLocaleString() }}
                            </td>
                            <td>
                                <span
                                    class="d-inline-block"
                                    tabindex="0"
                                    data-bs-toggle="popover"
                                    data-bs-trigger="hover focus"
                                    :data-bs-content="`${
                                        model.duration.startDate
                                    }~${model.duration.endDate}(${
                                        durationCheck(model.duration.endDate)
                                            ? durationCheck(
                                                  model.duration.endDate
                                              )
                                            : 'end'
                                    })`"
                                    v-if="
                                        model.duration.startDate &&
                                        model.duration.endDate
                                    "
                                >
                                    <p class="m-0">
                                        <span
                                            v-if="
                                                model.subYear &&
                                                model.modelActivation ===
                                                    'activated'
                                            "
                                            class="subyear-point"
                                            >*</span
                                        >
                                        {{
                                            !!model.subYear
                                                ? `${model.subYear} Year`
                                                : "-"
                                        }}
                                    </p>
                                </span>
                                <p class="m-0" v-else>
                                    {{
                                        !!model.subYear
                                            ? `${model.subYear} Year`
                                            : "-"
                                    }}
                                </p>
                            </td>
                            <td>
                                {{ model.discountRate }} +
                                {{
                                    model.codeDiscount === undefined
                                        ? 0
                                        : model.codeDiscount
                                }}
                            </td>
                            <td>
                                {{
                                    model.contractStatus
                                        ? model.contractStatus.toUpperCase()
                                        : model.contractStatus
                                }}
                            </td>
                            <td>
                                {{ model.modelActivation.toUpperCase() }}
                            </td>
                            <td>
                                <button
                                    :disabled="
                                        model.license !== 'standard' ||
                                        model.modelActivation === 'pending' ||
                                        true
                                    "
                                    class="theme-btn"
                                    @click="extendSubscription(index, model)"
                                >
                                    Extend
                                </button>
                            </td>
                            <td>
                                <button
                                    class="theme-btn"
                                    :disabled="
                                        licenseAccess(
                                            model.duration.endDate
                                                ? model.duration.endDate
                                                : 0,
                                            model.soynetversion,
                                            model.license,
                                            model.modelActivation
                                        )
                                    "
                                    @click="
                                        showLicenseModal(
                                            index,
                                            // remainingCount,
                                            model
                                        )
                                    "
                                >
                                    Show
                                </button>
                            </td>
                            <td>
                                <button
                                    class="theme-btn"
                                    @click="deleteLicenseModel(model, index)"
                                >
                                    Delete
                                </button>
                            </td>
                        </template>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="no-item text-center" v-if="filterModels.length < 1">
            <h1>No Item</h1>
        </div>
        <license-key-modal ref="licenseModal" />
        <subscription-extention-modal ref="extentionModal" />
        <user-model-paid ref="paidModal" />
    </div>
</template>

<style scoped>
.my-models {
    margin-bottom: 120px;
}

.theme-btn {
    padding: 9px;
    font-size: 14px;
}

th,
td {
    vertical-align: middle;
    text-align: center;
}

.title {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.01em;

    color: #ea5b33;
}

.remaining-count {
    text-align: right;
    margin-bottom: 15px;
    font-weight: bold;
}

.paid-button-area {
    text-align: right;
    margin-bottom: 0px;
    font-weight: bold;
}

.subyear-point {
    color: red;
}

.no-item h1 {
    font-weight: bold;
    font-size: 50px;
    line-height: 45px;
    letter-spacing: -0.01em;

    padding-top: 100px;

    color: #9f9f9f;

    margin-bottom: 15px;
}

@media (min-width: 1400px) {
    .container-xxl {
        max-width: 1400px;
    }
}
</style>

<script>
import myPage from "@/compositions/MyPage.js";
import {
    getDateTime,
    durationCheck,
} from "../../../compositions/setFunction.js";
import { Popover } from "bootstrap";
import LicenseKeyModal from "./LicenseKeyModal.vue";
import SubscriptionExtentionModal from "./SubscriptionExtentionModal.vue";
import UserModelPaid from "./UserModelPaid.vue";
import { ref } from "vue";
import Swal from "sweetalert2";

export default {
    components: { LicenseKeyModal, SubscriptionExtentionModal, UserModelPaid },
    props: ["id"],
    setup(props) {
        const licenseModal = ref(null);
        const extentionModal = ref(null);
        const paidModal = ref(null);

        /**  사용자가 결제 해야할 모델 목록 모달 띄움, Input: 결제해야할 모델 데이터  */
        const showpaidModal = (models) => {
            paidModal.value.show(models);
        };

        /** 모델에 라이센스키 발급 모달 띄움,  Input: 모델 순서 번호, 모델 이름, 발급 가능 카운트, 모델 라이센스 등급 */
        // const showLicenseModal = (index, count, model) => {
        //     licenseModal.value.show(index, count, model);
        // };

        const showLicenseModal = (index, model) => {
            licenseModal.value.show(index, model);
        };

        /** license발행 권한 체크, Input: 소이넷 버전, 모델 라이센스 등급 */
        const licenseAccess = (
            end_dt,
            soynetversion,
            license,
            modelActivation
        ) => {
            if (modelActivation !== "activated") return true;
            if (license !== "free") {
                if (!durationCheck(end_dt)) return true;
            }
            if (soynetversion !== "v5.1") {
                //|| !soynetversion
                return true;
            }

            return false;
        };

        /** 삭제 경고창, Input: delete 종류 */
        const deleteAlert = (deleteKind) => {
            // 0은 무료, 1은 결제가 진행되지 않은 유료 모델, 2는 기간이 남은 모델, 3은 삭제 완료
            switch (deleteKind) {
                case 0:
                    return Swal.fire({
                        title: "Delete this model?",
                        showCancelButton: true,
                        icon: "warning",
                        confirmButtonText: "Yes",
                        confirmButtonColor: "#E36159",
                    });
                case 1:
                    return Swal.fire({
                        title: "The expiration date remains. Are you sure you want to delete it though?",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        icon: "warning",
                        confirmButtonColor: "#E36159",
                    });
                case 2:
                    return Swal.fire({
                        title: "Model Delete Complete",
                        icon: "success",
                        confirmButtonColor: "#E36159",
                    });
            }
        };

        // 모델 삭제, Input: 모델 정보, 모델 순서번호
        const deleteLicenseModel = async (model, modelIndex) => {
            try {
                let checkComplete = false; // 사용자가 확인 여부

                // 무료모델인지 체크
                if (model.license === "free") {
                    const { isConfirmed } = await deleteAlert(0);
                    checkComplete = isConfirmed;
                } else {
                    // 유료 모델이지만 기간이 없을경우 (계산을 하지 않았을 경우);
                    if (
                        model.duration.startDate === null &&
                        model.duration.endDate === null
                    ) {
                        const { isConfirmed } = await deleteAlert(1);
                        checkComplete = isConfirmed;
                    } else if (model.duration.endDate < new Date()) {
                        const { isConfirmed } = await deleteAlert(1);
                        checkComplete = isConfirmed;
                    }
                }

                let dateTime = getDateTime(); // 현재 시간가져오기 ex) 2023-01-31 09:30:01

                if (checkComplete) {
                    const response = await myPage.deleteModel(
                        props.id,
                        modelIndex,
                        model,
                        dateTime
                    );

                    if (!response) {
                        throw new Error("delete model not working.");
                    }

                    await deleteAlert(2);
                }

                myPage.listMyPageModels(props.id);
            } catch (err) {
                Swal.fire({
                    title: err.message,
                    icon: "error",
                    confirmButtonColor: "#E36159",
                });
            }
        };

        // 사용자 모델 목록 조회
        myPage.listMyPageModels(props.id);

        /**  기간 연장 모달 스위치, Input: 모델 순서 번호, 모델 정보 */
        const extendSubscription = (index, model) =>
            extentionModal.value.show(index, model);

        return {
            licenseModal,
            extentionModal,
            showLicenseModal,
            deleteLicenseModel,
            models: myPage.myPageModels,
            filterModels: myPage.filterModels,
            extendSubscription,
            durationCheck,
            // remainingCount: myPage.remainingCount,
            licenseAccess,
            paidModal,
            showpaidModal,
            signedModelList: myPage.signedModelList,
        };
    },
    updated() {
        Array.from(
            document.querySelectorAll('span[data-bs-toggle="popover"]')
        ).forEach((popoverNode) => new Popover(popoverNode));
    },
};
</script>
