<template>
    <section class="market-section">
        <div class="container">
            <div class="heading-cont">
                <div class="d-flex align-items-center justify-content-center">
                    <h1 class="m-0 text-center">Model Market</h1>
                    <router-link
                        class="ms-3 theme-btn-2"
                        to="/model-editor"
                        v-if="isConetentWriter"
                    >
                        Add
                    </router-link>
                </div>
            </div>
            <div class="card-cont">
                <div class="userFillterArea">
                    <div class="text-end">
                        <label for="userInput"> Search Models </label>
                        <div class="searchModelArea">
                            <input
                                id="userInput"
                                type="text"
                                placeholder="Enter Model id"
                                v-model="searchData"
                                @change="searchModel()"
                            />
                            <button class="theme-btn-2" @click="searchModel()">
                                Search
                            </button>
                        </div>
                    </div>
                    <div class="col-6 col-sm-5 col-md-4 col-lg-3 text-end">
                        <label for="category-select"> Filter Category </label>
                        <select
                            id="category-select"
                            class="form-select"
                            v-model="filterCategory"
                        >
                            <option :value="null">ALL</option>
                            <option
                                v-for="{ id, name } in modelCategories"
                                :key="id"
                                :value="id"
                            >
                                {{ name }}
                            </option>
                        </select>
                    </div>
                    <!-- <div class="modelPerformanceExcelDownArea">
          <button @click="modelPerformanceExcelDown()" class="modelPerformanceExcelDownBtn"><img src="@/assets/images/excel-icon.png" alt="excel icon" />Model Performance</button>
        </div> -->
                </div>
                <div>
                    <div v-if="isConetentWriter" class="disableCheckArea">
                        <label class="disableCheckLabel">
                            <input
                                class="form-check-input disableCheckBox"
                                type="checkbox"
                                value="modelDisableShow"
                                v-model="modelDisableShow"
                                :on-change="checkBoxSave()"
                            />
                            Show Admin Only
                        </label>
                        <a href="/model-performance"
                            >All Model Performance Data</a
                        >
                    </div>
                </div>
                <div class="row">
                    <template
                        v-for="{
                            modelName,
                            modelSequence,
                            thumbnailKey,
                            summary,
                            disableFlag,
                        } in modelList"
                        :key="modelSequence"
                    >
                        <div
                            class="col-lg-4 col-md-6 col-12 mb-5"
                            v-if="disableFlag === modelDisableShow"
                        >
                            <div class="card border-0">
                                <figure class="m-0">
                                    <img
                                        class="w-100 rounded"
                                        :src="thumbnailKey"
                                        alt=""
                                    />
                                </figure>
                                <div class="content">
                                    <div
                                        class="d-flex justify-content-between mt-3"
                                    >
                                        <h1>{{ modelName }}</h1>
                                        <div v-if="isConetentWriter">
                                            <router-link
                                                class="admin-control me-3"
                                                :to="{
                                                    name: 'Model Editor',
                                                    query: { modelSequence },
                                                }"
                                            >
                                                <i
                                                    class="fas fa-pencil-alt"
                                                ></i>
                                            </router-link>
                                            <a
                                                class="admin-control"
                                                @click="
                                                    deleteModel(modelSequence)
                                                "
                                                ><i class="far fa-trash-alt"></i
                                            ></a>
                                        </div>
                                    </div>
                                    <h6>
                                        {{ summary }}
                                    </h6>
                                    <router-link
                                        class="view-more"
                                        :to="{
                                            name: 'Market Detail',
                                            params: { modelSequence },
                                        }"
                                        >View More
                                        <i class="fas fa-chevron-right"></i
                                    ></router-link>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="no-item text-center" v-if="modelList.length < 1">
                    <h1>No Item</h1>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
.market-section {
    padding-top: 120px;
}

.heading-cont h1 {
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
    align-items: center;
    letter-spacing: -0.01em;
    color: #e36159;
}

.heading-cont h6 {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.01em;
    color: #000000;
}

.card-cont {
    padding-top: 50px;
}

.market-section a {
    text-decoration: none;
}

.market-section .card h1 {
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.01em;
    color: #000000;
}

.market-section .card h6 {
    font-size: 16px;
    line-height: 30px;
    /* or 187% */
    letter-spacing: -0.01em;
    color: #000000;
}

.market-section .view-more {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #e36159;
    margin: 20px 0 0;
}

.categories {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #e36159;
}

.market-section .view-more:hover i {
    margin-left: 8px;
}

.market-section .view-more i {
    margin-left: 2px;
    transition: all 0.4s ease-in-out;
}

.market-section .card figure {
    position: relative;
    padding-top: 70%;
}

.market-section .card figure img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.theme-btn-2 {
    padding: 8px;
    height: 35px;
}

.admin-control {
    display: inline-block;
    font-size: 20px;
    cursor: pointer;
}

.no-item h1 {
    font-weight: bold;
    font-size: 50px;
    line-height: 45px;
    letter-spacing: -0.01em;

    padding: 100px 0;

    color: #9f9f9f;
}

.userFillterArea {
    display: flex;
    flex-direction: row-reverse;
}

.category-select {
    text-align: right !important;
}

.searchModelArea {
    display: flex;
    flex-direction: row;
}

input[type="text"] {
    background: #ffffff;
    border: 1.5px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 2px;

    font-size: 15px;
    line-height: 21px;
    letter-spacing: -0.01em;
    height: 38px;
    padding: 12px;

    margin: 0 12px 12px;
}

input:focus {
    outline: none;
}

label {
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 5px;

    color: #000000;
}

.disableCheckArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    margin-bottom: 10px;
}

.disableCheckBox {
    margin: 0 5px !important;
}

.disableCheckLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    margin-right: 10px;
}

.modelPerformanceExcelDownArea {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.modelPerformanceExcelDownBtn {
    min-width: 100px;
    padding: 5px 15px;
    background-color: #eef8d5;
    color: #63af4c;
    border: None;
}

.modelPerformanceExcelDownBtn:hover {
    background-color: #d9e9b1;
}

.modelPerformanceExcelDownBtn img {
    width: 24px;
    margin-right: 8px;
}

@media screen and (max-width: 576px) {
    .market-section {
        padding-top: 60px;
    }

    .market-section .card h6 {
        font-size: 14px;
    }

    .heading-cont h1 {
        font-size: 24px;
    }

    .heading-cont h6 {
        font-size: 14px;
    }
}
</style>

<script>
import model from "@/compositions/Model.js";
import modelConfig from "@/compositions/ModelConfig.js";
import { objectClone } from "@/compositions/setFunction.js";
import { utils, writeFileXLSX } from "xlsx";

import { ref, computed, inject } from "vue";

import Swal from "sweetalert2";

export default {
    setup() {
        const allowedRoles = ["admin", "developer"]; // 관리자 권한 목록
        const userInfo = inject("userInfo"); // 사용자 정보 받아오기
        const filterCategory = ref(null); // 사용자가 찾을 카테고리 구분
        const searchData = ref(""); // 사용자가 검색할 데이터
        const modelDisableShow = ref(null); // 관리자만 볼수있는 모델 목록 여부

        // 사용자가 관리자 권한에 포함되어있는지 체크
        const isConetentWriter = computed(() =>
            allowedRoles.includes(userInfo.value?.authority)
        );

        // disabled 체크 상태가 저장 되어 있는 값 가져오기
        const getCheckBoxSave = () => {
            const storageItem = localStorage.getItem("disabledCheckBox");
            if (storageItem === "true") {
                modelDisableShow.value = true;
            } else {
                modelDisableShow.value = false;
            }
        };

        // disable 체크박스 체크값 유지
        const checkBoxSave = () => {
            localStorage.setItem("disabledCheckBox", modelDisableShow.value);
        };

        getCheckBoxSave();
        modelConfig.listConfigs();
        model.listModels(searchData.value);

        // 데이터 삭제
        const deleteModel = async (modelSequence) => {
            const { isConfirmed } = await Swal.fire({
                title: "Delete this model?",
                showCancelButton: true,
                confirmButtonText: "Yes",
                confirmButtonColor: "#E36159",
            });

            if (isConfirmed && (await model.deleteModel(modelSequence))) {
                await model.listModels();
            }
        };

        // 모델 검색
        const searchModel = () => {
            model.listModels(searchData.value);
        };

        // 선택된 카테고리별로 데이터 빼오기
        const modelList = computed(() =>
            filterCategory.value === null
                ? model.filterModels.value
                : model.filterModels.value.filter(({ categories }) =>
                      categories.some(({ id }) => id === filterCategory.value)
                  )
        );

        // model config에 카테고리 데이터를 받아옴
        const modelCategories = computed(
            () => modelConfig.modelConfigs.value.category
        );

        // 모델 성능 엑셀 다운
        const modelPerformanceExcelDown = async () => {
            let modelBanchData = []; // 모델들에 Benchmark 데이터를 담음
            let modelconsistencyData = []; // 모델들에 consistency 데이터를 담음

            // 원본 데이터가 훼손되지 않도록 깊은복사를 해줌
            let cloneModelPerformanceData =
                model.modelPerformanceData.value.slice();
            cloneModelPerformanceData = cloneModelPerformanceData.map(
                (modelData, index) => {
                    return objectClone(modelData);
                }
            );

            // 관리자만 볼수 있는 모델 구분
            cloneModelPerformanceData = cloneModelPerformanceData.filter(
                (modelData, index) =>
                    modelData.disableFlag === modelDisableShow.value
            );

            // 모델 정보 객체 Key 순서
            const modelOrder = [
                "modelName",
                "modelcode",
                "variablemodelway",
                "modelbrief",
                "thesisdate",
                "codedate",
                "modelclassification",
                "modelspecific",
                "inputdatetype",
                "inputdatashape",
                "outputdatadtype",
                "outputdatashape",
                "preproc",
                "postproc",
                "newlayer",
                "thesislink",
                "githublink",
                "framework",
                "pretrained",
                "portingstart",
                "portingend",
                "portingoperator",
                "traincodename",
                "inferencecodename",
                "weightdowncodename",
                "workcode",
            ];

            // 모델 정보 객체 Name 순서
            const nameOrder = [
                "Model Name",
                "Model Code",
                "Variable Model Way",
                "Model Brief",
                "Thesis Date",
                "Code Date",
                "Model Classification",
                "Model Specific",
                "Input Data Type",
                "Input Data Shape",
                "Output Data Dtype",
                "Output Data Shape",
                "Preproc",
                "Postproc",
                "New Layer",
                "Thesis Link",
                "Github Link",
                "Framework",
                "Pretrained",
                "Porting Start",
                "Porting End",
                "Porting Operator",
                "Train-code Name",
                "Inference-code Name",
                "Weight-down-code Name",
                "Work Code",
            ];

            // 모델 벤치마크 객체 Key 순서
            const benchmarkOrder = [
                "batchsize",
                "precision",
                "datasizeorlength",
                "soynetfps",
                "soynetgpumemory",
                "pytorchfps",
                "pytorchgpumemory",
            ];

            // 모델 벤치마크 객체 Name 순서
            const benchmarkNameOrder = [
                "Batch",
                "Precision",
                "Data Size,Length",
                "SoyNet Fps",
                "SoyNet GPU Memory",
                "Pytorch Fps",
                "Pytorch GPU Memory",
            ];

            const consistencyOrder = ["framework", "soynet", "diff"]; // 모델 정합성 객체 Key 순서

            const consistencyNameOrder = ["Framework", "Soynet", "Diff"]; // 모델 정합성 객체 Name 순서

            const modelPerformanceData = []; // 모델 Performance 데이터

            // 모델 Key순서대로 객체 저장
            cloneModelPerformanceData.map((modelData, index) => {
                modelOrder.map((data, index2) => {
                    modelPerformanceData[index] = {
                        ...modelPerformanceData[index],
                        [nameOrder[index2]]: modelData[data],
                    };
                });
            });

            // 모델 개수만큼 banchmark 데이터와 consistency데이터를 추가하기 위해 반복
            cloneModelPerformanceData.map((modelData, index) => {
                // 모델에 batch만큼 반복하여 배열에 저장
                modelData.batch
                    ? modelData.batch.map((modelBatch, index) => {
                          let modelBanchOrderArr; // batch별 데이터

                          // batch안에 batch개수만큼 데이터를 뽑아와서 저장
                          benchmarkOrder.map((data, index2) => {
                              modelBanchOrderArr = {
                                  ...modelBanchOrderArr,
                                  [benchmarkNameOrder[index2]]:
                                      modelBatch[data],
                              };
                          });

                          // modelBenchData에 순서대로 저장
                          modelBanchData = [
                              ...modelBanchData,
                              {
                                  "Model Name": modelData.modelName,
                                  "Benchmark Device": modelData.benchmarkdevice,
                                  Data: modelData.data,
                                  ...modelBanchOrderArr,
                                  "Benchmark Brief": modelData.modelbrief,
                              },
                          ];
                      })
                    : null;

                // 모델에 consistency만큼 반복하여 배열에 저장
                modelData.consistency
                    ? modelData.consistency.map((modelConsistency, index) => {
                          let modelConsistencyOrderArr; // consistency별 데이터

                          // consistency안에 consistency개수만큼 데이터를 뽑아와서 저장
                          consistencyOrder.map((data, index2) => {
                              modelConsistencyOrderArr = {
                                  ...modelConsistencyOrderArr,
                                  [consistencyNameOrder[index2]]:
                                      modelConsistency[data],
                              };
                          });

                          // modelConsistencyData에 순서대로 저장
                          modelconsistencyData = [
                              ...modelconsistencyData,
                              {
                                  "Model Name": modelData.modelName,
                                  ...modelConsistencyOrderArr,
                                  "Consistency Brief":
                                      modelData.consistencybrief,
                              },
                          ];
                      })
                    : null;
            });

            const workBook = utils.book_new(); // 빈 통합 문서를 생성
            const modelSheet = utils.json_to_sheet(modelPerformanceData); // Model Data 배열을 워크시트로 변환합니다.
            const benchSheet = utils.json_to_sheet(modelBanchData); // Model BanchMark 배열을 워크시트로 변환.
            const consistencySheet = utils.json_to_sheet(modelconsistencyData); // Model ConsistencyData 배열을 워크시트로 변환

            utils.book_append_sheet(workBook, modelSheet, "Model Info"); // 워크시트를 통합 문서에 Model Info 이름으로 추가
            utils.book_append_sheet(workBook, benchSheet, "Model BenchMark"); // 워크시트를 통합 문서에 Model BenchMark 이름으로 추가
            utils.book_append_sheet(
                workBook,
                consistencySheet,
                "Model Consistency"
            ); // 워크시트를 통합 문서에 Model consistency란 이름으로 추가

            writeFileXLSX(workBook, "ModelPerformance.xlsx"); // 위에서 만든 문서를 ModelPerformance.xlsx파일로 변환하여 사용자에게 다운로드 시킴
        };

        return {
            modelList,
            deleteModel,
            isConetentWriter,
            modelCategories,
            filterCategory,
            searchData,
            searchModel,
            modelDisableShow,
            checkBoxSave,
            modelPerformanceExcelDown,
        };
    },
};
</script>
