/*
created: 2023.04.25
version: 1.0
creator: 김영훈
Soy License와 연동시 필요한 함수 기능들
*/

import { urlencodedData, getFreeDuration } from "@/compositions/setFunction.js";

/** license 서버에서 public key 가져오기 */
export const getRsaPublicKey = async () => {
    const getRsaPublicKeyReq = await fetch(
        `${process.env.VUE_APP_LICENSE_ADDRESS}/api/oauth2/rsa/publickey?type=pem`,
        { method: "GET" }
    );

    return await getRsaPublicKeyReq.json();
};

/** license 서버에서 access 토큰 발행, Input: publicKey */
export const createAccessToken = async (publicKey) => {
    const crypto = require("crypto"); // 암호화 모듈
    const password = process.env.VUE_APP_LICENSE_PASSWORD;

    // password 암호화
    const encryptedData = crypto.publicEncrypt(
        publicKey,
        Buffer.from(password)
    );
    const encryptedPassword = encryptedData.toString("base64"); // password를 base64로 변환

    const bodyParam = {
        grant_type: "password",
        client_id: process.env.VUE_APP_LICENSE_CLIENT_ID,
        client_secret: process.env.VUE_APP_LICENSE_CLIENT_SECRET,
        email: process.env.VUE_APP_LICENSE_EMAIL,
        password: encryptedPassword,
        public_key: publicKey,
    };

    const bodyData = urlencodedData(bodyParam); // URLencodeed 데이터로 변환

    // license서버에서 키 발급을 할 수 있도록 license서버에서 access키 토큰 받기
    const createAccessTokenReq = await fetch(
        `${process.env.VUE_APP_LICENSE_ADDRESS}/api/oauth2/token`,
        {
            method: "POST",
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded;charset=UTF-8",
            },
            body: bodyData,
        }
    );

    return await createAccessTokenReq.json();
};

/** license서버에서 key 발행 */
export const getLicenseKeyData = async (
    plan,
    equipment_uuid,
    duration,
    user_id,
    model_id,
    tokenData
) => {
    let start_dt;
    let end_dt;

    if (plan !== "free") {
        start_dt = duration.startDate.replace(/-/g, "");
        end_dt = duration.endDate.replace(/-/g, "");
    } else {
        const getDuration = getFreeDuration();
        start_dt = getDuration.start_dt;
        end_dt = getDuration.end_dt;
    }
    const bodyParam = {
        plan,
        equipment_uuid,
        start_dt,
        end_dt,
        user_id,
        model_id,
    };

    const bodyData = urlencodedData(bodyParam); // URLencodeed 데이터로 변환

    const getLicenseKeyReq = await fetch(
        `${process.env.VUE_APP_LICENSE_ADDRESS}/api/oauth2/license/issuance `,
        {
            method: "POST",
            headers: {
                Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
                "Content-Type":
                    "application/x-www-form-urlencoded;charset=UTF-8",
            },
            body: bodyData,
        }
    );

    return await getLicenseKeyReq.json();
};

export const throwErrMsg = (responseData) => {
    if (!responseData.success) {
        throw new Error(responseData.data.message);
    }
};
