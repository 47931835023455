<template>
    <teleport to="#destination">
        <base-modal ref="modal" modalClass="modal-md modal-dialog-centered">
            <template #title> Edit UserInfo </template>

            <div class="row h-100 align-items-start details">
                <div>
                    <h2>Email :</h2>
                    <p>
                        {{ email }}
                    </p>
                </div>

                <div class="col-6">
                    <label>
                        Name :
                        <input placeholder="Enter Name" v-model="name" />
                    </label>
                </div>
                <div class="col-6">
                    <label>
                        Country :
                        <country-select
                            v-model="country"
                            :countryName="true"
                            :country="country"
                            :autocomplete="true"
                            class="country-select"
                        />
                    </label>
                </div>
                <div class="col-6">
                    <label>
                        Job Title :
                        <input
                            placeholder="Enter Job Title"
                            v-model="jobtitle"
                        />
                    </label>
                </div>
                <div class="col-6">
                    <label>
                        Company :
                        <input placeholder="Enter Company" v-model="company" />
                    </label>
                </div>
                <!-- <div class="col-6">
          <label>
            Phone Number :
            <input placeholder="Enter Phone Number" v-model="phoneNumber" />
          </label>
        </div> -->
            </div>

            <template #footer>
                <base-button-with-loading
                    :loading="loading"
                    @confirmed="confirmUpdate"
                >
                    Submit
                </base-button-with-loading>
            </template>
        </base-modal>
    </teleport>
</template>

<style scoped>
input,
.country-select {
    background: #ffffff;
    border: 1.5px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;

    font-size: 15px;
    line-height: 21px;
    letter-spacing: -0.01em;

    padding: 12px;

    margin: 0 0 12px;
}

input:focus {
    outline: none;
}

label,
h2 {
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 5px;

    color: #000000;

    display: block;
}
</style>

<script>
import BaseModal from "@/components/BaseModal.vue";

import { ref, inject } from "vue";

import BaseButtonWithLoading from "@/components/BaseButtonWithLoading.vue";

import Swal from "sweetalert2";

export default {
    components: { BaseModal, BaseButtonWithLoading },
    setup() {
        const userInfo = inject("userInfo");
        const updateUserInfo = inject("updateUserInfo");
        const modal = ref(null);
        const email = ref(userInfo.value.email);
        const name = ref(userInfo.value.name);
        const country = ref(userInfo.value.country);
        const company = ref(userInfo.value.company);
        // const phoneNumber = ref(userInfo.value.phoneNumber);
        const jobtitle = ref(userInfo.value.jobtitle);
        const loading = ref(false);

        const show = () => modal.value.show();

        const confirmUpdate = async () => {
            try {
                loading.value = true;

                const { isConfirmed } = await Swal.fire({
                    title: "Are you Sure to update account info?",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    confirmButtonColor: "#E36159",
                });

                if (!isConfirmed) return;

                await updateUserInfo({
                    name: name.value,
                    country: country.value,
                    company: company.value,
                    // phoneNumber: phoneNumber.value,
                    jobtitle: jobtitle.value,
                });

                const swalValue = await Swal.fire({
                    title: "UserInfo change complete",
                    icon: "success",
                    confirmButtonColor: "#E36159",
                });

                if (swalValue.isConfirmed) {
                    document.location.reload();
                }

                modal.value.hide();
            } catch (err) {
                Swal.fire({
                    title: err.message,
                    icon: "error",
                    confirmButtonColor: "#E36159",
                });
            } finally {
                loading.value = false;
            }
        };

        return {
            modal,
            show,
            loading,
            email,
            name,
            country,
            company,
            // phoneNumber,
            jobtitle,
            confirmUpdate,
        };
    },
};
</script>
