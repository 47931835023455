<template>
  <section class="case-study-editor-section">
    <div class="container">
      <div class="case-study-title">
        <div class="row">
          <div class="col-6">
            <label class="input-label">
              Title
              <input
                type="text"
                placeholder="Enter Your Title"
                v-model="title"
              />
            </label>
          </div>
          <div class="col-6">
            <label class="input-label">
              Customer
              <input
                type="text"
                placeholder="Enter the name of Customer "
                v-model="customer"
              />
            </label>
          </div>
        </div>
        <div class="thumbnail-uploader">
          <h1 v-if="thumbnail == null">Upload thumbnail image here</h1>
          <img v-else :src="thumbnail" />
          <label>
            <i class="far fa-plus-square"></i>
            <input type="file" accept="image/*" @change="previewThumbnail" />
          </label>
        </div>
      </div>

      <editor class="editor" ref="editor" :uploadImage="uploadImage"></editor>

      <div class="text-end">
        <button class="theme-btn" @click="publish">publish</button>
      </div>
    </div>
  </section>
</template>

<style scoped>
.case-study-editor-section {
  margin: 50px 0;
}

.editor {
  margin-bottom: 50px;
}

.case-study-title input,
.thumbnail-uploader {
  background: #ffffff;
  border: 1.5px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
}

.input-label {
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  letter-spacing: -0.01em;
  width: 100%;

  margin: 15px 0;
}

.input-label input {
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  letter-spacing: -0.01em;
  padding: 15px;

  margin-top: 5px;
}

.thumbnail-uploader {
  position: relative;
  padding-bottom: 45%;

  margin: 0 0 15px;
}

.thumbnail-uploader input {
  display: none;
}

.thumbnail-uploader img,
.thumbnail-uploader h1,
.thumbnail-uploader label {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.thumbnail-uploader label,
.thumbnail-uploader h1 {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.thumbnail-uploader label {
  font-size: 60px;
  color: rgba(0, 0, 0, 0);
}

.thumbnail-uploader h1 {
  font-weight: bold;
  font-size: 40px;
  color: #9b9b9b;
}

.thumbnail-uploader label:hover {
  color: #bbbbbb;
  background: rgba(255, 255, 255, 0.8);
}

.thumbnail-uploader img {
  object-fit: cover;
  object-position: center;
}

@media screen and (max-width: 576px) {
  .case-study-title input,
  .case-study-title label {
    font-size: 18px;
    line-height: 24px;
  }

  .thumbnail-uploader h1 {
    font-size: 16px;
  }
}
</style>

<script>
import Swal from "sweetalert2";

import CaseStudy from "@/compositions/CaseStudy.js";

import Editor from "@/components/Editor.vue";
import { setApiEndpoint } from "@/compositions/setFunction";

const api_hostname = setApiEndpoint();
const API_ENDPOINT =
  `${api_hostname}/case-study`;

export default {
  inject: ["getAccessToken"],
  components: {
    Editor,
  },
  data() {
    const { id } = this.$route.query;
    return {
      customer: null,
      title: null,
      thumbnailFile: null,
      thumbnail: null,
      date: null,
      markdown: null,
      id,
    };
  },
  async mounted() {
    try {
      if (this.id != null) {
        await CaseStudy.getCaseStudy(this.id);

        const { customer, title, thumbnail, markdown } =
          CaseStudy.caseStudy.value;

        this.customer = customer;
        this.title = title;
        this.thumbnail = thumbnail;
        this.markdown = markdown;

        const imageRequest = await fetch(this.thumbnail, {
          headers: {
            "Cache-Control": "no-cache",
          },
        });

        if (!imageRequest.ok) {
          throw new Error("network error");
        }

        this.thumbnailFile = await imageRequest.blob();
      }
    } finally {
      this.$refs.editor.setMarkdown(this.markdown);
    }
  },
  methods: {
    async uploadImage(imageFile) {
      const fileName = `image-${+new Date()}.png`;

      const urlRequest = await fetch(API_ENDPOINT, {
        method: "POST",
        headers: {
          Authorization: await this.getAccessToken(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prefix: "images",
          fileName,
          ContentType: "image/png",
        }),
      });

      if (!urlRequest.ok) {
        throw new Error("network error");
      }

      const { uploadURL, Key } = JSON.parse(await urlRequest.json());

      const uploadRequest = await fetch(uploadURL, {
        method: "PUT",
        body: imageFile,
      });

      if (!uploadRequest.ok) {
        throw new Error("network error");
      }

      return Key;
    },
    async uploadThumbnail(id) {
      const { type: ContentType } = this.thumbnailFile;
      const fileName = `thumbnail-${+new Date()}.${ContentType.split("/")[1]}`;

      const urlRequest = await fetch(API_ENDPOINT, {
        method: "POST",
        headers: {
          Authorization: await this.getAccessToken(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prefix: id,
          fileName,
          ContentType,
        }),
      });

      if (!urlRequest.ok) {
        throw new Error("network error");
      }

      const { uploadURL, Key } = JSON.parse(await urlRequest.json());

      const uploadRequest = await fetch(uploadURL, {
        method: "PUT",
        body: this.thumbnailFile,
      });

      if (!uploadRequest.ok) {
        throw new Error("network error");
      }

      return Key;
    },
    async uploadBlogContent(id) {
      const content = this.$refs.editor.getContent();

      const urlRequest = await fetch(API_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: await this.getAccessToken(),
        },
        body: JSON.stringify({
          prefix: id,
          fileName: `content-${+new Date()}.md`,
          ContentType: "text/markdown; charset=UTF-8",
        }),
      });

      if (!urlRequest.ok) {
        throw new Error("network error");
      }

      const { uploadURL, Key } = JSON.parse(await urlRequest.json());

      const uploadRequest = await fetch(uploadURL, {
        method: "PUT",
        body: content,
      });

      if (!uploadRequest.ok) {
        throw new Error("network error");
      }

      return Key;
    },
    async postBlogToDB({ id, contentKey, thumbnailKey }) {
      const dbRequest = await fetch(`${API_ENDPOINT}/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: await this.getAccessToken(),
        },
        body: JSON.stringify({
          title: this.title,
          customer: this.customer,
          contentKey,
          thumbnailKey,
        }),
      });

      if (!dbRequest.ok) {
        throw new Error("network error");
      }
    },
    async publish() {
      try {
        if (this.title == null || this.title.length < 1) {
          throw new Error("Title is empty");
        }

        if (this.thumbnailFile == null) {
          throw new Error("thumbnail is missing");
        }

        if (this.$refs.editor.isEmpty()) {
          throw new Error("Contents are empty");
        }

        await Swal.fire({
          title: "Publish this post?",
          showCancelButton: true,
          confirmButtonText: "Yes",
          confirmButtonColor: "#E36159",
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !Swal.isLoading(),
          allowEscapeKey: () => !Swal.isLoading(),
          preConfirm: async (isConfirmed) => {
            try {
              if (!isConfirmed) return;
              const id = this.id || +new Date();
              const thumbnailKey = await this.uploadThumbnail(id);
              const contentKey = await this.uploadBlogContent(id);

              await this.postBlogToDB({ id, thumbnailKey, contentKey });
              this.$router.push("/case-study");

              return true;
            } catch (err) {
              Swal.showValidationMessage(`Request failed: ${err}`);
            }
          },
        });
      } catch (err) {
        Swal.fire({
          title: err.message,
          icon: "error",
          confirmButtonColor: "#E36159",
        });
      }
    },
    previewThumbnail({
      target: {
        files: [file],
      },
    }) {
      if (file) {
        const reader = new FileReader();

        reader.onload = () => {
          this.thumbnail = reader.result;
        };

        this.thumbnailFile = file;

        reader.readAsDataURL(file);
      }
    },
  },
};
</script>
