<template>
  <div class="auth-form">
    <div class="d-flex justify-content-between">
      <h1>Sign In</h1>
      <div class="sign-up-link">
        <p>
          Don't have an Account yet?
          <a href="/auth/sign-up">Sign Up Now</a>
        </p>
      </div>
    </div>
    <div>
      <label>
        E-mail
        <input type="email" v-model="email" placeholder="Enter your email" />
      </label>
    </div>
    <div>
      <label>
        Password
        <input
          type="password"
          v-model="password"
          @keyup.enter="signInUser"
          placeholder="Enter your password"
        />
      </label>
    </div>
    <div class="text-end">
      <a class="forgot-password" href="/auth/reset-password"
        >Forgot you password?</a
      >
    </div>
    <div>
      <base-button-with-loading
        class="w-100"
        :loading="loading"
        @confirmed="signInUser"
      >
        Sign In
      </base-button-with-loading>
    </div>
  </div>
</template>

<style scoped>
.auth-form {
  -ms-overflow-style: none;
  position: absolute;
  padding: 15px 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: 100%;
  overflow: auto;
  max-width: 500px;
}

.auth-form::-webkit-scrollbar {
  display: none;
}

.auth-form h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.01em;
  margin-bottom: 25px;

  color: #000000;
}

.sign-up-link {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.sign-up-link p {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.01em;
  margin: 0;

  color: #000000;
}

.sign-up-link a {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.01em;
  color: #e36159;
  text-decoration: none;
}

.forgot-password {
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 40px;
}

.auth-form label {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.01em;
  color: #000000;
  margin-bottom: 15px;

  display: block;
}

.auth-form input {
  background: #ffffff;
  border: 1.5px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;

  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.01em;

  padding: 15px;

  margin-top: 5px;
}

@media screen and (max-width: 991px) {
  .auth-form {
    padding: 17px;
  }

  .auth-form h1 {
    font-size: 24px;
    line-height: 30px;
  }

  .auth-form p {
    font-size: 14px;
    line-height: 16px;
  }

  .auth-form p a {
    font-size: 14px;
    line-height: 16px;
  }
}
</style>

<script>
import Swal from "sweetalert2";

import BaseButtonWithLoading from "@/components/BaseButtonWithLoading.vue";

export default {
  components: {
    BaseButtonWithLoading,
  },
  data() {
    return {
      email: null, // 사용자 이메일
      password: null, // 사용자 비밀번호
      loading: false,
    };
  },
  inject: ["signIn"],
  methods: {
    async signInUser() {
      // 사용자 로그인
      try {
        this.loading = true;

        await this.signIn(this.email, this.password);

        this.$router.push("/about");
      } catch (err) {
        if (err.message === "User is not confirmed.") {
          this.$router.push({
            path: "sign-up-confirm",
            query: { email: this.email, type: "emailConfirmation" },
          });
          return;
        }

        Swal.fire({
          title: err.message,
          icon: "error",
          confirmButtonColor: "#E36159",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
