<template>
    <section class="job-posting-section">
        <div class="container my-5">
            <div class="cont-heading text-center w-100">
                <h1>Open Positions</h1>
                <p>
                    Following are the current opening for us.
                    <br>
                    Feel free to drop us an email : <strong>hr@soynet.io</strong>
                </p>
            </div>
            <div class="row job-posts mx-0" v-for="{ title, qualifications, type } in postings">
                <div class="col-12 col-md-6 px-0">
                    <h5>{{title}}</h5>
                </div>
                <div class="col-7 text-md-end col-md-3 px-0">
                    {{qualifications}}
                </div>
                <div class="col-5 text-end col-md-3 px-0">{{type}}</div>
                <hr>
            </div>
        </div>
    </section>
</template>
<style scoped>
    .job-posting-section {
        margin: 85px 0 240px;
    }

    .cont-heading {
        margin-bottom: 46px;
    }

    .cont-heading h1 {
        font-weight: bold;
        font-size: 36px;
        line-height: 54px;
        letter-spacing: -0.01em;

        color: #EA5B33;
    }

    .cont-heading p {
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #000000;
    }

    .job-posts hr {
        margin: 20px 0;
    }

    .job-posts h5 {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
    }

    .job-posts div {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.01em;
    }

    @media screen and (max-width : 576px) {
        .job-postion-section {
            margin: 60px 0 70px;
        }

        .cont-heading h1 {
            font-size: 24px;
            line-height: 36px;
        }
        
        .cont-heading p {
            font-size: 14px;
        }

        .job-posts h5 {
            font-size: 16px;
            line-height: 24px;
        }

        .job-posts div {
            font-size: 12px;
            line-height: 18px;
        }

    }
</style>


<script>
    export default {
        data() {
            return {
                postings: [{
                        title: 'AI researcher',
                        qualifications: 'Computer Vision Analytics, NLP, Time Series Processing.',
                        type: 'Technical'
                    },
                    {
                        title: 'Software Developer(Full Stack)',
                        qualifications: 'C++/C, CUDA, cuDNN, TensorRT, openCL, oneDNN.',
                        type: 'Technical'
                    },
                    {
                        title: 'Digital Marketing Manager (Korean)',
                        qualifications: 'SEO, SEM, B2B marketing experience.',
                        type: 'Business management'
                    },
                    {
                        title: 'Account Manager',
                        qualifications: 'Preferred sales experience in IT industry.',
                        type: 'Business management'
                    },
                    {
                        title: 'Pre-sales engineer',
                        qualifications: 'Preferred pre-sales experience.',
                        type: 'Business management'
                    },
                ]
            }
        }
    }
</script>
