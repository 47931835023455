<template>
    <div class="container-xxl admin-container">
        <h1 class="title">User Model List</h1>
        <div class="user-model-header">
            <div class="search-area">
                <select
                    id=" category-select"
                    class="form-select search-area-select"
                    v-model="filterCategory"
                >
                    <option :value="null">ALL</option>
                    <option
                        v-for="value in searchCategory"
                        :key="value"
                        :value="value"
                    >
                        {{ value.toUpperCase() }}
                    </option>
                </select>
                <input
                    id="userInput"
                    type="text"
                    placeholder="Enter User id"
                    v-model="inputSearchData"
                    @keyup="findUserModel(1)"
                    :disabled="!filterCategory"
                />
            </div>
            <div class="option-container">
                <div class="model-option-base-area">
                    <select
                        id="category-select"
                        class="form-select search-area-select"
                        v-model="modelOptionBase"
                        @change="resetModelOption()"
                    >
                        <option :value="null" selected>ALL</option>
                        <option
                            v-for="{ title, value } in modelOptionBaseCategory"
                            :key="value"
                            :value="value"
                        >
                            {{ title.toUpperCase() }}
                        </option>
                    </select>
                </div>
                <div class="model-option-area">
                    <select
                        id="category-select"
                        class="form-select search-area-select"
                        v-model="modelOption"
                        :disabled="!modelOptionBase"
                        @change="findUserModel(1)"
                    >
                        <option :value="''">ALL</option>
                        <option
                            v-for="value in getModelOption()"
                            :key="value"
                            :value="value"
                        >
                            {{ value.toUpperCase() }}
                        </option>
                    </select>
                </div>
                <div class="insider-check-box-area">
                    <label for="insiderCheck"
                        ><input
                            type="checkbox"
                            id="insiderCheck"
                            @change="findUserModel(1)"
                            v-model="insiderChecked"
                        />Excluding insiders</label
                    >
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col" @click="sortList('id')">
                            User Id
                            <template v-if="sortKind === 'id'">
                                <span v-if="sortAsc" class="sortIcon">▼</span>
                                <span v-else class="sortIcon">▲</span>
                            </template>
                            <template v-else>
                                <span class="sortIcon">▼</span>
                            </template>
                        </th>
                        <th scope="col" @click="sortList('modelName')">
                            Models
                            <template v-if="sortKind === 'modelName'">
                                <span v-if="sortAsc" class="sortIcon">▼</span>
                                <span v-else class="sortIcon">▲</span>
                            </template>
                            <template v-else>
                                <span class="sortIcon">▼</span>
                            </template>
                        </th>
                        <th scope="col" @click="sortList('modelId')">
                            ModelID
                            <template v-if="sortKind === 'modelId'">
                                <span v-if="sortAsc" class="sortIcon">▼</span>
                                <span v-else class="sortIcon">▲</span>
                            </template>
                            <template v-else>
                                <span class="sortIcon">▼</span>
                            </template>
                        </th>
                        <th scope="col" @click="sortList('license')">
                            License
                            <template v-if="sortKind === 'license'">
                                <span v-if="sortAsc" class="sortIcon">▼</span>
                                <span v-else class="sortIcon">▲</span>
                            </template>
                            <template v-else>
                                <span class="sortIcon">▼</span>
                            </template>
                        </th>
                        <th scope="col">Support<br />Plan</th>
                        <th scope="col">Pricing<br />Amount ($)</th>
                        <th scope="col" @click="sortList('subYear')">
                            Duration
                            <template v-if="sortKind === 'subYear'">
                                <span v-if="sortAsc" class="sortIcon">▼</span>
                                <span v-else class="sortIcon">▲</span>
                            </template>
                            <template v-else>
                                <span class="sortIcon">▼</span>
                            </template>
                        </th>
                        <!-- D = Duration Discount, C = Code Discount -->
                        <th scope="col">Discount<br />Rate<br />(%,D/C)</th>
                        <th scope="col">Contract <br />Status</th>
                        <th scope="col">Model <br />Activation</th>
                        <th scope="col">Model <br />Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(model, userModelIndex) in userModels"
                        :key="userModelIndex"
                    >
                        <td class="user-id">
                            {{ model.id }}
                        </td>
                        <td class="model-name">
                            {{ model.modelName }}
                        </td>
                        <td class="model-id">
                            {{ model.modelId }}
                        </td>
                        <td>
                            {{ model.license }}
                        </td>
                        <td>
                            {{ model.supportPlan }}
                        </td>
                        <td>
                            {{ model.pricingAmount?.toLocaleString() }}
                        </td>
                        <td class="duration">
                            <span
                                class="d-inline-block"
                                tabindex="0"
                                data-bs-toggle="popover"
                                data-bs-trigger="hover focus"
                                :data-bs-content="`${
                                    model.duration.startDate
                                }~${model.duration.endDate}(${
                                    durationCheck(model.duration.endDate)
                                        ? durationCheck(model.duration.endDate)
                                        : 'end'
                                })`"
                                v-if="
                                    model.duration.startDate &&
                                    model.duration.endDate
                                "
                            >
                                <p class="m-0">
                                    <span
                                        v-if="
                                            model.subYear &&
                                            model.modelActivation ===
                                                'activated'
                                        "
                                        class="subyear-point"
                                        >*</span
                                    >
                                    {{
                                        !!model.subYear
                                            ? `${model.subYear} Year`
                                            : "-"
                                    }}
                                </p>
                            </span>
                            <p class="m-0" v-else>
                                {{
                                    !!model.subYear
                                        ? `${model.subYear} Year`
                                        : "-"
                                }}
                            </p>
                            <div>
                                <button
                                    class="btn"
                                    v-if="!!model.subYear"
                                    @click="editDuration(model)"
                                >
                                    <i class="far fa-calendar-alt"></i>
                                </button>
                            </div>
                        </td>
                        <td>
                            {{ model.discountRate }} +
                            {{
                                model.codeDiscount === undefined
                                    ? 0
                                    : model.codeDiscount
                            }}
                        </td>
                        <td>
                            {{
                                model.contractStatus
                                    ? model.contractStatus.toUpperCase()
                                    : model.contractStatus
                            }}
                        </td>
                        <td class="model-activation">
                            <select
                                class="form-select"
                                @change="onModelActivationChange(model, $event)"
                            >
                                <option
                                    v-for="option in activationOptions"
                                    :key="option"
                                    :value="option"
                                    :selected="option === model.modelActivation"
                                >
                                    {{ option.toUpperCase() }}
                                </option>
                            </select>
                        </td>
                        <td>
                            <template
                                v-if="model.modelActivation === 'activated'"
                            >
                                Actived
                            </template>
                            <template
                                v-else-if="model.modelActivation === 'pending'"
                            >
                                Pending
                            </template>
                            <template v-else> Deleted </template>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="no-item text-center" v-if="userModels.length < 1">
                <h1>No Item</h1>
            </div>
            <div class="user-model-pageBtn-Area">
                <template v-if="totalPageBtn.length > showPageBtnLen">
                    <button
                        v-if="userModelPageNum !== 1"
                        @click="findUserModel(1)"
                        class="user-model-pageBtn-noActive"
                    >
                        &lt&lt
                    </button>
                    <button
                        v-if="userModelPageNum !== 1"
                        @click="findUserModel(userModelPageNum - 1)"
                        class="user-model-pageBtn-noActive"
                    >
                        &lt
                    </button>
                </template>
                <template v-for="pageNum in pageBtnList" :key="pageNum">
                    <button
                        v-if="pageNum === userModelPageNum"
                        class="user-model-pageBtn-active"
                    >
                        {{ pageNum }}
                    </button>
                    <button
                        v-else
                        @click="findUserModel(pageNum)"
                        class="user-model-pageBtn-noActive"
                    >
                        {{ pageNum }}
                    </button>
                </template>
                <template v-if="totalPageBtn.length > showPageBtnLen">
                    <button
                        v-if="userModelPageNum !== totalPageBtn.length"
                        @click="findUserModel(userModelPageNum + 1)"
                        class="user-model-pageBtn-noActive"
                    >
                        >
                    </button>
                    <button
                        v-if="userModelPageNum !== totalPageBtn.length"
                        @click="findUserModel(totalPageBtn.length)"
                        class="user-model-pageBtn-noActive"
                    >
                        >>
                    </button>
                </template>
            </div>
        </div>

        <user-model-duration-modal
            ref="durationModal"
            @setDurationCompleted="findUserModel(1)"
        />
    </div>
</template>
<style scoped>
.admin-container {
    max-width: 1440px;
}

.search-area {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin: 15px 0 30px;
}

.search-area .search-area-select {
    max-width: 200px;
    margin-right: 15px;
}

.search-area input {
    background: #ffffff;
    border: 1.5px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 2px;

    font-size: 15px;
    line-height: 21px;
    letter-spacing: -0.01em;
    padding: 10px;
}

.search-area input:focus {
    outline: none;
}

.option-container {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
}

.model-option-base-area {
    margin-right: 15px;
}

.insider-check-box-area {
    margin-left: auto;
}

.insider-check-box-area input {
    margin-right: 5px;
}

.subyear-point {
    color: red;
}

.theme-btn {
    padding: 9px;
    font-size: 14px;
}

th,
td {
    vertical-align: middle;
    text-align: center;
}

.title {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.01em;

    color: #ea5b33;
}

.sortIcon {
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
}

.no-item h1 {
    font-weight: bold;
    font-size: 50px;
    line-height: 45px;
    letter-spacing: -0.01em;

    padding-top: 100px;

    color: #9f9f9f;

    margin-bottom: 15px;
}

.user-id,
.model-id,
.model-name {
    max-width: 150px;
    min-width: 150px;
    word-break: break-word;
}

.model-activation {
    min-width: 150px;
}

.duration {
    min-width: 220px;
}

.edit-calendar {
    cursor: pointer;
}

.theme-btn-2 {
    padding: 12px 20px;
}

.user-model-pageBtn-Area {
    max-width: 1320px;
    text-align: center;
    margin-top: 15px;
}

.user-model-pageBtn-Area button {
    width: 35px;
    height: 35px;
    margin: 0px 5px;
    text-align: center;
    border: none;
    background-color: #fff;
}

.user-model-pageBtn-active {
    background-color: #ea5b33 !important;
    color: #fff;
}

.user-model-pageBtn-noActive:hover {
    background-color: #ddd;
}
</style>
<script>
import AdminPage from "@/compositions/AdminPage.js";

import { ref } from "vue";
import { Popover } from "bootstrap";

import Swal from "sweetalert2";
import { durationCheck } from "@/compositions/setFunction.js";

import UserModelDurationModal from "./UserModelDurationModal.vue";

export default {
    components: {
        UserModelDurationModal,
    },

    setup() {
        const inputSearchData = ref(null); // 사용자 모델 검색 데이터
        const filterCategory = ref(null); // 현재 검색 카테고리
        const modelOptionBase = ref(null); // 모델 상태 기준 카테고리
        const modelOption = ref(""); // 모델 상태 값 카테고리

        // 검색 카테고리
        const modelOptionBaseCategory = ref([
            {
                title: "contract status",
                value: "contractStatus",
            },
            {
                title: "model activation",
                value: "modelActivation",
            },
        ]);

        const modelOptionCategory = ref(null);
        const searchCategory = ref(["id", "modelName", "modelId"]); // 검색 카테고리
        const durationModal = ref(null); // 사용기간 수정 모달 생성
        const activationOptions = ["pending", "activated", "deactivated"]; // 사용자 모델 사용 가능 여부
        let userModelPageNum = ref(1); // 유저 모델 리스트 페이지 번호
        let sortAsc = ref(true); // 정렬 오름차순/내림차순
        let sortKind = ref("id"); // 정렬 기준

        /** 모델에 사용기간 확인  Input: 모델 정보(object), Output: 문자열*/
        const modelDurationCheck = ({ duration: { startDate, endDate } }) => {
            if (!startDate || !endDate) {
                return "";
            }
            return `${startDate}~${endDate}`;
        };

        // 모델 option 기준에 따라 값 변경
        const getModelOption = () => {
            if (modelOptionBase.value === "contractStatus") {
                return [
                    "sent",
                    "signed",
                    "paid",
                    "extend",
                    "complete",
                    "expiration",
                ];
            } else if (modelOptionBase.value === "modelActivation") {
                return ["pending", "activated", "deactivated"];
            }
        };

        // 모델 상태 카테고리 변경시 값 초기화
        const resetModelOption = () => {
            modelOption.value = "";

            findUserModel(1);
        };

        // 사용자 모델 검색 Input : 페이지 번호
        const findUserModel = async (pageNum) => {
            await AdminPage.scanUserModels(
                pageNum,
                inputSearchData.value?.toLowerCase(),
                sortAsc.value,
                sortKind.value,
                filterCategory.value,
                modelOptionBase.value,
                modelOption.value?.toLowerCase()
            );
            userModelPageNum.value = pageNum;
        };

        findUserModel(1); // 첫화면때 저장할 사용자 모델

        // 사용자 모델 목록 정렬 Input : 정렬 기준
        const sortList = async (kind) => {
            userModelPageNum.value = 1; // 페이지 번호

            // 정렬 기준이 예전과 같으면 내림차순 아니면 반대
            if (sortKind.value !== kind) {
                sortAsc.value = false;
            } else {
                sortAsc.value = !sortAsc.value;
            }
            sortKind.value = kind;
            await AdminPage.scanUserModels(
                userModelPageNum.value,
                inputSearchData,
                sortAsc.value,
                sortKind.value,
                filterCategory.value,
                modelOptionBase.value,
                modelOption.value?.toLowerCase()
            );
        };

        // 사용자 모델 사용 가능 여부 수정 Input : model사용하는 사용자 id, 사용자가 사용하는 모델중 순서, 모델의 사용 가능 여부, 변경된 모델, 변경된 모델의 사용 가능 여부,
        const onModelActivationChange = async (
            { id, index, modelActivation: oldValue },
            { target, target: { value: newValue } }
        ) => {
            const { isConfirmed } = await Swal.fire({
                title: "Would you like to change model Activation?",
                showCancelButton: true,
                confirmButtonText: "Yes",
                confirmButtonColor: "#E36159",
                showLoaderOnConfirm: true,
                backdrop: true,
                allowOutsideClick: () => !Swal.isLoading(),
                allowEscapeKey: () => !Swal.isLoading(),
                preConfirm: async () => {
                    try {
                        await AdminPage.setActivation({
                            index,
                            id,
                            activationValue: newValue,
                        });

                        return true;
                    } catch (err) {
                        Swal.showValidationMessage(`Request failed: ${err}`);
                    }
                },
            });

            if (!isConfirmed) {
                target.value = oldValue;
            }
        };

        // 사용기간 수정 Input : 모델 데이터
        const editDuration = (model) => {
            durationModal.value.show(model);
        };

        return {
            inputSearchData,
            userModels: AdminPage.sliceUserModels,
            pageBtnList: AdminPage.sliceModelPageBtn,
            totalPageBtn: AdminPage.userModelpage,
            showPageBtnLen: AdminPage.showPageBtnLen,
            activationOptions,
            onModelActivationChange,
            findUserModel,
            durationModal,
            editDuration,
            userModelPageNum,
            sortList,
            sortAsc,
            sortKind,
            filterCategory,
            searchCategory,
            insiderChecked: AdminPage.insiderChecked,
            modelOptionBase,
            modelOptionBaseCategory,
            modelOption,
            modelOptionCategory,
            getModelOption,
            resetModelOption,
            modelDurationCheck,
            durationCheck,
        };
    },
    updated() {
        Array.from(
            document.querySelectorAll('span[data-bs-toggle="popover"]')
        ).forEach((popoverNode) => new Popover(popoverNode));
    },
};
</script>
