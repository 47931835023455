<template>
  <div>
    <div ref="editorContainer"></div>
    <image-editor-modal ref="imageModal" @ok="onOk"></image-editor-modal>
  </div>
</template>

<style>
.ProseMirror,
.toastui-editor-contents {
  font-family: fieldwork, sans-serif !important;
  font-size: 18px !important;
  color: #000000;
}
</style>

<style scoped></style>

<script>
import Editor from "@toast-ui/editor";
import uml from "@toast-ui/editor-plugin-uml";

import ImageEditorModal from "./ImageEditorModal.vue";
import codeSyntaxHighlight from "@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight-all.js";

import { ref, onMounted } from "vue";

export default {
  components: {
    ImageEditorModal,
  },
  props: ["uploadImage"],
  setup(props) {
    let editor = null;
    const editorContainer = ref(null);
    const imageModal = ref(null);

    const {
      uploadImage = (imageFile) =>
        new Promise((res) => {
          const reader = new FileReader();
          reader.onload = () => res(reader.result);
          reader.readAsDataURL(imageFile);
        }),
    } = props;

    onMounted(() => {
      editor = new Editor({
        el: editorContainer.value,
        height: "75vh",
        initialEditType: "markdown",
        previewStyle: "vertical",
        plugins: [uml, codeSyntaxHighlight],
      });

      editor.removeHook("addImageBlobHook");
      editor.addHook("addImageBlobHook", (blob, callback) => {
        imageModal.value.show();
        imageModal.value.loadImageFromFile(blob);
      });
    });

    const getContent = () => editor.getMarkdown();

    const regex = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;
    const imageRegex = /.*\!\[\]\(.*\)/gi;

    const summary = () =>
      `${editor
        .getMarkdown()
        .replace(imageRegex, "")
        .replace(/\n/g, " ")
        .replace(regex, "")
        .substring(0, 200)
        .trim()}...`;

    const setMarkdown = (markdown) => editor.setMarkdown(markdown || "", false);

    const isEmpty = () =>
      editor.getMarkdown() == null ||
      editor.getMarkdown().replace(/\s/g, "").length < 1;

    const onOk = async (imageFile) =>
      editor.exec("addImage", {
        imageUrl: await uploadImage(imageFile),
        altText: "",
      });

    return {
      editorContainer,
      imageModal,
      getContent,
      summary,
      setMarkdown,
      onOk,
      isEmpty,
    };
  },
};
</script>
