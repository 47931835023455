<template>
  <div class="auth-form">
    <h1>Reset Password</h1>
    <div class="sign-up-form-input">
      <label>
        New Password
        <input type="password" v-model="password" placeholder="Enter your new password" />
      </label>
    </div>
    <div class="sign-up-form-input">
      <label>
        Confirm new Password
        <input type="password" v-model="confirmPassword" placeholder="Confirm your new password" />
      </label>
    </div>
    <div>
      <base-button-with-loading class="w-100" :loading="loading" @confirmed="setNewPassword">
        Reset Password
      </base-button-with-loading>
    </div>
  </div>
</template>

<style scoped>
.auth-form {
  -ms-overflow-style: none;
  position: absolute;
  padding: 15px 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: 100%;
  overflow: auto;
  max-width: 500px;
}

.auth-form::-webkit-scrollbar {
  display: none;
}

.auth-form h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.01em;
  margin-bottom: 25px;

  color: #000000;
}

.sign-up-form-input label {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.01em;
  color: #000000;
  margin-bottom: 15px;

  display: block;
}

.sign-up-form-input input {
  background: #ffffff;
  border: 1.5px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;

  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.01em;

  padding: 15px;

  margin-top: 5px;
}

.theme-btn {
  margin-top: 25px;
}

@media screen and (max-width: 991px) {
  .auth-form {
    padding: 17px 17px;

  }

  .auth-form h1 {
    font-size: 24px;
    line-height: 30px;
  }
}
</style>

<script>
import { validateSpace, validateEmpty } from "@/utils/validator.js";
import User from "@/compositions/User.js";
import BaseButtonWithLoading from "@/components/BaseButtonWithLoading.vue";

import Swal from "sweetalert2";

export default {
  components: {
    BaseButtonWithLoading,
  },
  data() {
    const { email, tempPassword } = this.$route.query;
    return {
      email, // 사용자 이메일
      tempPassword, // 임시 비밀번호
      password: null, // 사용자 비밀번호
      confirmPassword: null, // 비밀번호 확인
      loading: false,
    };
  },
  methods: {
    async setNewPassword() {
      // 새로운 비밀번호 등록
      try {
        this.loading = true;
        if (!validateEmpty(this.password, this.confirmPassword)) {
          throw new Error("empty input");
        }

        if (!validateSpace(this.password, this.confirmPassword)) {
          throw new Error("space in password");
        }

        if (this.password !== this.confirmPassword) {
          throw new Error("wrong confirm password");
        }

        if (this.password.length < 8) {
          throw new Error("password must be at least 8 characters");
        }

        await User.setNewPassword({
          email: this.email,
          oldPassword: this.tempPassword,
          newPassword: this.password,
        });

        this.$router.push("/auth/sign-in");
      } catch (err) {
        Swal.fire({
          title: err.message,
          icon: "error",
          confirmButtonColor: "#E36159",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
