<template>
    <article>
        <case-study-card-section />
    </article>
</template>

<script>
    import CaseStudyCardSection from './CaseStudyCardSection.vue';

    export default {
        components: {
            CaseStudyCardSection
        }
    };
</script>
