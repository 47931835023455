<template>
  <div class="container-xxl">
    <h1 class="title">Referer Code</h1>
    <div class="text-end">
      <button class="theme-btn" @click="addRefererCode()">
        Add Referer Code
      </button>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Referer Code</th>
            <th scope="col">Price</th>
            <th scope="col">Discount<br />Percent</th>
            <th scope="col">Discount<br />Price</th>
            <th scope="col">Final<br />Price</th>
            <th scope="col">Status</th>
            <th scope="col">Use User</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(refereCode, refercodeIndex) in refererCode"
            :key="refercodeIndex"
          >
            <td class="user-id">
              {{ refereCode.code }}
            </td>
            <td class="model-name">
              {{ refereCode.price.toLocaleString() }}
            </td>
            <td class="model-id">{{ refereCode.discountPercent }}%</td>
            <td class="model-id">
              {{ refereCode.discountPrice.toLocaleString() }}
            </td>
            <td>
              {{ refereCode.finalPrice.toLocaleString() }}
            </td>
            <td>
              {{ refereCode.status === 1 ? "use" : "no use" }}
            </td>
            <td>
              {{ refereCode.useUser !== "" ? refereCode.useUser : "none" }}
            </td>
            <td>
              <button
                class="btn btn-link refererBtn"
                @click="
                  editRefererCode({
                    code: refereCode.code,
                    discountPercent: refereCode.discountPercent,
                  })
                "
              >
                <i class="fas fa-cog"></i>
              </button>
              <button
                class="btn btn-link refererBtn"
                @click="
                  deleteRefererCode({
                    code: refereCode.code,
                  })
                "
              >
                <i class="fas fa-trash-alt"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="no-item text-center" v-if="refererCode.length < 1">
        <h1>No Item</h1>
      </div>
      <div class="user-model-pageBtn-Area">
        <template v-if="totalPageBtn.length > showPageBtnLen">
          <button v-if="refererCodePageNum !== 1" @click="refererCodepage(1)"
            class="user-model-pageBtn-noActive">&lt&lt</button>
          <button v-if="refererCodePageNum !== 1" @click="refererCodepage(refererCodePageNum - 1)"
            class="user-model-pageBtn-noActive">&lt</button>
        </template>
        <template v-for="pageNum in pageBtnList" :key="pageNum">
          <button v-if="pageNum === refererCodePageNum" class="user-model-pageBtn-active">
            {{ pageNum }}
          </button>
          <button v-else @click="refererCodepage(pageNum)" class="user-model-pageBtn-noActive">
            {{ pageNum }}
          </button>
        </template>
        <template v-if="totalPageBtn.length > showPageBtnLen">

          <button v-if="refererCodePageNum !== totalPageBtn.length" @click="refererCodepage(refererCodePageNum + 1)"
            class="user-model-pageBtn-noActive">></button>
          <button v-if="refererCodePageNum !== totalPageBtn.length" @click="refererCodepage(totalPageBtn.length)"
            class="user-model-pageBtn-noActive">>></button>
        </template>
      </div>
    </div>

    <user-model-duration-modal
      ref="durationModal"
      @setDurationCompleted="refererCodepage"
    />
    <referer-code-add-modal ref="inputModal" />
    <referer-code-edit-modal ref="editModal" />
  </div>
</template>
<style scoped>
.theme-btn {
  padding: 9px;
  font-size: 14px;
}

th,
td {
  vertical-align: middle;
  text-align: center;
}

.text-end {
  margin-bottom: 15px;
}

.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.01em;

  color: #ea5b33;
}

.sortIcon {
  margin-left: 5px;
  font-size: 11px;
  cursor: pointer;
}

.no-item h1 {
  font-weight: bold;
  font-size: 50px;
  line-height: 45px;
  letter-spacing: -0.01em;

  padding-top: 100px;

  color: #9f9f9f;

  margin-bottom: 15px;
}

.user-id,
.model-id,
.model-name {
  max-width: 150px;
  min-width: 150px;
  word-break: break-word;
}

.model-activation {
  min-width: 150px;
}

.duration {
  min-width: 220px;
}

.edit-calendar {
  cursor: pointer;
}

input {
  background: #ffffff;
  border: 1.5px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 2px;

  font-size: 15px;
  line-height: 21px;
  letter-spacing: -0.01em;

  padding: 12px;

  margin: 0 12px 12px;
}

input:focus {
  outline: none;
}

label {
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 5px;

  color: #000000;
}

.theme-btn-2 {
  padding: 12px 20px;
}

.user-model-pageBtn-Area {
  max-width: 1320px;
  text-align: center;
  margin-top: 15px;
}

.user-model-pageBtn-Area button {
  width: 35px;
  height: 35px;
  margin: 0px 5px;
  text-align: center;
  border: none;
  background-color: #fff;
}

.user-model-pageBtn-active {
  background-color: #ea5b33 !important;
  color: #fff;
}

.user-model-pageBtn-noActive:hover {
  background-color: #ddd;
}

.refererBtn{
  color: #6f6f6f;
  cursor: pointer;
}

.refererBtn:hover {
  color: #000000;
}
</style>
<script>
import RefererCode from "@/compositions/RefererCode.js";
import Swal from "sweetalert2";

import { inject, ref } from "vue";

import RefererCodeAddModal from "./RefererCodeAddModal.vue";
import RefererCodeEditModal from "./RefererCodeEditModal.vue";

export default {
  components: {
    RefererCodeAddModal,
    RefererCodeEditModal,
  },

  setup() {
    const id = inject("userInfo").value?.email; // 사용자 이메일

    const inputModal = ref(null); // 할인코드 추가시 모달 생성
    const editModal = ref(null); // 할인코드 수정시 모달 생성

    // 할인코드 추가
    const addRefererCode = () => {
      refererCodePageNum.value = 1;
      inputModal.value.show();
    };

    const editRefererCode = (refererCode) => editModal.value.show(refererCode); // 할인코드 수정 Input : refererCode 데이터
    let refererCodePageNum = ref(1);

    // 할인코드 삭제 Input : refererCode
    const deleteRefererCode = async ({ code }) => {
      try {
        const { isConfirmed } = await Swal.fire({
          title: "Delete Referer Code?",
          showCancelButton: true,
          confirmButtonText: "Yes",
          confirmButtonColor: "#E36159",
        });

        if (!isConfirmed) {
          return;
        }

        // 할인 코드 추가
        await RefererCode.deleteRefererCode({
          id,
          refererCode: code,
        });

        await RefererCode.scanRefererCode(1); // 목록 불러오기
      } catch (err) {
        Swal.fire({
          title: err.message,
          icon: "error",
          confirmButtonColor: "#E36159",
        });
      }
    };

    // 할인코드 페이지 버튼 변경
    const refererCodepage = async (pageNum) => {
      refererCodePageNum.value = pageNum;
      await RefererCode.scanRefererCode(pageNum);
    };

    refererCodepage(refererCodePageNum.value); // 첫화면때 저장할 사용자 모델

    return {
      refererCode: RefererCode.sliceRefererCode,
      pageBtnList: RefererCode.sliceRefererCodePageBtn,
      totalPageBtn: RefererCode.refererCodePage,
      showPageBtnLen : RefererCode.showPageBtnLen,
      refererCodepage,
      refererCodePageNum,
      addRefererCode,
      inputModal,
      editModal,
      editRefererCode,
      deleteRefererCode,
    };
  },
};
</script>
