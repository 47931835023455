<template>
    <section class="soynet-section" ref="modalOnOff" v-if="modalShow">
        <div class="event-container">
            <div class="cancelArea" @click="modalHide()">
                <img src="" alt="" />
                <span>X</span>
            </div>
            <div class="cont-article">
                <div class="article-box">
                    <h2 class="article-box-child-1">
                        Get 50% off
                    </h2>
                    <router-link :to="{ path: '/auth/sign-up' }" class="article-box-child-2">
                        <span>
                            SUBSCRIBE NOW
                        </span>
                    </router-link>
                    <h2 class="article-box-child-3">All models in Model Market</h2>
                    <div class="article-box-child-4">
                        <span>Contact:support@soynet.io for queries on license activation</span>
                    </div>
                    <div class="article-box-child-5">
                        <h6>valid until Dec 31, 2022</h6>
                        <label for="popupCheck"><input type="checkbox" v-model="popupChecked" id="popupCheck" />Close
                            the pop up for the day</label>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
.soynet-section {
    bottom: 0%;
    right: 0%;
    position: fixed;
    z-index: 101;
    display: flex;
    flex-direction: row;
    align-items: center; 
}

.event-container {
    width: 480px;
    margin-left: auto;
    background-image: url('../../assets/images/event3.png');
    background-size: cover;
    background-color: #fff;
    border: 1px solid #3b3b3b;
}

.cancelArea {
    text-align: right;
}

.cancelArea span {
    cursor: pointer;
    padding-right: 10px;
    font-weight: bold;
    font-size: 12px;
    color: #fff;
}

.cont-article {
    width: 100%;
    padding: 50px 0 0;
}

.cont-article .article-box {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
    padding: 80px 5px 20px;
}

.article-box-child-1 {
    font-size: 52px;
    font-weight: bold;
    color: #fff;
    line-height: 170%;
}

.article-box-child-2 {
    font-size: 20px;
    border-radius: 50px;
    margin: 0 auto 30px;
    background-color: #ce530f;
    padding: 20px 30px;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
}

.article-box-child-2:hover {
    background-color: #af450c;
}

.article-box-child-3 {
    font-size: 26px;
    font-weight: bold;
    color: #fff;
    line-height: 170%;
}

.article-box-child-4 {
    margin: 35px 0 0 0;
    font-size: 13px;
    background-color: #fff;
    color: #696969;
}

.article-box-child-5{
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    justify-content: space-between;
    color: #fff;
    font-size: 14px;
}

.article-box-child-5 h6{
    color: rgba(255, 255, 255, 0.6);
    font-weight: bold;
    font-size: 12px;
}
.article-box-child-5 label{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.article-box-child-5 input{
    margin-right: 3px;
}

@media screen  and (max-width:1440px) and (min-width:577px) {
    .soynet-section {
    bottom: 0%;
    right: 0%;
    position: fixed;
    z-index: 101;
    display: flex;
    flex-direction: row;
    align-items: center; 
}

.event-container {
    width: 360px;
    margin-left: auto;
    background-image: url('../../assets/images/event3.png');
    background-size: cover;
    background-color: #fff;
    border: 1px solid #3b3b3b;
}

.cancelArea {
    text-align: right;
}

.cancelArea span {
    cursor: pointer;
    padding-right: 10px;
    font-weight: bold;
    font-size: 12px;
    color: #fff;
}

.cont-article {
    width: 100%;
    padding: 50px 0 0;
}

.cont-article .article-box {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
    padding: 20px 5px 20px;
}

.article-box-child-1 {
    font-size: 40px;
    font-weight: bold;
    color: #fff;
    line-height: 170%;
}

.article-box-child-2 {
    font-size: 14px;
    border-radius: 50px;
    margin: 0 auto 30px;
    background-color: #ce530f;
    padding: 10px 20px;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
}

.article-box-child-2:hover {
    background-color: #af450c;
}

.article-box-child-3 {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    line-height: 170%;
}

.article-box-child-4 {
    margin: 25px 0 0 0;
    font-size: 9px;
    background-color: #fff;
    color: #696969;
}

.article-box-child-5{
    margin-top: 30px;
    text-align: right;
    color: #fff;
    font-size: 10px;
}

.article-box-child-5 label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}

.article-box-child-5 h6{
    font-size: 10px;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
}

.article-box-child-5 input{
    margin-right: 3px;
    width: 10px;
}
}


@media screen and (max-width:576px) {
    .soynet-section {
        display: none;
    }
}
</style>

<script>
import VueCookies from 'vue-cookies';
import CookieCheck from '@/compositions/CookieCheck.js';
import { ref } from "vue";

export default {
    setup() {
        const modalShow = ref(CookieCheck.modalCookieCheck());
        const popupChecked = ref(false);

        /** event Modal 날짜 체크 */
        const dateLimitCheck = () => {
            const dateLimit = new Date('2023-01-01 00:00');
            const currentDate = new Date();

            if(dateLimit < currentDate)
                return modalShow.value = false;
        }

        dateLimitCheck();
        // cookie사용 안하고 모달닫기
        const modalHide = () => {
            popupChecked.value ? VueCookies.set("eventModalCookie", "ture", "1d") : null;
            modalShow.value = false;
        }
        return {
            modalHide,
            popupChecked,
            modalShow
        }
    }
}
</script>
