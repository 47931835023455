<template>
    <div class="container my-model">
        <h1 class="title">My Account</h1>
        <div class="user-info row">
            <div class="col-6">
                <h2>Email :</h2>
                <p>
                    {{ userInfo.email }}
                </p>
            </div>
            <div class="col-6">
                <h2>Name :</h2>
                <p>
                    {{ userInfo.name }}
                </p>
            </div>
            <div class="col-6">
                <h2>Country :</h2>
                <p>
                    {{ userInfo.country || "none" }}
                </p>
            </div>
            <div class="col-6">
                <h2>Company :</h2>
                <p>
                    {{ userInfo.company || "none" }}
                </p>
            </div>
            <div class="col-6">
                <h2>Job Title :</h2>
                <p>
                    {{ userInfo.jobtitle || "none" }}
                </p>
            </div>
            <!-- <div class="col-6">
        <h2>Phone Number :</h2>
        <p>
          {{ userInfo.phoneNumber || "none" }}
        </p>
      </div> -->
            <div class="col-12 text-center mt-4">
                <button class="theme-btn editBtn" @click="editModalShow">
                    Edit
                </button>
                <button class="theme-btn" @click="changePasswordModalShow">
                    Change Password
                </button>
            </div>
        </div>

        <user-info-edit-modal ref="userInfoEditModal"> </user-info-edit-modal>
        <user-change-pw-modal
            ref="userChangePasswordModal"
        ></user-change-pw-modal>
    </div>
</template>

<style scoped>
.my-model {
    margin-bottom: 100px;
}

.title {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.01em;

    color: #ea5b33;
}

.user-info {
    max-width: 500px;
    margin: 50px auto;
}

.user-info h2 {
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 5px;

    color: #000000;
}

.user-info p {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 10px;
    margin-left: 7px;

    color: #000000;
}

.editBtn {
    margin-right: 1vw;
}
</style>

<script>
import UserInfoEditModal from "./UserInfoEditModal.vue";
import UserChangePwModal from "./UserChangePwModal.vue";
import { inject, ref } from "vue";

export default {
    components: { UserInfoEditModal, UserChangePwModal },
    props: ["userInfo"],
    setup() {
        const userInfoEditModal = ref(null);
        const userChangePasswordModal = ref(null);

        const editModalShow = () => userInfoEditModal.value.show();
        const changePasswordModalShow = () =>
            userChangePasswordModal.value.show();

        return {
            userInfoEditModal,
            userChangePasswordModal,
            editModalShow,
            changePasswordModalShow,
        };
    },
};
</script>
