<template>
    <footer>
        <div class="container">
            <p class="notFound-mainText">Not Found</p>
            <p class="notFound-subText">This page could not be found.
                <br>
                The requested page is gone or you used the wrong path.
            </p>
            <div class="notFound-homeBtnArea">
            <a href="/">
            <button class="notFound-homeBtn">
                go to home
            </button>
            </a>
            </div>
        </div>
    </footer>
</template>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 30px;
    min-height: 63vh;
}

.notFound-mainText {
    font-size: 90px;
}

.notFound-subText{
    font-size: 24px;
}



.notFound-homeBtn{
    max-width: 300px;
    min-width: 200px;
    padding: 15px;
    background-color: #e36159;
    border: none;
    color: #ddd;
}

@media screen and (max-width:576px){
.notFound-mainText {
    font-size: 50px;
}

.notFound-subText{
    font-size: 16px;
}
}
</style>

<script>
export default {};
</script>
