<template>
  <footer>
    <div class="container">
      <div class="accordion d-block d-lg-none" id="accordionExample">
        <div class="accordion-item">
          <h6 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Company
            </button>
          </h6>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ul class="p-0">
                <li>
                  <router-link to="/about">About Us </router-link>
                </li>
                <li>
                  <router-link to="/market">Model Market </router-link>
                </li>
                <li>
                  <router-link to="/partners">Partners </router-link>
                </li>
                <li>
                  <router-link to="/pricing">Pricing </router-link>
                </li>
                <li class="m-0">
                  <router-link to="/what-is-soynet">SoyNet </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h6 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Customer Service
            </button>
          </h6>
          <div
            id="collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <ul class="p-0">
                <li>
                  <p>Email: sales@soynet.io</p>
                </li>
                <li></li>
                <li>
                  <p>Collaboration: +82 10 8956 8157</p>
                </li>
                <li>
                  <p>Technical Support: support@soynet.io</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-lg-0 mt-5">
        <div class="col-lg-5 col-12">
          <h6>SOYNET Co,Ltd.</h6>
          <p class="para">
            No.1503, 8, Seongnam-daero 331beon-gil, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea
          </p>
        </div>

        <div class="col-lg-3 d-lg-block d-none">
          <ul class="p-0">
            <h6>Company</h6>
            <li>
              <router-link to="/about">About Us </router-link>
            </li>
            <li>
              <router-link to="/market">Model Market </router-link>
            </li>
            <li>
              <router-link to="/partners">Partners </router-link>
            </li>
            <li>
              <router-link to="/pricing">Pricing </router-link>
            </li>
            <li class="m-0">
              <router-link to="/what-is-soynet">SoyNet</router-link>
            </li>
          </ul>
        </div>

        <div class="col-lg-4 d-lg-block d-none">
          <ul class="p-0">
            <h6>Customer Service</h6>
            <li>
              <p>Email: sales@soynet.io</p>
            </li>
            <li></li>
            <li>
              <p>Collaboration: +82 10 8956 8157</p>
            </li>
            <li>
              <p>Technical Support: support@soynet.io</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="container">
      <div class="d-md-flex justify-content-between">
        <p class="light">© 2022. SOYNET Co,Ltd.ALL RIGHTS RESERVED</p>
        <div class="d-flex icons mt-3 mt-md-0">
          <a href="https://www.linkedin.com/company/soynet" target="_blank">
            <i class="fab fa-linkedin-in"></i>
          </a>
          <a href="https://www.facebook.com/soynet.io" target="_blank">
            <i class="fab fa-facebook-f"></i>
          </a>
          <a
            href="https://www.youtube.com/@soynet "
            target="_blank"
          >
            <i class="fab fa-youtube"></i>
          </a>
          <a
            href="https://discord.gg/d33bw87Hqb"
          >
            <i class="fab fa-discord"></i>
          </a>
          <a href="mailto:sales@soynet.io">
            <i class="fas fa-envelope"></i>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
footer {
  padding-top: 60px;
  background-color: #000;
  padding-bottom: 25px;
}

footer h6 {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 5px;
}

footer ul {
  list-style: none;
}

footer .para {
  width: 80%;
}

footer p,
footer a {
  margin: 0;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
}

footer a:hover {
  color: #e36159;
}

footer .divider {
  height: 2px;
  background: #e36159;
  width: 100%;
  margin-top: 75px;
  margin-bottom: 15px;
}

footer a i {
  color: #000;
  background: #fff;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
}

footer .accordion-item {
  background: transparent;
}

footer .accordion-header {
  background: transparent;
}

footer .accordion-item button {
  background: transparent !important;
  color: #fff !important;
  padding: 20px 0;
  border-bottom: 1px solid #fff;
}

footer .accordion-button:focus {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #fff;
  box-shadow: none;
  outline: none;
}
</style>

<script>
export default {};
</script>
 