<template>
  <section class="soynet-section">
    <div class="container">
      <div class="cont-heading text-center">
        <h1>
          What is SoyNet
        </h1>
        <h6>
          General-purpose AI frameworks are heavy and slow to apply to service execution.
          SoyNet can run any <br class="d-none sm-inline"> artificial intelligence models trained on Tensor/<br class="d-none sm-inline">Pytorch/Caffe Etc making it faster
          consuming less memory.
        </h6>
      </div>

      <figure>
        <video class="w-100" controls="">
          <source src="@/assets/images/SoyNet_intro_english_caption.mp4" type="video/mp4">
        </video>
      </figure>
    </div>
  </section>
</template>

<style scoped>
  .cont-heading h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
    letter-spacing: -0.01em;
    color: #EA5B33;
  }

  .cont-heading h6 {
    max-width: 762px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    margin: 0 auto;
    letter-spacing: -0.01em;

    color: #000000;
  }

  .soynet-section {
    padding-top: 180px;
    position: relative;
  }

  .soynet-section figure {
    margin: 60px 0 0;
  }

  @media screen and (max-width:576px) {
    .cont-heading {
      text-align: left !important;
    }

    .soynet-section {
      padding-top: 140px;
    }

    .cont-heading h1 {
      font-size: 24px;
    }

    .cont-heading h6 {
      font-size: 14px;
    }
  }
</style>

<script>
  export default {}
</script>
