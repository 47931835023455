<template>
  <section class="admin-page">
    <admin-page-side-bar />
    <admin-model-config v-if="showCompIndex === 1" />
    <admin-model-list v-if="showCompIndex === 2" />
    <admin-user-models v-if="showCompIndex === 3" />
    <admin-referer-code v-if="showCompIndex === 4" />
    <admin-user-list v-if="showCompIndex === 5" />
  </section>
</template>
<style scoped>
.admin-page {
  margin: 70px 0;
  min-height: 40vh;
}
</style>
<script>
import AdminUserModels from "./AdminUserModels.vue";
import AdminModelConfig from "./AdminModelConfig.vue";
import AdminModelList from "./AdminModelList.vue";
import AdminPageSideBar from "./AdminPageSideBar.vue";
import AdminPage from "../../../compositions/AdminPage";
import AdminRefererCode from "./AdminRefererCode.vue";
import AdminUserList from "./AdminUserList.vue";

export default {
  components: {
    AdminPageSideBar,
    AdminUserModels,
    AdminModelConfig,
    AdminModelList,
    AdminRefererCode,
    AdminUserList
  },
  setup() {
    return {
      showCompIndex: AdminPage.showComponent,
    };
  },
};
</script>
