<template>
    <article>
        <partners-section />
    </article>
</template>

<script>
    import PartnersSection from './PartnersSection.vue';

    export default {
        components: {
            PartnersSection
        }
    };
</script>
