<template>
    <article>
        <license-notice />
        <model-market-gallery /> 
        <model-market-section />
        <model-market-support-section />
        <about-event-modal-vue/>
    </article>
</template>

<script>
import ModelMarketGallery from './ModelMarketGallery.vue';
import ModelMarketSection from './ModelMarketSection.vue'; 
import ModelMarketSupportSection from './ModelMarketSupportSection.vue';
import AboutEventModalVue from '@/views/layouts/AboutEventModal3.vue';
import LicenseNotice from "@/components/LicenseNotice.vue";

export default {
    components: {
        LicenseNotice,
        ModelMarketGallery,
        ModelMarketSection,
        ModelMarketSupportSection,
        AboutEventModalVue
    },
};
</script>
