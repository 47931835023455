<template>
  <teleport to="#destination">
    <base-modal ref="modal" modalClass="modal-md modal-dialog-centered">
      <template #title> Extend Subscription </template>
      <div class="table-responsive">
        <table class="table" v-if="modelInfo">
          <thead>
            <tr>
              <th scope="col">Model Name</th>
              <th scope="col">Model Id</th>
              <th scope="col">License</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {{ modelInfo.modelName }}
              </td>
              <td>
                {{ modelInfo.modelId }}
              </td>
              <td>
                {{ modelInfo.license }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row m-0">
        <div class="col-8 px-2">
          <h6>Subscription Year</h6>
        </div>
        <div class="col-4 px-2">
          <h6>Price</h6>
        </div>

        <div class="col-8 px-2">
          <select class="form-select" v-model="subYear">
            <option :value="null" disabled>Select Subscription Year</option>
            <option
              v-for="{ value, text } in modelInfo.subscriptionYear || []"
              :value="value"
              :key="value"
            >
              {{ text }}
            </option>
          </select>
        </div>
        <div class="col-4 px-2">$ {{ price || 0 }}</div>
      </div>

      <template #footer>
        <base-button-with-loading
          class="w-100"
          :loading="loading"
          @confirmed="extendSubscription"
        >
          Extend Subscription
        </base-button-with-loading>
      </template>
    </base-modal>
  </teleport>
</template>

<style scoped>
input {
  background: #ffffff;
  border: 1.5px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;

  font-size: 15px;
  line-height: 21px;
  letter-spacing: -0.01em;

  padding: 12px;
}

.theme-btn {
  padding: 13.5px;
}

td {
  word-break: break-all;
}

h6 {
  font-weight: 700;
}
</style>

<script>
import BaseModal from "@/components/BaseModal.vue";
import { ref, computed, inject } from "vue";

import Model from "@/compositions/Model.js";
import Cart from "@/compositions/Cart.js";
import MyPage from "@/compositions/MyPage.js";
import Swal from "sweetalert2";

import BaseButtonWithLoading from "@/components/BaseButtonWithLoading.vue";

import { sendContract } from "@/compositions/Signing.js";

export default {
  components: { BaseModal, BaseButtonWithLoading },
  setup() {
    const modal = ref(null);

    const modelInfo = ref({});
    const loading = ref(false);
    const subYear = ref(null);
    const index = ref(null);

    const id = inject("userInfo").value?.email;
    const name = inject("userInfo").value?.name;

    const show = async (targetIndex, model) => {
      index.value = targetIndex;

      modelInfo.value = {
        ...model,
        ...(await Model.getModelInfo(model.modelSequence)),
      };

      subYear.value = null;

      modal.value.show();
    };

    const hide = () => modal.value.hide();

    const price = computed(
      () =>
        subYear.value &&
        Cart.getPrice({
          license: modelInfo.value.license,
          subYear: subYear.value,
        })
    );

    const extendSubscription = async () => {
      try {
        if (subYear.value == null) {
          throw new Error("Subscription Year is not selected");
        }
        loading.value = true;
        const { isConfirmed: paymentConfirmed } = await Swal.fire({
          title: `Total Price is $${price.value}.`,
          html: `<p>When you click confirm, you will receive an electronic contract with the extended subscription information.</p>
          <p>Please note the contract will be extended once the payment has been confirmed.</p>
          <p>The status of the subscription will be updated once the payment has been confirmed.</p>
          <p>In case you have any questions contact: support@soynet.io</p>`,
          showCancelButton: true,
          confirmButtonText: "Confirm",
          confirmButtonColor: "#E36159",
        });
        if (!paymentConfirmed) return;
        const contractDetails = await sendContract({
          id,
          name,
          models: [
            {
              modelId: modelInfo.value.modelId,
              modelName: modelInfo.value.modelName,
              subYear: subYear.value,
              license: modelInfo.value.license,
              price: price.value,
            },
          ],
        });
        if (!contractDetails) return;
        const { envelopeId, statusDateTime } = contractDetails;

        await MyPage.updateSubscription({
          id,
          index: index.value,
          pricingAmount: price.value,
          subYear: subYear.value,
          envelopeId,
          statusDateTime,
        });

        hide();
      } catch (err) {
        Swal.fire({
          title: err.message,
          icon: "error",
          confirmButtonColor: "#E36159",
        });
      } finally {
        loading.value = false;
      }
    };

    return {
      modelInfo,
      modal,
      show,
      extendSubscription,
      hide,
      loading,
      subYear,
      price,
    };
  },
};
</script>
