import { createApp } from "vue";
import { serverClassification } from "./compositions/setFunction";
import vueCountryRegionSelect from "vue3-country-region-select";
import App from "./App.vue";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/swiper-bundle.min.css";
import "./assets/css/style.css";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.core.css";

import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";

Amplify.configure(awsExports);

import router from "./routes/router";
import VueCookies from "vue-cookies";

import SwiperCore, { Navigation, Pagination } from "swiper";
SwiperCore.use([Navigation, Pagination]);

import VueGtag from "vue-gtag";

import "aos/dist/aos.css";

import "@toast-ui/editor/dist/toastui-editor.css";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";

import "prismjs/themes/prism.css";
import "@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css";

const app = createApp(App);
const liveServer = serverClassification();
liveServer &&
    app.use(VueGtag, {
        config: {
            id: "GTM-54VBVTS",
            params: { send_page_view: true },
        },
    });

app.use(router);
app.use(VueCookies);
app.use(vueCountryRegionSelect);

app.config.unwrapInjectedRef = true;

app.mount("#app");
