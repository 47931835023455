<template>
    <section class="cart-section">
        <div class="container">
            <div class="heading">
                <h1>Cart</h1>
            </div>
            <div v-if="items?.length > 0">
                <div
                    class="row mx-0"
                    v-for="(item, index) in items"
                    :key="item.modelSequence"
                >
                    <h1 class="order-first model-name">
                        {{ item.modelName }}
                    </h1>
                    <div
                        class="order-3 order-sm-2 col-2 col-sm-1 d-flex justify-content-center align-items-center"
                    >
                        <input
                            class="form-check-input"
                            type="checkbox"
                            v-model="item.selected"
                            :disabled="item.license === 'enterprise'"
                        />
                    </div>
                    <div class="order-2 order-sm-3 col-sm-3 col-12">
                        <figure>
                            <img :src="item.thumbnailKey" />
                        </figure>
                    </div>
                    <div class="order-4 col-10 col-sm-8 mx-0 details">
                        <div class="mx-0">
                            <label class="d-block col-12 col-sm-6">
                                License :
                                <select
                                    class="form-select"
                                    v-model="item.license"
                                >
                                    <template
                                        v-for="licenseName in item.licenseGroups"
                                    >
                                        <template v-if="isConetentWriter">
                                            <option
                                                :key="licenseName"
                                                :value="licenseName"
                                                :disabled="
                                                    licenseName === 'enterprise'
                                                "
                                            >
                                                {{ licenseName }}
                                            </option>
                                        </template>
                                        <template v-else>
                                            <option
                                                v-if="
                                                    licenseName !== 'developer'
                                                "
                                                :key="licenseName"
                                                :value="licenseName"
                                                :disabled="
                                                    licenseName === 'enterprise'
                                                "
                                            >
                                                {{ licenseName }}
                                            </option>
                                        </template>
                                    </template>
                                </select>
                            </label>
                            <label class="d-block col-12 col-sm-6">
                                Subscription Year
                                <select
                                    class="form-select"
                                    v-model="item.subYear"
                                    :disabled="
                                        disabledLicense.includes(item.license)
                                    "
                                >
                                    <option
                                        v-for="{
                                            value,
                                            text,
                                        } in item.subscriptionYear"
                                        :key="value"
                                        :value="value"
                                    >
                                        {{ text }}
                                    </option>
                                </select>
                            </label>
                            <div>
                                <h2 class="d-inline-block">Support :</h2>
                                <p class="d-inline-block">
                                    {{ item.license !== "free" ? "Yes" : "No" }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="order-5 sub-total">
                        <h1>
                            {{
                                prices[index] != null
                                    ? `Sub Total : $ ${prices[
                                          index
                                      ].toLocaleString()}`
                                    : "Contact Sales"
                            }}
                        </h1>

                        <div class="text-end">
                            <a
                                class="link"
                                @click="removeModelFromCart([index])"
                            >
                                Remove model from Cart
                            </a>
                        </div>
                    </div>

                    <hr class="order-last my-4" />
                </div>
                <div class="total">
                    <h3>Total : ${{ beforeTotalPrice.toLocaleString() }}</h3>
                    <h3>Discount Rate : {{ discountPercent }}%</h3>
                    <h1>Final Total : ${{ totalPrice.toLocaleString() }}</h1>
                </div>

                <div class="text-end cart-confirm-area">
                    <span class="codeInputArea">
                        Referer Code :
                        <input
                            id="userInput"
                            type="text"
                            placeholder="Enter Cord"
                            v-model="refererCode"
                        />
                        <button class="theme-btn-2" @click="getRefererCode()">
                            Enter
                        </button>
                    </span>
                    <div class="confirmBtnArea">
                        <button
                            class="theme-btn"
                            @click="subscribeModel(false)"
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
            <div class="no-item text-center" v-else>
                <h1>No Item</h1>
            </div>
        </div>

        <teleport to="#destination">
            <base-modal ref="modal" modalClass="modal-xl">
                <template #title>
                    <h1>Terms and Conditions</h1>
                </template>
                <template #footer>
                    <button
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                        @click="subscribeModel(true)"
                    >
                        Agree
                    </button>
                </template>
                <terms-and-conditions></terms-and-conditions>
            </base-modal>
        </teleport>
    </section>
</template>
<style scoped>
.cart-section {
    margin: 50px 0;
    min-height: 50vh;
}

.heading h1 {
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.01em;

    color: #ea5b33;

    margin-bottom: 50px;
}

figure {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    margin: 0;
}

figure img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.no-item h1 {
    font-weight: bold;
    font-size: 50px;
    line-height: 45px;
    letter-spacing: -0.01em;

    padding-top: 100px;

    color: #9f9f9f;

    margin-bottom: 15px;
}

.model-name {
    font-weight: bold;
    font-size: 30px;
    line-height: 45px;
    letter-spacing: -0.01em;

    color: #000000;

    margin-bottom: 15px;
}

.details h2 {
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 5px;

    color: #000000;
}

.details p {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 10px;

    color: #000000;
}

.details label {
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 10px;

    color: #000000;
}

.details select {
    margin: 5px 0 0;
}

.sub-total {
    margin: 10px 0;
}

.sub-total h1 {
    font-weight: bold;
    font-size: 30px;
    line-height: 45px;
    letter-spacing: -0.01em;

    color: #000000;
    text-align: end;
    margin: 0;
}

.sub-total a {
    cursor: pointer;
}

.total h1 {
    font-weight: bold;
    font-size: 40px;
    line-height: 55px;
    letter-spacing: -0.01em;

    color: #000000;
    text-align: end;
    margin: 20px 0 30px;
}

.total h3 {
    font-weight: bold;
    line-height: 55px;
    letter-spacing: -0.01em;

    color: #000000;
    text-align: end;
    margin: 20px 0 30px;
}

.cart-confirm-area .codeInputArea input {
    background: #ffffff;
    border: 1.5px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 2px;

    font-size: 15px;
    line-height: 15px;
    letter-spacing: -0.01em;

    padding: 8px;

    margin: 0 8px 8px;
}

.cart-confirm-area .codeInputArea input:focus {
    outline: none;
}

.cart-confirm-area {
    width: 100%;
    display: flex;
    align-items: center;
}

.cart-confirm-area .codeInputArea {
    width: 85%;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    color: #000000;
}

.cart-confirm-area .codeInputArea button {
    margin-left: 15px;
    padding: 12px 20px;
}

.cart-confirm-area .confirmBtnArea {
    width: 15%;
}

@media screen and (max-width: 576px) {
    figure {
        width: 100%;
        padding-bottom: 100%;
        position: relative;
        margin-bottom: 15px;
    }

    .sub-total h1 {
        font-size: 24px;
    }

    .total h1 {
        font-size: 30px;
    }

    .cart-confirm-area .codeInputArea input {
        background: #ffffff;
        border: 1.5px solid #ebebeb;
        box-sizing: border-box;
        border-radius: 2px;
        width: 40%;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.01em;

        padding: 8px;

        margin: 0 8px 8px;
    }

    .cart-confirm-area .codeInputArea input:focus {
        outline: none;
    }

    .cart-confirm-area {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .cart-confirm-area .codeInputArea {
        width: 100%;
        text-align: right;
        font-weight: bold;
        font-size: 14px;
        line-height: 30px;
        color: #000000;
        margin-bottom: 10px;
    }

    .cart-confirm-area .codeInputArea button {
        margin-left: 5px;
        font-size: 14px;
        padding: 8px 8px;
    }

    .cart-confirm-area .confirmBtnArea {
        width: 100%;
    }
}
</style>
<script>
import Swal from "sweetalert2";

import cart from "@/compositions/Cart.js";
import myPage from "../../../compositions/MyPage";
import { getDateTime, setApiEndpoint } from "../../../compositions/setFunction";
import { paymentAlert, sendContract } from "@/compositions/Signing.js";

import { inject, ref, computed } from "vue";
import { useRouter } from "vue-router";

import BaseModal from "@/components/BaseModal.vue";
import TermsAndConditions from "@/components/TermsAndConditions.vue";

export default {
    components: {
        BaseModal,
        TermsAndConditions,
    },
    setup() {
        const router = useRouter(); // 라우터 데이터
        const modal = ref(null); // 모달 스위치
        const refererCode = ref(""); // 할인 코드

        const id = inject("userInfo").value?.email; // 사용자 이메일
        const name = inject("userInfo").value?.name; // 사용자 이름
        const authority = inject("userInfo").value?.authority || "user"; // 사용자 권한
        const allowedRoles = ["admin", "developer"]; // 관리자 권한 목록
        const disabledLicense = ["free", "developer"]; // 사용할 연도 선택 불가할 라이센스 목록

        cart.getCartItems(id); // 카트 목록 가져오기

        // 카트 목록에서 항목 삭제 Input : 항목 순서 번호
        const removeModelFromCart = async (indexes) =>
            await cart.removeModelFromCart({ id, indexes });

        // 모델 구입 Input : 동의 체크 여부
        const subscribeModel = async (agreeWithTerms) => {
            try {
                // 선택된 항목 조회
                const selectedItems = cart.cartItems.value
                    .map((e, index) => ({ ...e, index }))
                    .filter(
                        ({ selected, license }) =>
                            selected && license !== "enterprise"
                    );

                if (selectedItems.length < 1)
                    throw new Error("No Item Selected");

                const sameModel = "true"; // 이미 사용중인 모델인지 체크

                await Promise.all(
                    selectedItems.map(({ modelSequence, license, modelName }) =>
                        myPage.sameModelCheck({
                            id,
                            modelName,
                            modelSequence,
                            license,
                            sameModel,
                        })
                    )
                );

                // modal창 내용
                const paymentConfirmed = await paymentAlert({
                    initialValue: agreeWithTerms,
                    title: `Total Price is $${cart.totalPrice.value.toLocaleString()}.`,
                    onClick: () => modal.value.show(),
                });

                if (!paymentConfirmed) return;

                // 전자 서명을 보낼때 무료 모델은 보내지 않음 Input : 사용자 이메일, 이름, 서명할 모델
                const contractDetails = await sendContract({
                    id,
                    name,
                    models: selectedItems.map(
                        ({ modelId, modelName, subYear, index, license }) => {
                            if (license !== "free") {
                                return {
                                    modelId,
                                    modelName,
                                    subYear: license !== "free" ? +subYear : 0,
                                    price: cart.prices.value[index],
                                };
                            } else {
                                return null;
                            }
                        }
                    ),
                });

                if (!contractDetails) return;

                const {
                    envelopeId,
                    status: contractStatus,
                    statusDateTime,
                } = contractDetails; // 전자서명 고유id, 서명 상태, 날짜
                const dateTime = getDateTime();

                await Promise.all(
                    selectedItems.map(
                        ({
                            modelSequence,
                            modelId,
                            modelName,
                            license,
                            subYear,
                            soynetversion,
                        }) =>
                            myPage.addModelToList({
                                id,
                                modelId,
                                modelSequence,
                                modelName,
                                license,
                                pricingAmount: cart.getPrice({
                                    license,
                                    subYear,
                                }),
                                subYear: license !== "free" ? +subYear : 0,
                                envelopeId,
                                contractStatus: contractStatus
                                    ? contractStatus
                                    : "",
                                statusDateTime,
                                dateTime,
                                codeDiscount: cart.discountMinus.value,
                                refererCode: refererCode.value,
                                authority,
                                soynetversion,
                            })
                    )
                );

                // 할인 코드를 사용할경우 코드 사용으로 변경
                if (!!refererCode.value) {
                    cart.updateRefererCode(
                        id,
                        refererCode.value,
                        cart.discountInfo.value
                    );
                }

                await removeModelFromCart(
                    selectedItems.map(({ index }) => index)
                );

                router.push("/thank-you/purchase");
            } catch (err) {
                Swal.fire({
                    title: err.message,
                    icon: "error",
                    confirmButtonColor: "#E36159",
                });
            }
        };

        // 할인 코드 입력시 할인 정보 가져오기
        const getRefererCode = async () => {
            try {
                if (refererCode.value.length === 0) {
                    throw new Error("Please enter the code.");
                }

                if (refererCode.value.length < 12) {
                    throw new Error("This is incorrect code.");
                }

                const api_hostname = setApiEndpoint(); // api 주소
                const response = await fetch(
                    `${api_hostname}/referer-code/${id}/${refererCode.value}`
                );

                if (!response.ok) {
                    throw new Error("None ReferCode");
                }

                const fetchResult = await response.json();

                if (fetchResult.status === 1) {
                    refererCode.value = null;
                    throw new Error("Already Use Code");
                }

                cart.discountPercent.value = 100; // 할인 중복 방지를 위해 초기값 설정
                cart.discountMinus.value = fetchResult.discountPercent; // 할인율 저장
                cart.discountPercent.value -= cart.discountMinus.value; // 초기값에서 할인율을 빼기
            } catch (err) {
                Swal.fire({
                    title: err.message,
                    icon: "error",
                    confirmButtonColor: "#E36159",
                });
            }
        };

        // 사용자가 관리자 권한이 있는지 체크
        const isConetentWriter = computed(() =>
            allowedRoles.includes(authority)
        );

        return {
            prices: cart.prices,
            items: cart.cartItems,
            totalPrice: cart.totalPrice,
            removeModelFromCart,
            modal,
            subscribeModel,
            getRefererCode,
            refererCode,
            discountPercent: cart.discountMinus,
            beforeTotalPrice: cart.beforeTotalPrice,
            isConetentWriter,
            disabledLicense,
        };
    },
};
</script>
