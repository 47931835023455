<template>
  <header>

    <nav class="navbar navbar-expand-lg bg-transparent navbar-light border-bottom">
      <div class="container">
        <div v-if="!serverClass" class="header-server-class">
          test
        </div>
        <a class="navbar-brand" href="/">
          <img src="@/assets/images/logo.svg" alt="" />
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 mx-auto">
            <li class="nav-item">
              <router-link class="nav-tiles" :to="{ path: '/about/' }">About Us</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-tiles" :to="{ path: '/market/' }">Model Market</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-tiles" :to="{ path: '/partners/' }">Partners</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-tiles" :to="{ path: '/pricing/' }">Pricing</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-tiles" :to="{ path: '/contact-us/' }">Contact Us</router-link>
            </li>
            <li class="nav-item dropdown">
              <a class="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                SoyNet
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li class="my-2">
                  <router-link class="nav-tiles" :to="{ path: '/blogs/' }">Blogs</router-link>
                </li>
                <li class="my-2">
                  <router-link class="nav-tiles" :to="{ path: '/case-study/' }">Case Study</router-link>
                </li>
                <li class="my-2">
                  <router-link class="nav-tiles" :to="{ path: '/career/' }">Career</router-link>
                </li>
                <li class="my-2">
                  <router-link class="nav-tiles" :to="{ path: '/what-is-soynet/' }">What is SoyNet</router-link>
                </li>
              </ul>
            </li>
          </ul>
          <div class="d-flex sign-in-button">
            <a href="/cart/" v-if="userInfo" class="cart">
              <img src="@/assets/images/shopping-cart.png" alt="" />
            </a>
            <div class="dropdown" v-if="userInfo">
              <h2 class="user-name text-center dropdown-toggle" id="userDropdown" data-bs-toggle="dropdown"
                aria-expanded="false">
                Welcome<br />
                {{ userInfo.name }}
              </h2>
              <ul class="dropdown-menu" aria-labelledby="userDropdown">
                <li class="my-2">
                  <a class="nav-tiles" href="/my-page/">My Page</a>
                </li>
                <li class="my-2">
                  <a class="nav-tiles" href="/admin-page/" v-if="isAdmin">Admin Page</a>
                </li>
                <li class="my-2">
                  <a class="nav-tiles" href="#" @click="userSignOut">Sign Out</a>
                </li>
              </ul>
            </div>

            <router-link class="theme-btn-2" to="/auth/sign-in/" v-else>
              Log in<span class="d-inline">/Sign Up</span>
            </router-link>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<style scoped>
.navbar-brand img {
  width: 120px;
}

.header-server-class{
  position: absolute;
  left: 5%;
  background-color: yellow;
  padding: 15px;
} 
header nav li .nav-tiles,
header nav li a {
  margin: 0 20px;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  text-decoration: none;
  position: relative;
  padding: 10px 0;
}

header nav li .nav-tiles::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1.5px;
  width: 0;
  background-color: #000;
  transition: all 0.4s ease;
}

header nav li .nav-tiles:hover,
header nav li .nav-tiles.router-link-exact-active {
  font-weight: 600;
}

header nav li .nav-tiles:hover::before,
header nav li .nav-tiles.router-link-exact-active::before {
  width: 100%;
}

header .cart {
  height: fit-content;
  margin: auto 20px auto auto;
}

.theme-btn-2 {
  padding: 15.5px 15px;
}

.user-name {
  margin: 0;
  padding: 7px 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #000000;

  cursor: pointer;
}

@media (max-width: 1199px) {
  .theme-btn-2 span {
    display: none !important;
  }

  header nav li .nav-tiles,
  header nav li a {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  header nav li .nav-tiles::before {
    bottom: 7px;
  }

  .theme-btn-2 {
    font-size: 14px;
    padding: 12.5px 12px;
  }

  .user-name {
    margin-right: 25px;
  }
}
</style>

<script>
import { computed, inject } from "vue";
import { useRouter } from "vue-router";
import { serverClassification } from "../../compositions/setFunction";

export default {
  setup() {
    const allowedRoles = ["admin", "developer"];
    const serverClass = serverClassification();
    const userInfo = inject("userInfo");
    const signOut = inject("signOut");
    const isAdmin = computed(() =>
      allowedRoles.includes(userInfo.value?.authority)
    );
    const router = useRouter();

    const userSignOut = async (e) => {
      e.preventDefault();
      await signOut();
      router.push("");
    };

    return {
      userInfo,
      isAdmin,
      userSignOut,
      serverClass
    };
  },
};
</script>
