<template>
    <teleport to="#destination">
        <base-modal ref="modal" modalClass="modal-md modal-dialog-centered">
            <template #title> {{ title }} - License Keys </template>

            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <!-- <th scope="col" v-if="license !== 'free'">
                                GPU ID
                            </th> -->
                            <th scope="col">GPU ID</th>
                            <!-- <th scope="col">issuance_mode</th> -->
                            <!-- <th scope="col">CreateDateTime</th> -->
                        </tr>
                    </thead>
                    <tbody class="keys">
                        <!-- <tr v-for="key in keyList">
                            <td v-if="license !== 'free'">
                                {{ key.gpuId }}
                            </td>
                            <td>
                                <h1>{{ key.issuance_mode }}</h1>
                            </td>
                            <td>
                                <h1>{{ key.datetime }}</h1>
                            </td>
                        </tr> -->
                        <!-- <tr v-if="count != 0"> -->
                        <!-- <td v-if="license !== 'free'"> -->
                        <td>
                            <input
                                type="text"
                                v-model="gpuId"
                                placeholder="Enter GPU ID"
                            />
                        </td>
                        <td>
                            <base-button-with-loading
                                class="w-100 center"
                                :loading="loading"
                                @confirmed="genrateLicenseKey()"
                            >
                                Generate License Key
                            </base-button-with-loading>
                        </td>
                        <!-- </tr> -->
                    </tbody>
                </table>
                <div>
                    <!-- <a href="" class="help_tag">License guide link</a> -->
                </div>
            </div>
        </base-modal>
    </teleport>
</template>

<style scoped>
.genrate-key {
    border: 1.5px dashed #bbbbbb;
    box-sizing: border-box;
    border-radius: 2px;
    cursor: pointer;
}

.genrate-key h1 {
    font-size: 30px;
    font-weight: bold;
    color: #bbbbbb;
    text-align: center;

    margin: 12px 0;
}

.keys input {
    background: #ffffff;
    border: 1.5px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;

    font-size: 15px;
    line-height: 21px;
    letter-spacing: -0.01em;

    padding: 12px;
}

.theme-btn {
    padding: 13.5px;
}

.keys td {
    word-break: break-all;
}

.keys h1 {
    font-size: 18px;
    font-weight: bold;

    margin: 5px 0;
}

.help_tag {
    text-decoration: none;
    padding: 0 8px;
}
</style>

<script>
import BaseModal from "@/components/BaseModal.vue";
import { ref, inject } from "vue";

import myPage from "@/compositions/MyPage.js";
import Swal from "sweetalert2";
import {
    getRsaPublicKey,
    createAccessToken,
    getLicenseKeyData,
    throwErrMsg,
} from "@/compositions/License.js";

import BaseButtonWithLoading from "@/components/BaseButtonWithLoading.vue";

export default {
    components: { BaseModal, BaseButtonWithLoading },
    setup() {
        const userId = inject("userInfo").value.email; // 사용자 이메일 ex) example@example.com
        const modal = ref(null); // 모달 스위치
        const index = ref(null); // 모델 번호 순서 ex) 1
        const keyList = ref([]); // 모델 라이센스 키 목록
        const title = ref(null); // 모델 이름 ex) YoloV5
        // const count = ref(null); // 사용자 무료 키 발행 잔여 횟수 ex) 5
        const license = ref(null); // 모델 라이센스 등급 ex) free
        const gpuId = ref(null); // 입력한 GPU ID
        const duration = ref(null); // 모델 사용 기간
        const modelSequence = ref(null); // 모델 sequenceId ID
        const loading = ref(false); // 키 발행 로딩 스위치

        // 사용자가 보유한 licensekey 목록
        const getUserLicenseKeys = () => {
            keyList.value = myPage.getLicenseKeys({ index: index.value });
        };

        // console.log(
        //     license.value,
        //     gpuId.value,
        //     duration.value,
        //     userId,
        //     modelSequence.value
        // );

        // 라이센스 키 생성 모달 띄움 Input: 모델 순서 번호, 잔여 횟수, 모델 정보
        const show = (
            newIndex,
            // newCount,
            {
                modelName: newTitle,
                license: newLlicense,
                duration: newDuration,
                modelSequence: newModelSequence,
            }
        ) => {
            index.value = newIndex;
            title.value = newTitle;
            // count.value = newCount;
            license.value = newLlicense;
            duration.value = newDuration;
            modelSequence.value = newModelSequence;
            getUserLicenseKeys();
            modal.value.show();
        };

        // 모달 닫기
        const hide = () => modal.value.hide();

        // 라이센스 키생성
        const genrateLicenseKey = async () => {
            try {
                loading.value = true;

                // GPU ID유효성 검사를 한다.
                if (gpuId.value == null || gpuId.value.length < 1) {
                    throw new Error("GPU ID is missing");
                }

                const { isConfirmed } = await Swal.fire({
                    title: `Are you sure to generate a license key for this GPU ID?\n GPU ID : ${gpuId.value}`,
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    icon: "question",
                    confirmButtonColor: "#E36159",
                });

                if (!isConfirmed) return;

                const getRsaPublicKeyRes = await getRsaPublicKey(); // license서버에서 public key 받아오는 함수

                // 서버에서 받은 응답 체크
                throwErrMsg(getRsaPublicKeyRes);
                console.log("끝");

                // license서버에서 token 발행
                const createAccessTokenRes = await createAccessToken(
                    getRsaPublicKeyRes.publicKey
                );

                throwErrMsg(createAccessTokenRes);
                console.log("끝");

                // license서버에서 license key data 발행
                const getLicenseKeyRes = await getLicenseKeyData(
                    license.value,
                    gpuId.value,
                    duration.value,
                    userId,
                    modelSequence.value,
                    createAccessTokenRes.data
                );

                throwErrMsg(getLicenseKeyRes);

                // 키파일 다운로드 함수 호출
                myPage
                    .generateLicenseKey(
                        userId,
                        index.value,
                        gpuId.value,
                        license.value,
                        getLicenseKeyRes.data.license_key,
                        title.value
                    )
                    .catch((err) => {
                        throw new Error(err);
                    });

                Swal.fire({
                    title: getLicenseKeyRes.data.message,
                    icon: "success",
                    confirmButtonColor: "#E36159",
                });

                getUserLicenseKeys();

                gpuId.value = null;
            } catch (err) {
                Swal.fire({
                    title: err.message,
                    icon: "error",
                    confirmButtonColor: "#E36159",
                });
            } finally {
                loading.value = false;
                hide();
            }
        };

        return {
            modal,
            title,
            show,
            // count,
            index,
            keyList,
            gpuId,
            license,
            genrateLicenseKey,
            hide,
            loading,
        };
    },
};
</script>
