<template>
    <section class="image-section" data-aos="fade-up">
        <div class="position-relative container">
            <div class="mask-img">
                <img
                    class="w-100 rounded"
                    src="@/assets/images/bottom-img.png"
                    alt="bottom Img"
                />
            </div>
            <div class="content">
                <div>
                    <h6 class="mask-main">SOYNET</h6>
                    <h1 class="mask-main-heading">
                        AI Acceleration Solution.
                        <br />
                        Make your AI faster and affordable.
                    </h1>
                    <router-link
                        :to="{ path: '/contact-us' }"
                        class="contact-btn"
                        >Contact Us</router-link
                    >
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
.image-section {
    padding-top: 150px;
    margin-bottom: 65px;
    position: relative;
}

.image-section .content {
    position: absolute;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.image-section .mask-main {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
}

.image-section .mask-main-heading {
    font-weight: bold;
    font-size: 45px;
    line-height: 55px;
    color: #ffffff;
    margin-bottom: 30px;
}

.image-section .mask-img img {
    height: 380px;
    object-fit: cover;
}

.image-section .contact-btn {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #e36159;
    background: #ffffff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 15px 45px;
    text-decoration: none;
}

@media screen and (max-width: 767px) {
    .image-section {
        padding-top: 60px;
    }

    .image-section .mask-main {
        font-size: 16px;
        line-height: 19px;
    }

    .image-section .content {
        width: 100%;
        padding: 0 17px;
    }

    .image-section .mask-main-heading {
        font-size: 34px;
        line-height: 45px;
    }

    .image-section .mask-img img {
        height: 340px;
        object-fit: cover;
    }

    .image-section .contact-btn {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #e36159;
        background: #ffffff;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        border-radius: 2px;
        padding: 15px 45px;
        text-decoration: none;
    }
}
</style>

<script>
export default {};
</script>
