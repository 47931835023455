<template>
  <article>
    <model-market-product-details ref="productDetails" />
    <model-market-product-other-section />
  </article>
</template>

<script>
import ModelMarketProductDetails from "./ModelMarketProductDetails.vue";
import ModelMarketProductOtherSection from "./ModelMarketProductOtherSection.vue";

export default {
  components: {
    ModelMarketProductDetails,
    ModelMarketProductOtherSection,
  },
};
</script>
