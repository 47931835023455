<template>
  <div>
    <button
      class="theme-btn loading w-100"
      :class="[buttonClass, sizeClass]"
      v-if="loading"
    >
      <base-spinner />
    </button>
    <button
      class="theme-btn w-100"
      :class="[buttonClass, sizeClass]"
      @click="$emit('confirmed')"
      v-else
    >
      <slot></slot>
    </button>
  </div>
</template>
<style scoped>
div {
  display: inline-block;
}
.loading {
  font-size: 32px;
  padding: 8.5px 40px;
  pointer-events: none;
  cursor: wait;
}

.sm {
  padding: 9px;
  font-size: 14px;
}

.sm.loading {
  padding: 7.5px 13.2px;
  font-size: 17px;
}
</style>
<script>
import BaseSpinner from "@/components/BaseSpinner.vue";

export default {
  props: ["loading", "buttonClass", "size"],
  components: {
    BaseSpinner,
  },
  computed: {
    sizeClass() {
      const sizeClass =
        {
          sm: "sm",
          lg: "lg",
        } || "";
      return sizeClass[this.size];
    },
  },
};
</script>
