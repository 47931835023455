import { ref, readonly } from "vue";
import User from "./User";
import { setApiEndpoint } from "./setFunction";

const api_hostname = setApiEndpoint();
const API_ENDPOINT =
  `${api_hostname}/case-study`;

const caseStudies = ref([]);

const caseStudy = ref({
  title: null,
  customer: null,
  thumbnailKey: null,
  markdown: null,
});

// case study의 목록을 받아온다
const listCaseStudies = async () => {
  const response = await fetch(API_ENDPOINT, { method: "GET" });

  if (response.ok) {
    caseStudies.value = (await response.json()).Items;
  }
};

// case study의 상세내용을 받아온다. Input : case study post아이디
const getCaseStudy = async (postId) => {
  if (caseStudies.value.length < 1) {
    await listCaseStudies();
  }

  const postDetails = caseStudies.value.find(({ id }) => id === postId); // case study 상세정보

  const { title, customer, contentKey, thumbnailKey: thumbnail } = postDetails; // case study 상세정보 데이터 추출

  const content = await fetch(contentKey, { method: "GET" }); // case study markdown 데이터

  const markdown = await content.text(); // case study markdown 데이터 추출

  if (content.ok) {
    caseStudy.value = {
      title,
      thumbnail,
      customer,
      markdown,
    };
  }
};

// case study항목을 삭제한다 Input : case study post아이디
const deleteCaseStudy = async (postId) => {
  const request = await fetch(`${API_ENDPOINT}/${postId}`, {
    headers: {
      Authorization: await User.getAccessToken(),
    },
    method: "DELETE",
  });

  return request.ok;
};

export default {
  caseStudies: readonly(caseStudies),
  caseStudy: readonly(caseStudy),
  listCaseStudies,
  getCaseStudy,
  deleteCaseStudy,
};
