<template>
    <section class="partner-section">
        <div class="partner-main w-100">
            <img src="@/assets/images/partners-main.png" alt="">
        </div>
        <div class="container">
            <div class="cont-heading">
                <h1>We are proud partners with Esteemed organizations<br class="sm-none">to explore new markets.</h1>
            </div>
            <div class="row justify-content-center">
                <div class="col-sm-4 col-9 logo-container">
                    <a href="http://www.saltlux.com/en/index.do" target="_blank">
                        <figure>
                            <img src="@/assets/images/saltlux.png" alt="">
                        </figure>
                    </a>
                </div>
                <div class="col-sm-4 col-9 logo-container">
                    <a href="http://medicalstandard.com/ai_solution_soynet.php" target="_blank">
                        <figure>
                            <img src="@/assets/images/medicalstandard.png" alt="">
                        </figure>
                    </a>
                </div>
                <div class="col-sm-4 col-9 logo-container">
                    <a href="https://www.seculayer.com/en/" target="_blank">
                        <figure>
                            <img src="@/assets/images/seculayer.png" alt="">
                        </figure>
                    </a>
                </div>
            </div>
            <div class="contact-us">
                <h3>
                    To become strategic partner in making AI dream faster for others contact us : <a href="mailto:sales@soynet.io">sales@soynet.io</a>
                </h3>
            </div>
        </div>
    </section>
</template>

<style scoped>
    .partner-section {
        margin-bottom: 180px;
    }

    .partner-main {
        margin-bottom: 50px;
    }

    .partner-main img {
        width: 100%;
        object-fit: cover;
        object-position: top;
        max-height: 470px;
        min-height: 275px;
        z-index: -1;

        filter: brightness(90%);
    }

    .partner-section span {
        font-weight: bold;
    }

    .logo-container figure {
        position: relative;
        height: 120px;
        width: 100%;
        background: #FAFAFA;
    }

    .logo-container img {
        max-width: 90%;
        max-height: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: contain;
    }

    .cont-heading h1 {
        max-width: 760px;
        font-size: 24px;
        line-height: 45px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #000000;
        margin: 0 auto;
    }

    .contact-us h3 {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #000000;
        margin: 30px auto 0;
    }

    .row {
        padding-top: 20px;
    }

    @media screen and (max-width : 576px) {
        .cont-heading h1 {
            font-size: 18px;
            margin: 0;
        }

        .contact-us h3 {
            font-size: 16px;
        }

        .partner-section {
            margin-bottom: 70px;
        }

        .logo-container figure {
            height: 100px;
        }

        .row {
            padding-top: 60px;
        }

        .logo-container img {
            max-width: 70%;
        }
    }
</style>
<script>
    export default {}
</script>
