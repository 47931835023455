<template>
    <div class="thank-you-page container text-center">
        <div v-if="!paymentStatus" class="spinner-container">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <template v-else>
            <h1 class="mb-4">Thank You Payment!</h1>
            <p>
                We have received your query please wait while we get back to
                you.
            </p>
            <hr />
            <router-link class="theme-btn-2 d-inline-block" to="/"
                >Redirect To HomePage</router-link
            >
        </template>
    </div>
</template>
<style scoped>
.spinner-container {
    height: 25.2vh;
}
.thank-you-page {
    margin: 200px auto;
}

h1 {
    font-weight: bold;
    font-size: 60px;
    line-height: 72px;
    color: #ea5b33;
}

p {
    font-size: 20px;
}

hr {
    background-color: #ea5b33;
    height: 3px;
    width: 30px;
    margin: 30px auto;
    opacity: 1;
}

@media screen and (max-width: 576px) {
    .thank-you-page {
        margin: 150px auto;
    }

    h1 {
        font-size: 48px;
        line-height: 60px;
    }

    p {
        font-size: 16px;
    }
}
</style>

<script>
import Swal from "sweetalert2";
import { ref, inject } from "vue";
import { useRoute } from "vue-router";
import { setApiEndpoint } from "../../../compositions/setFunction";

export default {
    setup() {
        const routerQuery = useRoute().query;
        const userId = inject("userInfo").value.email;
        const api_hostname = setApiEndpoint(); // api 주소
        const apiEndpoint = `${api_hostname}/toss-payment/${userId}`; // api 상세 주소
        const paymentStatus = ref(false);

        // toss 결제 연동
        const paymentApproval = async () => {
            const responseFetch = await fetch(apiEndpoint, {
                method: "POST",
                body: JSON.stringify(routerQuery),
            });

            const response = await responseFetch.json();

            if (response.status === "DONE") {
                paymentStatus.value = true;
            } else {
                await Swal.fire({
                    title: response.message,
                    icon: "error",
                    confirmButtonColor: "#E36159",
                    confirmButtonText: "Home",
                });

                document.location.href = "/about/";
            }
        };

        paymentApproval();
        return {
            paymentStatus,
        };
    },
};
</script>
