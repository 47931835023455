import freeDomainList from "@/assets/mail/free-domains-list.json";

const emailRegex = /^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/;
const validateEmail = (email) => {
	if(!emailRegex.test(email))
		return false
	
	const emailDomains = email.split("@")[1];

	if(freeDomainList.includes(emailDomains))
		throw new Error("Please enter your work/office email.");
	
	return true;
};

const validateEmpty = (...strings) => strings.every(string => string != null && !(string.replace(/(\s*)/g, "") === ""));
const validateSpace = (...strings) => strings.every(string => string != null && !(/[\s]/.test(string)));

// 이름 유효성 검사
const validateName = (userData) => {
	let nameReg = /^[가-힣a-zA-Z]{2,15}$/gi; //이름 유효성검사 정규식
	let asteriskReg = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@#$%&\\\=\(\'\"]/gi;
	let gapReg = / /gi; // 공백 체크

	// 이름 조건
	if (userData === "" || userData === null || userData === undefined) {
		return "이름을 입력해주세요";
	}

	// 이름 길이
	if (userData.length < 2 || userData.length > 30) {
		return "이름은 2자에서 30자까지입니다.";
	}

	// 이름 특수문자 체크
	if (asteriskReg.test(userData)) {
		return "이름은 특수문자를 사용할 수 없습니다.";
	}

	// 공백체크
	if (gapReg.test(userData)) {
		return "이름은 공백을 사용할 수 없습니다.";
	}

	// 이름 입력 유효성 검사
	if (!nameReg.test(userData)) {
		return "이름을 올바르게 입력해주세요.";
	}
};

// 핸드포 유효성 검사
const validatePhone = (userData) => {
	let phoneRefExp = /^(01[016789]{1})-?[0-9]{3,4}-?[0-9]{4}$/; // 핸드폰 번호 정규식

	// 번호가 빈칸일경우
	if (userData === "" || userData === null || userData === undefined) {
		return "전화번호를 입력해주세요.";
	}

	// 번호가 11자가 아닐경우
	if (userData.length !== 11) {
		return "휴대 번호를 입력해주세요. - 11자";
	}

	// 번호가 규칙에 어긋날경우
	if (!phoneRefExp.test(userData)) {
		return "형식에 맞게 숫자만 입력해 주세요.";
	}
};

export {
    validateEmail,
    validateEmpty,
    validateSpace,
    validateName,
    validatePhone
}
