<template>
    <license-notice />
    <article>
        <section class="price-section">
            <div class="pricing-main w-100">
                <img src="@/assets/images/pricing-main.png" alt="" />
            </div>
            <div class="container">
                <div class="cont-heading text-md-center">
                    <h1>
                        Wondering how much these highly optimized models will
                        cost you?
                    </h1>
                </div>

                <div class="row">
                    <div class="col-md-4 mb-5">
                        <div class="card h-100">
                            <div class="card-color yellow"></div>
                            <h6>Free Subscription</h6>
                            <p>Start Plan</p>
                            <ul class="p-0 price-card-content">
                                <li>
                                    <i class="fas fa-check"></i>
                                    Select any models *free of cost with signup.
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    Forum support
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    FAQ
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    Documentations for Installation
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    non-commercial use only
                                </li>
                            </ul>
                            <router-link
                                :to="signUpLink"
                                class="theme-btn-2 mt-auto text-center"
                                >Free Sign up</router-link
                            >
                        </div>
                    </div>

                    <div class="col-md-4 mb-5">
                        <div class="card h-100">
                            <div class="card-color red"></div>
                            <div class="d-flex">
                                <h6>Standard Support</h6>
                                <div class="pill">Most Popular</div>
                            </div>
                            <p>Standard Plan</p>
                            <ul class="p-0 price-card-content">
                                <li>
                                    <i class="fas fa-check"></i>
                                    Pay only incase of any issues or questions.
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    Select any models *free of cost with signup.
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    Forum support
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    FAQ
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    Documentations for Installation
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    Email Support
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    Online (Chat/Call/Remote) support
                                </li>
                            </ul>
                            <router-link
                                to="/market"
                                class="theme-btn mt-auto text-center"
                                >$ 10,000 per model yearly</router-link
                            >
                        </div>
                    </div>

                    <div class="col-md-4 mb-5">
                        <div class="card h-100">
                            <div class="card-color yellow"></div>
                            <h6>Enterprise support</h6>
                            <p>Enterprise Plan</p>
                            <ul class="p-0 price-card-content">
                                <li>
                                    <i class="fas fa-check"></i>
                                    You get team of experts from SOYNET to
                                    optimize your private models
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    Forum support
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    FAQ
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    Documentations for Installation
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    Email Support
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    Online (Chat/Call/Remote) support
                                </li>
                            </ul>
                            <router-link
                                to="/contact-us"
                                class="theme-btn-2 mt-auto text-center"
                                >Contact us for pricing</router-link
                            >
                        </div>
                    </div>
                </div>
                <p class="text-center">
                    If you cannot find models supporting your enviornment
                    details contact us : sales@soynet.io
                </p>
            </div>
            <about-event-modal-vue />
        </section>
    </article>
</template>

<style scoped>
.price-section {
    padding: 0 0 80px;
}

.pricing-main {
    margin-bottom: 50px;
}

.pricing-main img {
    width: 100%;
    object-fit: cover;
    object-position: top;
    max-height: 470px;
    min-height: 275px;
    z-index: -1;

    filter: brightness(90%);
}

.cont-heading {
    margin-bottom: 90px;
}

.cont-heading h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.01em;

    color: #ea5b33;
}

.cont-heading h1 {
    max-width: 760px;
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    letter-spacing: -0.01em;

    color: #000000;

    margin: 0 auto;
}

.price-section .card h6 {
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    color: #000000;
}

.price-section .card {
    padding: 30px;
    border-radius: 10px;
    box-shadow: 2px 2px 15px #ddd;
    min-height: 490px;
}

.price-section ul {
    list-style: none;
}

.price-section ul li {
    margin-bottom: 10px;
    margin-left: 20px;
    text-indent: -10px;
}

.price-section ul li i {
    color: #62a1ff;
}

.card-color {
    border-radius: 10px 10px 0 0;
    height: 7px;
    margin: -30px -30px 37px;
}

.card-color.yellow {
    background: #ffbb00;
}

.card-color.red {
    background: #e36159;
}

.pill {
    font-weight: 500;
    font-size: 12px;
    line-height: 30px;
    color: #e36159;
    background: rgba(227, 97, 89, 0.1);
    border-radius: 5px;
    padding: 0 6px;
    height: fit-content;
    margin-left: 10px;
}

@media screen and (max-width: 576px) {
    .price-section {
        padding: 0 0 70px;
    }

    .cont-heading h1 {
        font-size: 18px;
        margin: 0 0;
    }

    .price-section ul li {
        font-size: 14px;
    }
}
</style>

<script>
import AboutEventModalVue from "@/views/layouts/AboutEventModal3.vue";
import LicenseNotice from "@/components/LicenseNotice.vue";
import { inject, computed, ref } from "vue";

export default {
    components: {
        LicenseNotice,
        AboutEventModalVue,
    },
    setup() {
        const userInfo = inject("userInfo");

        const signUpLink = computed(() =>
            userInfo.value != null ? "/market" : "/auth/sign-in"
        );
        return {
            signUpLink,
        };
    },
};
</script>
