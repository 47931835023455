<template>
    <section class="career-main-section w-100">
        <img src="@/assets/images/career-main.jpg" alt="">
        <div class="text-center w-100 text-white career-title">
            <h2>
                Career
            </h2>
            <h1>
                We Are Hiring!
            </h1>
        </div>
    </section>
</template>

<style scoped>
    .career-main-section {
        position: relative;
    }

    .career-main-section img {
        width: 100%;
        object-fit: cover;
        object-position: center;
        max-height: 470px;
        min-height: 275px;
        z-index: -1;
        filter : brightness(70%);
    }

    .career-title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
    }

    .career-title h1 {
        font-weight: bold;
        font-size: 50px;
        line-height: 75px;
        letter-spacing: -0.01em;

        color: #FFFFFF;
    }

    .career-title h2 {
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.01em;

        color: #FFFFFF;
    }

    @media screen and (max-width : 576px) {
        .career-title h1 {
            font-size: 32px;
            line-height: 48px;
        }
    }
</style>

<script>
    export default {}
</script>
