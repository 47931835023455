import Swal from "sweetalert2";
import { setApiEndpoint } from "./setFunction";

const api_hostname = setApiEndpoint(); // api 주소
const API_ENDPOINT = `${api_hostname}/sign`; // api endpoint

// 모델 구입시 이메일로 전자서명 전송, Input : userid, 이름, 모델정보
export const sendContract = async ({ id, name, models }) => {
    // 유료로 사용하는 모델을 걸러주는 필터
    models = models.filter((value) => value !== null);
    if (models.length === 0) return true;

    const { value } = await Swal.fire({
        title: "The contract will be sent to your email",
        html: `<p>
                        Email and Name will be used for the contract.
                    </p>
                    <label>
                        Email :
                        <input placeholder="${id}" class="swal2-input shadow-none border-0" value="${id}" readonly>
                    </label>
                    <label>
                        Name :
                        <input id="swal-input" placeholder="Enter your name" class="swal2-input" value="${name}">
                    </label>`,
        focusConfirm: false,
        showCancelButton: true,
        showLoaderOnConfirm: true,
        backdrop: true,
        allowOutsideClick: () => !Swal.isLoading(),
        allowEscapeKey: () => !Swal.isLoading(),
        preConfirm: async () => {
            try {
                const signerName = document.getElementById("swal-input").value; // 사용자가 입력한 이름

                if (signerName.replace(/\s/gi, "").length < 1) {
                    Swal.showValidationMessage(`You must enter your Name`);
                }

                // 서버 전송
                const response = await fetch(API_ENDPOINT, {
                    method: "POST",
                    body: JSON.stringify({
                        signerEmail: id,
                        signerName,
                        models,
                    }),
                });

                if (!response.ok) {
                    throw new Error("Sending Contract failed.");
                }

                return await response.json();
            } catch (err) {
                Swal.showValidationMessage(`Request failed: ${err}`);
            }
        },
        confirmButtonText: "Submit",
        confirmButtonColor: "#E36159",
    });

    return value;
};

// 모델 구입시 구입 동의와 안내문, Input : 동의 체크여부, 제목, onClick이벤트
export const paymentAlert = async ({
    initialValue = false,
    title,
    onClick = () => {},
}) => {
    const { isConfirmed } = await Swal.fire({
        title: title,
        text: "Would you like to proceed subscription?",
        input: "checkbox",
        inputValue: !!initialValue,
        inputPlaceholder:
            'I agree with the <a id="terms" href="">terms and conditions</a>',
        didRender: (target) => {
            target
                .getElementsByTagName("a")
                .terms.addEventListener("click", (e) => {
                    e.preventDefault();
                    onClick();
                    Swal.close();
                });

            target.getElementsByTagName("input")[
                "swal2-checkbox"
            ].style.display = "none";
        },
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#E36159",
        // inputValidator: (result) => {
        //     return (
        //         !result && "You must read and agree to the terms and conditions"
        //     );
        // },
    });

    if (!initialValue && isConfirmed) {
        onClick();
    }

    return initialValue && isConfirmed;
};
