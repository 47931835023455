import {
    createWebHashHistory,
    createWebHistory,
    createRouter,
} from "vue-router";
import Vue from "vue";

import Header from "@/views/layouts/Header.vue";
import Footer from "@/views/layouts/Footer.vue";
import NotFound from "@/views/layouts/NotFound.vue";

import About from "@/views/pages/About/About.vue";
import ModelMarket from "@/views/pages/ModelMarket/ModelMarket.vue";
import Blogs from "@/views/pages/Blogs/Blogs.vue";
import CaseStudy from "@/views/pages/CaseStudy/CaseStudy.vue";
import Partners from "@/views/pages/Partners/Partners.vue";
import Pricing from "@/views/pages/Pricing/Pricing.vue";
import Career from "@/views/pages/Career/Career.vue";
import ContactUs from "@/views/pages/ContactUs/ContactUs.vue";
import WhatIsSoynet from "@/views/pages/WhatIsSoynet/WhatIsSoynet.vue";
import BlogPost from "@/views/pages/BlogPost/BlogPost.vue";
import CaseStudyPost from "@/views/pages/CaseStudyPost/CaseStudyPost.vue";
import ModelMarketProduct from "@/views/pages/ModelMarketProduct/ModelMarketProduct.vue";
import BlogEditor from "@/views/pages/BlogEditor/BlogEditor.vue";
import Auth from "@/views/pages/Auth/Auth.vue";
import ModelEditor from "@/views/pages/ModelEditor/ModelEditor.vue";
import MyPage from "@/views/pages/MyPage/MyPage.vue";
import AdminPage from "@/views/pages/AdminPage/AdminPage.vue";
import Cart from "@/views/pages/Cart/Cart.vue";
import CaseStudyEditor from "@/views/pages/CaseStudyEditor/CaseStudyEditor.vue";
import ThankYouContactUs from "@/views/pages/ThankYouPage/ThankYouContactUs.vue";
import ThankYouSignUp from "@/views/pages/ThankYouPage/ThankYouSignUp.vue";
import ThankYouPurchase from "@/views/pages/ThankYouPage/ThankYouPurchase.vue";
import ThankYouPayment from "@/views/pages/ThankYouPage/ThankYouPayment.vue";
import PrivacyPolicy from "@/views/pages/Policy/PrivacyPolicy.vue";
import CookiePolicy from "@/views/pages/Policy/CookiePolicy.vue";
import ModelPerformance from "@/views/pages/ModelMarketProduct/ModelMarketPerformance.vue";

import user from "@/compositions/User.js";

import Swal from "sweetalert2";

const { checkAuthority, userInfo } = user;

// 사용자 권한이 페이지에 접속할수 있는 권한에 포함되지 않았을때 작동
const noAuthorityAlert = () =>
    Swal.fire({
        title: "Sorry, You Are Not Allowed to Access This Page.",
        icon: "error",
        confirmButtonColor: "#E36159",
    });

const routes = [
    {
        path: "/",
        redirect: "/about/",
    },
    {
        path: "/about/",
        name: "About us",
        components: { default: About, header: Header, footer: Footer },
        props: {},
    },
    {
        path: "/market/",
        name: "Model Market",
        components: { default: ModelMarket, header: Header, footer: Footer },
        props: {},
    },
    {
        path: "/blogs/",
        name: "Blogs",
        components: { default: Blogs, header: Header, footer: Footer },
        props: {},
    },
    {
        path: "/case-study/",
        name: "Case Study",
        components: { default: CaseStudy, header: Header, footer: Footer },
        props: {},
    },
    {
        path: "/partners/",
        name: "Partners",
        components: { default: Partners, header: Header, footer: Footer },
        props: {},
    },
    {
        path: "/pricing/",
        name: "Pricing",
        components: { default: Pricing, header: Header, footer: Footer },
        props: {},
    },
    {
        path: "/career/",
        name: "Career",
        components: { default: Career, header: Header, footer: Footer },
        props: {},
    },
    {
        path: "/contact-us/",
        name: "Contact us",
        components: { default: ContactUs, header: Header, footer: Footer },
        props: {},
    },
    {
        path: "/what-is-soynet/",
        name: "What is SoyNet",
        components: { default: WhatIsSoynet, header: Header, footer: Footer },
        props: {},
    },
    {
        path: "/blog-post/:urlTitle/",
        name: "Blog Post",
        components: { default: BlogPost, header: Header, footer: Footer },
        props: true,
        meta: {
            title: "Blog Post",
        },
    },
    {
        path: "/case-study-post/:id/",
        name: "Case Study Post",
        components: { default: CaseStudyPost, header: Header, footer: Footer },
        props: true,
        meta: {
            title: "Case Study Post",
        },
    },
    {
        path: "/market-detail/:modelSequence/",
        name: "Market Detail",
        components: {
            default: ModelMarketProduct,
            header: Header,
            footer: Footer,
        },
        props: true,
        meta: {
            title: "Market Detail",
            description: "Market Detail",
        },
    },
    {
        path: "/auth/:page/",
        name: "Auth",
        components: { default: Auth },
        props: true,
        meta: {
            title: "Auth",
            description: "Auth",
        },
    },
    {
        path: "/blog-editor/",
        name: "Blog Editor",
        beforeEnter: async (to, from) => {
            if (await checkAuthority(["admin", "developer", "bloger"])) {
                return;
            } else {
                noAuthorityAlert();
                return { path: from.path };
            }
        },
        components: { default: BlogEditor, header: Header, footer: Footer },
        meta: {
            title: "Blog Editor",
            description: "Blog Editor",
        },
    },
    {
        path: "/model-editor/",
        name: "Model Editor",
        beforeEnter: async (to, from) => {
            if (await checkAuthority(["admin", "developer"])) {
                return;
            } else {
                noAuthorityAlert();
                return { path: from.path };
            }
        },
        components: { default: ModelEditor, header: Header, footer: Footer },
        meta: {
            title: "Model Editor",
            description: "Model Editor",
        },
    },
    {
        path: "/case-study-editor/",
        name: "Case Study Editor",
        beforeEnter: async (to, from) => {
            if (await checkAuthority(["admin", "developer", "bloger"])) {
                return;
            } else {
                noAuthorityAlert();
                return { path: from.path };
            }
        },
        components: {
            default: CaseStudyEditor,
            header: Header,
            footer: Footer,
        },
        meta: {
            title: "Case Study Editor",
            description: "Case Study Editor",
        },
    },
    {
        path: "/my-page/",
        name: "My page",
        beforeEnter: async (to, from) => {
            if (userInfo.value != null) {
                return;
            } else {
                noAuthorityAlert();
                return { path: from.path };
            }
        },
        components: { default: MyPage, header: Header, footer: Footer },
        meta: {
            title: "My page",
            description: "My page",
        },
    },
    {
        path: "/cart/",
        name: "Cart",
        beforeEnter: async (to, from) => {
            if (userInfo.value != null) {
                return;
            } else {
                noAuthorityAlert();
                return { path: from.path };
            }
        },
        components: { default: Cart, header: Header, footer: Footer },
        meta: {
            title: "Cart",
            description: "Cart",
        },
    },
    {
        path: "/admin-page/",
        name: "Admin page",
        beforeEnter: async (to, from) => {
            if (await checkAuthority(["admin", "developer"])) {
                return;
            } else {
                noAuthorityAlert();
                return { path: from.path };
            }
        },
        components: { default: AdminPage, header: Header, footer: Footer },
        meta: {
            title: "Admin page",
            description: "Admin page",
        },
    },
    {
        path: "/thank-you/contact-us/",
        name: "Thank you Contacting us",
        components: {
            default: ThankYouContactUs,
            header: Header,
            footer: Footer,
        },
        meta: {
            title: "Thank you Contacting us",
            description: "Thank you Contacting us",
        },
    },
    {
        path: "/thank-you/sign-up/",
        name: "Sign up completion",
        components: { default: ThankYouSignUp, header: Header, footer: Footer },
        meta: {
            title: "Sign up completion",
            description: "Sign up completion",
        },
    },
    {
        path: "/thank-you/purchase/",
        name: "Purchase completion",
        components: {
            default: ThankYouPurchase,
            header: Header,
            footer: Footer,
        },
        meta: {
            title: "Purchase completion",
            description: "Purchase completion",
        },
    },
    {
        path: "/thank-you/payment/",
        name: "Payment completion",
        components: {
            default: ThankYouPayment,
            header: Header,
            footer: Footer,
        },
        meta: {
            title: "Payment completion",
            description: "Payment completion",
        },
    },
    {
        path: "/policy/privacy-policy/",
        name: "Privacy policy",
        components: { default: PrivacyPolicy, header: Header, footer: Footer },
        meta: {
            title: "Privacy policy",
            description: "Privacy policy",
        },
    },
    {
        path: "/policy/cookie-policy/",
        name: "Cookie policy",
        components: { default: CookiePolicy, header: Header, footer: Footer },
        meta: {
            title: "Cookie policy",
            description: "Cookie policy",
        },
    },
    {
        path: "/:pathMatch(.*)*",
        components: { header: Header, footer: Footer, default: NotFound },
        meta: {
            title: "Not found",
            description: "Not found",
        },
    },
    {
        path: "/model-performance",
        components: {
            header: Header,
            footer: Footer,
            default: ModelPerformance,
        },
        meta: {
            title: "Model Performance",
            description: "Model Performance",
        },
    },
];

const router = createRouter({
    // history: createWebHashHistory(),
    history: createWebHistory(),
    routes,
    scrollBehavior: (to) => {
        if (to.hash) {
            return { selector: to.hash, behavior: "instant" };
        } else {
            return { left: 0, top: 0, behavior: "instant" };
        }
    },
});

router.beforeEach((to, from) => {
    const title = to.meta.title === undefined ? "SOYNET" : to.meta.title;
    document.title = title;
});

export default router;
