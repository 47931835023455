<template>
    <article>
        <section class="auth-main">
            <div class="d-flex h-100">
                <figure class="m-0 auth-video d-none d-md-block">
                    <video
                        src="@/assets/images/about-us_bgmovie_640x360.mp4"
                        muted
                        autoplay
                        loop
                    ></video>
                    <div class="auth-title">
                        <h1>SOYNET a S/W based AI Inference Accelerator.</h1>
                        <h6>
                            We provide S/W based general-purpose accelerator
                            solution that can be used to accelerate various
                            models used for image processing/natural
                        </h6>
                    </div>
                </figure>

                <div class="form-container">
                    <component :is="pages[page]"></component>
                </div>
            </div>
        </section>
    </article>
</template>

<style scoped>
.auth-main {
    height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.auth-video {
    height: 100%;
    width: 48%;
    position: relative;
}

.auth-video video {
    object-fit: cover;
    height: 100%;
    width: 100%;
    filter: brightness(20%);
}

.auth-title {
    width: 100%;
    padding: 0 28px;
    position: absolute;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.auth-title h1 {
    font-weight: bold;
    font-size: 50px;
    line-height: 70px;
    color: #ffffff;
}

.auth-title h6 {
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
}

.form-container {
    width: 52%;
    position: relative;
}

@media screen and (max-width: 991px) {
    .auth-title {
        padding: 0 28px;
    }

    .auth-title h1 {
        font-size: 30px;
        line-height: 50px;
    }

    .auth-title h6 {
        font-size: 14px;
        line-height: 24px;
    }
}

@media screen and (max-width: 767px) {
    .form-container {
        width: 100%;
    }
}
</style>

<script>
import AuthSignIn from "./AuthSignIn.vue";
import AuthSignUp from "./AuthSignUp.vue";
import AuthSignUpConfirm from "./AuthSignUpConfirm.vue";
import AuthResetPassword from "./AuthResetPassword.vue";
import AuthNewPassword from "./AuthNewPassword.vue";
import { shallowRef } from "vue";

// auth 하위 페이지 정의
const pages = {
    "sign-in": shallowRef(AuthSignIn),
    "sign-up": shallowRef(AuthSignUp),
    "sign-up-confirm": shallowRef(AuthSignUpConfirm),
    "reset-password": shallowRef(AuthResetPassword),
    "new-password": shallowRef(AuthNewPassword),
};

export default {
    props: ["page"],
    inject: ["userInfo"],
    data() {
        return {
            pages,
        };
    },
    mounted() {
        if (this.userInfo) {
            this.$router.push("/about/");
        }
    },
};
</script>
