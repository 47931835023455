<template>
    <section class="blog-main">
        <div class="container">
            <router-link
                class="edit-controls"
                to="/blog-editor"
                v-if="isConetentWriter"
            >
                <h1>Add Blog</h1>
                <label>
                    <i class="far fa-plus-square"></i>
                </label>
            </router-link>
            <div
                class="row"
                v-for="(
                    { id, summary, thumbnailKey, title, urlTitle }, index
                ) in sortedPosts"
                :key="id"
            >
                <div class="col-lg-6">
                    <figure class="m-0">
                        <img class="rounded" :src="thumbnailKey" alt="" />
                    </figure>
                </div>
                <div class="col-lg-6">
                    <div class="content">
                        <div class="d-flex justify-content-between">
                            <p class="date">
                                {{
                                    new Date(+id).toLocaleDateString("en-US", {
                                        month: "long",
                                        year: "numeric",
                                        day: "2-digit",
                                    })
                                }}
                            </p>
                            <div v-if="isConetentWriter">
                                <router-link
                                    class="admin-control me-3"
                                    :to="{
                                        name: 'Blog Editor',
                                        query: { id },
                                    }"
                                    ><i class="fas fa-pencil-alt"></i
                                ></router-link>
                                <a
                                    class="admin-control"
                                    @click="deleteBlogPost(id)"
                                    ><i class="far fa-trash-alt"></i
                                ></a>
                            </div>
                        </div>
                        <h1>{{ title }}</h1>
                        <h6 class="m-0">
                            {{ summary }}
                        </h6>
                        <router-link
                            :to="{
                                name: 'Blog Post',
                                params: { urlTitle },
                                query: { id },
                            }"
                            class="learn-more"
                            >Read More<i class="fas fa-chevron-right"></i
                        ></router-link>
                    </div>
                </div>
                <hr v-if="index + 1 < posts.length" />
            </div>
        </div>
    </section>
</template>

<style scoped>
.blog-main {
    margin: 100px 0;
    min-height: 55vh;
}

hr {
    margin: 55px 0 60px;
}

figure {
    width: 100%;
    height: 100%;
    padding-top: 63%;
    position: relative;
}

figure img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.edit-controls {
    display: block;
    text-decoration: none;
    height: 20vh;
    min-height: 145px;
    border: 1.5px dashed #bbbbbb;
    box-sizing: border-box;
    border-radius: 2px;
    margin: 0 0 50px;
}

.edit-controls h1 {
    font-size: 40px;
    font-weight: bold;
    color: #bbbbbb;
    text-align: center;

    margin: 12px 0;
}

.edit-controls label {
    width: 100%;
    font-size: 60px;
    color: #bbbbbb;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.content {
    margin-left: 25px;
}

.content .date {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #b3b3b3;

    margin: 15px 0 5px;
}

.content h1 {
    font-weight: bold;
    font-size: 50px;
    line-height: 55px;
    color: #000000;
}

.content h6 {
    font-size: 16px;
    line-height: 30px;
    color: #000000;
}

.content .learn-more {
    display: inline-block;
    font-weight: bold;
    color: #e36159;
    text-decoration: none;
}

.content .learn-more:hover i {
    margin-left: 10px;
}

.content .learn-more i {
    margin-left: 5px;
    transition: all 0.4s ease-in-out;
}

.admin-control {
    display: inline-block;
    font-size: 20px;
    cursor: pointer;
    margin: 15px 0 5px;
}

@media screen and (max-width: 767px) {
    hr {
        margin: 20px 0 30px;
    }

    .blog-main {
        margin: 70px 0;
    }

    .content {
        margin-left: 0;
    }

    .content h1 {
        line-height: 24px;
        font-size: 16px;
    }

    .content h6 {
        font-size: 14px;
    }
}
</style>

<script>
import blog from "@/compositions/Blog.js";

import { computed, inject } from "vue";

import Swal from "sweetalert2";

export default {
    setup() {
        const allowedRoles = ["admin", "bloger", "developer"]; // 사용자 권한 목록

        const userInfo = inject("userInfo"); // 사용자 정보

        blog.listBlogPosts(); // 블로그 리스트 조회

        // 블로그 삭제 Input : blogPostId
        const deleteBlogPost = async (id) => {
            const { isConfirmed } = await Swal.fire({
                title: "Delete this post?",
                showCancelButton: true,
                confirmButtonText: "Yes",
                confirmButtonColor: "#E36159",
            });

            if (isConfirmed && (await blog.deleteBlogPost(id))) {
                await blog.listBlogPosts();
            }
        };

        // 블로그 정렬
        const sortedPosts = computed(() =>
            [...blog.blogPosts.value].sort(
                ({ id: id1 }, { id: id2 }) => id2 - id1
            )
        );

        // 블로그 작성 권한 확인
        const isConetentWriter = computed(() =>
            allowedRoles.includes(userInfo.value?.authority)
        );

        return {
            posts: blog.blogPosts,
            sortedPosts,
            deleteBlogPost,
            isConetentWriter,
        };
    },
};
</script>
