<template>
    <license-notice></license-notice>
    <section class="my-page">
        <my-account :userInfo="userInfo"></my-account>
        <my-models :id="userInfo.email"></my-models>
    </section>
</template>
<style scoped>
.my-page {
    margin: 100px 0;
}
</style>
<script>
import MyAccount from "./MyAccount.vue";
import MyModels from "./MyModels.vue";
import LicenseNotice from "@/components/LicenseNotice.vue";
import { inject } from "vue";

export default {
    components: { MyAccount, MyModels, LicenseNotice },
    setup() {
        const userInfo = inject("userInfo");
        return {
            userInfo,
        };
    },
};
</script>
