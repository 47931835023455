<template>
    <section class="what-is-soynet-section">
        <div class="container">
            <div class="cont-heading">
                <h1>
                    What is SoyNet
                </h1>
            </div>
            <div class="contents">
                <h1>
                    SoyNet
                </h1>

                <p>
                    SoyNet, an inference-specialized acceleration solution for AI models, has been developed to replace
                    existing AI frameworks, including TensorFlow, PyTorch, and Caffe, and deliver <strong>faster
                        inference services.</strong>
                </p>
                <img class="w-100" src="@/assets/images/soynet1.png">
            </div>

            <div class="contents">
                <h1>
                    AI Inference Accelerator
                </h1>

                <p>
                    Intended not for specific AI models but for inference execution, SoyNet can be integrated to run
                    countless AI models.
                </p>
                <img class="w-100" src="@/assets/images/soynet2.png">
            </div>

            <div class="contents">
                <h1>
                    Faster Execution
                </h1>

                <p class="m-0">
                    According to our tests on the Yolo V3 model, SoyNet’s processing speed was 3 times faster and its
                    memory usage 1/9 less than GPU-based TensorFlow.
                </p>
                <p>
                    <strong>Performance results may vary depending on specific AI models, but SoyNet, in general,
                        performs 2-5 times faster and uses 1/5-1/9 less memory compared to other accelerators.</strong>
                </p>
                <img class="w-100" src="@/assets/images/soynet3.png">

                <h5>Benchmark Environments</h5>
                <p>
                    <strong>Benchmark Environments</strong> OS(Windows 10 x64), CPU(i7-8770), Mem(16GB), GPU(GTX1080Ti
                    11GB)
                </p>
                <p>
                    <strong>Model</strong> YOLOv3-DarkNet53 (CNN based Object Detection)
                </p>
                <p>
                    <strong>Model</strong> Size 416x416, Float32, TensorFlow 1.12 (using TensorPT 4.0)
                </p>
            </div>

            <div class="contents">
                <h1>
                    Support for Various Models in Combination
                </h1>

                <p class="m-0">
                    The latest deep learning models introduced by recent research articles can only be executed alone
                    and on a high-end GPU like GTX1080ti,
                </p>

                <p>
                    <strong>SoyNet can run a combination of several models unveiled by the latest studies, ultimately
                        expanding the scope of feasible services.</strong>
                </p>
                <img class="w-100" src="@/assets/images/soynet4.png">
            </div>

            <div class="contents">
                <h1>
                    Lower Cost
                </h1>

                <p class="m-0">
                    <strong>SoyNet’s specialized acceleration solutions reduce our clients’ server costs.</strong>
                </p>
                <p class="m-0">
                    See below for SoyNet’s cost-cutting benefits in an intelligent CCTV architecture.
                </p>
                <p>
                    SoyNet supports over 3 times as many camera channels as before with the same equipment, reducing the
                    cost of installing additional video analysis equipment.
                </p>
            </div>


            <div class="contents">
                <h1>SoyNet's Cost-Cutting Benefits</h1>

                <p class="m-0">
                    <strong>Intelligent CCTVs are fast emerging as a critical element of a smart city.</strong>
                </p>


                <p class="m-0">
                    <strong>As the Korean government has successfully completed the Intelligent CCTV Pilot Project, more
                        and more local governments are adopting this innovative security solution.</strong>
                </p>

                <p>
                    When an AI-based video analysis server is run on SoyNet, it can process more than 3 times more
                    camera channels than before, considerably lowering server costs.
                </p>
            </div>

            <div class="contents">
                <h1 class="mt-5 title">To cover the same amount of IP camera channels like</h1>
                <img class="w-100" src="@/assets/images/soynet5.png">
            </div>


            <div class="contents">
                <h1>
                    Development Process and Resources Utilization
                </h1>

                <p class="m-0">
                    <strong>SoyNet enables you to reduce your reliance on high-cost AI specialists and harness your
                        existing application developers for AI service development.</strong>
                </p>

                <p>
                    SoyNet lets you solely focus on your domain model in the development phase and run your service
                    right away in its optimal condition once the model is developed.
                </p>
            </div>

            <div class="contents">
                <h1>Development Process</h1>
                <img class="w-100" src="@/assets/images/soynet6.png">
            </div>

            <div class="contents">
                <h1>Resources Utilization</h1>
                <img class="w-100" src="@/assets/images/soynet7.png">
            </div>

            <div class="contents benefits">
                <h1>
                    SoyNet’s Benefits
                </h1>

                <div class="row">
                    <div class="col-md-6">
                        <img src="@/assets/images/gd_i1.png">
                        <h3 class="title p-0">Reduced Costs</h3>
                        <p>
                            SoyNet supports larger processing tasks using the same equipment, lowering high-end server
                            costs for service execution.
                        </p>
                    </div>
                    <div class="col-md-6">
                        <img src="@/assets/images/gd_i2.png">
                        <h3 class="title p-0">Support for High-Speed Processing </h3>
                        <p>
                            In various environments requiring high-speeds with limited resources, such as autonomous
                            driving, SoyNet offers handy solutions to resolve execution speed issues.
                        </p>
                    </div>
                    <div class="col-md-6">
                        <img src="@/assets/images/gd_i3.png">
                        <h3 class="title p-0">Workable with No Internet Connection</h3>
                        <p>
                            Unlike existing Cloud-based inference processing, SoyNet can be embedded into edge devices,
                            offering inference processing at the edge without Internet connection, thereby supporting
                            businesses to expand their service scope.
                        </p>
                    </div>
                    <div class="col-md-6">
                        <img src="@/assets/images/gd_i4.png">
                        <h3 class="title p-0">Greater Value from Existing Developer Resources</h3>
                        <p>
                            SoyNet APIs enable businesses to develop AI services with their own existing application
                            developer resources instead of highly-trained AI specialists, helping them harness their
                            existing developer pool more efficiently.
                        </p>
                    </div>
                </div>

            </div>
        </div>
        <!-- <about-event-modal-vue /> -->
    </section>
</template>

<style scoped>
strong {
    font-weight: 600;
}

.what-is-soynet-section {
    text-align: center;
    margin: 70px 0 100px;
}

.cont-heading {
    margin-bottom: 70px;
}

.cont-heading h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.01em;
    text-align: center;

    color: #EA5B33;
}

.contents {
    margin-bottom: 155px;
}

.contents h1 {
    font-weight: 600;
    font-size: 36px;
    line-height: 30px;
    letter-spacing: -0.01em;
    color: #000000;

    margin-bottom: 35px;
}

.contents p {
    font-size: 16px;
    line-height: 30px;
    color: #000000;
}

.contents h3 {
    font-weight: 500;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: -0.01em;

    color: #000000;
}

.benefits img {
    margin-bottom: 15px;
}

@media screen and (max-width : 576px) {
    .what-is-soynet-section {
        text-align: center;
        margin: 35px 0 70px;
    }

    .cont-heading {
        margin-bottom: 30px;
    }

    .cont-heading h1 {
        font-size: 18px;
        line-height: 27px;
    }

    .contents {
        margin-bottom: 65px;
    }

    .contents h1 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .contents p {
        font-size: 14px;
    }

    .contents h3 {
        font-size: 18px;
    }
}
</style>

<script>
import AboutEventModalVue from '@/views/layouts/AboutEventModal.vue';

export default {
    components: {
        // AboutEventModalVue
    },
};
</script>
