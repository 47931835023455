<template>
    <article>
        <career-main-section />
        <career-join-us-section />
        <career-posting-section />
    </article>
</template>

<script>
    import CareerMainSection from './CareerMainSection.vue';
    import CareerJoinUsSection from './CareerJoinUsSection.vue';
    import CareerPostingSection from './CareerPostingSection.vue';

    export default {
        components: {
            CareerMainSection,
            CareerJoinUsSection,
            CareerPostingSection
        }
    };
</script>
