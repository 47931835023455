<template>
    <div class="notification-container">
        <div class="toast-body">
            <div class="toast-body p-3">
                <div class="row justify-content-center">
                    <div
                        class="col-12 col-md-11 d-flex align-items-center justify-content-center"
                    >
                        <p>
                            For activating GPU license or queries for standard
                            subscription please email: <u>sales@soynet.io</u>
                        </p>
                        <!-- <p>
                            License key issuable model is available only for
                            with soynet version 5.1. For other models, please
                            contact us please email:
                            <u>sales@soynet.io</u>
                        </p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.notification-container {
    width: 100%;
    background-image: url("../assets/images/banner1.png");
    background-size: cover;
}

.toast-body p {
    font-size: 15px;
    padding: 0 21px;
    margin: 0;
    color: #fff;
}

a {
    color: #f9776e;
}

a:hover {
    color: #e36159;
}

u {
    color: #ffb98a;
}

@media screen and (max-width: 576px) {
    .toast-body p {
        font-size: 12px;
    }
}
</style>
<script>
import { ref } from "vue";

export default {
    setup() {
        const notification = ref(null);

        return {
            notification,
        };
    },
};
</script>
