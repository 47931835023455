<template>
    <div class="container-xxl user-list">
        <h1 class="title">User List</h1>
        <div class="searchArea">
            <input
                id="userInput"
                type="month"
                placeholder="Enter search data."
                v-model="createDateTimeValue"
                @change="searchData()"
                v-if="filterCategory === 'createdatetime'"
            />

            <input
                id="userInput"
                type="text"
                placeholder="Enter search data."
                v-model="inputSearchData"
                @keyup="searchData()"
                :disabled="filterCategory === null"
                v-else
            />

            <select
                id="category-select"
                class="form-select searchArea-select"
                @change="selectChange()"
                v-model="filterCategory"
            >
                <option :value="null">ALL</option>
                <option
                    v-for="category in userCategories"
                    :key="category"
                    :value="category"
                >
                    {{ category.toUpperCase() }}
                </option>
            </select>
        </div>
        <div class="userInfoExcelDownArea">
            <button @click="userInfoExcelDown()" class="userInfoExcelDownBtn">
                <img
                    src="@/assets/images/excel-icon.png"
                    alt="excel icon"
                />User Information
            </button>
        </div>
        <!-- {{ totalUser.length }} -->
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">User Type</th>
                        <th scope="col" @click="sortUserList('name')">
                            Name
                            <template v-if="sortKind === 'name'">
                                <span v-if="sortAsc" class="sortIcon">▼</span>
                                <span v-else class="sortIcon">▲</span>
                            </template>
                            <template v-else>
                                <span class="sortIcon">▼</span>
                            </template>
                        </th>
                        <th scope="col">Email</th>
                        <th scope="col">Country</th>
                        <th scope="col">Job Title</th>
                        <th scope="col">Company</th>
                        <!-- <th scope="col">Phone</th> -->
                        <th scope="col" @click="sortUserList('createdatetime')">
                            Create Date
                            <template v-if="sortKind === 'createdatetime'">
                                <span v-if="sortAsc" class="sortIcon">▼</span>
                                <span v-else class="sortIcon">▲</span>
                            </template>
                            <template v-else>
                                <span class="sortIcon">▼</span>
                            </template>
                        </th>
                        <th scope="col">Last Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="userValue in userList" :key="userValue.id">
                        <td class="user-group">
                            {{ userValue.group || "user" }}
                        </td>
                        <td class="user-name">
                            {{ userValue.name }}
                        </td>
                        <td class="user-email">
                            {{ userValue.email }}
                        </td>
                        <td class="user-country">
                            {{ userValue.country }}
                        </td>
                        <td class="user-jobtitle">
                            {{ userValue.jobtitle }}
                        </td>
                        <td class="user-company">
                            {{ userValue.company }}
                        </td>
                        <!-- <td class="user-phoneNumber">
                            {{ userValue.phoneNumber }}
                        </td> -->
                        <td class="user-createDate">
                            {{ userValue.createdatetime || "NONE" }}
                        </td>
                        <td class="user-lastDate">
                            {{ userValue.lastdatetime || "NONE" }}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="no-item text-center" v-if="userList.length < 1">
                <h1>No Item</h1>
            </div>
            <div class="user-list-pageBtn-Area">
                <template v-if="totalPageBtn.length > showPageBtnLen">
                    <button
                        v-if="userListPage !== 1"
                        @click="getUserList(1)"
                        class="user-model-pageBtn-noActive"
                    >
                        &lt&lt
                    </button>
                    <button
                        v-if="userListPage !== 1"
                        @click="getUserList(userListPage - 1)"
                        class="user-model-pageBtn-noActive"
                    >
                        &lt
                    </button>
                </template>
                <template v-for="pageNum in pageBtnList" :key="pageNum">
                    <button
                        v-if="pageNum === userListPage"
                        class="user-list-pageBtn-active"
                    >
                        {{ pageNum }}
                    </button>
                    <button
                        v-else
                        @click="getUserList(pageNum)"
                        class="user-list-pageBtn-noActive"
                    >
                        {{ pageNum }}
                    </button>
                </template>
                <template v-if="totalPageBtn.length > showPageBtnLen">
                    <button
                        v-if="userListPage !== totalPageBtn.length"
                        @click="getUserList(userListPage + 1)"
                        class="user-model-pageBtn-noActive"
                    >
                        >
                    </button>
                    <button
                        v-if="userListPage !== totalPageBtn.length"
                        @click="getUserList(totalPageBtn.length)"
                        class="user-model-pageBtn-noActive"
                    >
                        >>
                    </button>
                </template>
            </div>
        </div>
    </div>
</template>

<style>
.user-list {
    margin-bottom: 100px;
}

.searchArea {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    margin-left: auto;
    margin: 15px 0 30px;
}

.searchArea .searchArea-select {
    max-width: 200px;
    margin-right: 15px;
}

.searchArea input {
    background: #ffffff;
    border: 1.5px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 2px;

    font-size: 15px;
    line-height: 21px;
    letter-spacing: -0.01em;
    padding: 10px;
}

.searchArea input:focus {
    outline: none;
}

.title {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.01em;

    color: #ea5b33;
}

th,
td {
    vertical-align: middle;
    text-align: center;
}

.user-list-pageBtn-Area {
    max-width: 1320px;
    text-align: center;
    margin-top: 15px;
}

.user-list-pageBtn-Area button {
    width: 35px;
    height: 35px;
    margin: 0px 5px;
    text-align: center;
    border: none;
    background-color: #fff;
}

.user-list-pageBtn-active {
    background-color: #ea5b33 !important;
    color: #fff;
}

.user-list-pageBtn-noActive:hover {
    background-color: #ddd;
}

.no-item h1 {
    font-weight: bold;
    font-size: 50px;
    line-height: 45px;
    letter-spacing: -0.01em;

    padding-top: 100px;

    color: #9f9f9f;

    margin-bottom: 15px;
}

.userInfoExcelDownArea {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    margin-bottom: 15px;
}

.userInfoExcelDownBtn {
    min-width: 100px;
    padding: 5px 15px;
    background-color: #eef8d5;
    color: #63af4c;
    border: None;
}

.userInfoExcelDownBtn:hover {
    background-color: #d9e9b1;
}

.userInfoExcelDownBtn img {
    width: 24px;
    margin-right: 8px;
}
</style>

<script>
import UserList from "@/compositions/UserList.js";

import { ref } from "vue";
import { utils, writeFileXLSX } from "xlsx";
import { objectClone } from "@/compositions/setFunction.js";

export default {
    setup() {
        const filterCategory = ref(null); // 검색 종류 값 ex) null = ALL, name = NAME
        const userCategories = ref(["name", "email", "createdatetime"]); // 검색 종류
        const createDateTimeValue = ref(""); // 생성 날짜
        const inputSearchData = ref(""); // 사용자 검색 데이터
        let userListPage = ref(1); // 현재 페이지 번호
        let sortAsc = ref(true); // 오름차순 = false, 내림차순 = true
        let sortKind = ref("createdatetime"); // 처음 조회시 정렬할 칼람

        // page별 데이터 조회 Input : 페이지 버호
        const getUserList = async (pageNum) => {
            await UserList.getUserList(
                pageNum,
                sortAsc.value,
                sortKind.value,
                filterCategory.value,
                inputSearchData.value
            );
            userListPage.value = pageNum;
        };

        getUserList(userListPage.value); // 첫 화면때 받을 데이터 조회

        // 정렬 Input : 정렬 기준
        const sortUserList = async (kind) => {
            // 정렬 기준이 예전과 같으면 내림차순 아니면 반대
            if (sortKind.value !== kind) {
                sortAsc.value = true;
            } else {
                sortAsc.value = !sortAsc.value;
            }

            sortKind.value = kind; // 정렬 기준 칼럼 저장
            await getUserList(1);
        };

        // 사용자가 원하는 카테고리와 내용을 검색
        const searchData = async () => {
            if (filterCategory.value === "createdatetime") {
                inputSearchData.value = createDateTimeValue.value.replace(
                    /-/g,
                    "."
                );
            }
            await getUserList(1);
        };

        // 카테고리 변경시 작동
        const selectChange = async () => {
            await getUserList(1); // 리스트 재출력
            inputSearchData.value = null; // 검색 데이터 초기화
        };

        // 사용자 정보 엑셀 다운
        const userInfoExcelDown = async () => {
            // 원본 데이터가 훼손되지 않도록 깊은복사를 해줌
            let cloneUserList = UserList.userList.value.slice();
            cloneUserList = cloneUserList.map((userListData, index) => {
                return objectClone(userListData);
            });

            // 사용자 정보 Key 순서
            const userInfoOrder = [
                "group",
                "name",
                "email",
                "country",
                "jobtitle",
                "company",
                "createdatetime",
                "lastdatetime",
            ];

            // const userInfoOrder = ["group", "name", "email", "country", "jobtitle", "company", "phoneNumber", "createdatetime", "lastdatetime"];

            // 사용자 정보 Name 순서
            const excelColumnOrder = [
                "User Type",
                "Name",
                "Email",
                "Country",
                "Job Title",
                "Company",
                "Create Date",
                "Last Date",
            ];
            // const excelColumnOrder = ["User Type", "Name", "Email", "Country", "Job Title", "Company", "Phone", "Create Date", "Last Date"];

            const userInfoData = []; // 모델 Performance 데이터

            // 사용자정보 Key순서대로 객체 저장
            cloneUserList.map((userListData, index) => {
                userInfoOrder.map((data, index2) => {
                    userInfoData[index] = {
                        ...userInfoData[index],
                        [excelColumnOrder[index2]]: userListData[data],
                    };
                });
            });

            const workBook = utils.book_new(); // 빈 통합 문서를 생성
            const userInfoSheet = utils.json_to_sheet(userInfoData); // UserInfoData 배열을 워크시트로 변환합니다.

            utils.book_append_sheet(workBook, userInfoSheet, "User Info"); // 워크시트를 통합 문서에 User Info 이름으로 추가

            writeFileXLSX(workBook, "UserInformation.xlsx"); // 위에서 만든 문서를 UserInformation.xlsx파일로 변환하여 사용자에게 다운로드 시킴
        };

        return {
            getUserList,
            userListPage,
            sortAsc,
            totalUser: UserList.userList,
            userList: UserList.sliceUserList,
            pageBtnList: UserList.sliceUserPageBtn,
            totalPageBtn: UserList.userListPages,
            pageBlock: UserList.pageBlock,
            showPageBtnLen: UserList.showPageBtnLen,
            sortUserList,
            sortKind,
            userCategories,
            filterCategory,
            inputSearchData,
            searchData,
            createDateTimeValue,
            selectChange,
            userInfoExcelDown,
        };
    },
};
</script>
