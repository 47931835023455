<template>
  <div class="container-xxl model-list">
    <h1 class="title">Registerd Model</h1>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Model Id</th>
            <th scope="col">Model Name</th>
            <th scope="col">OS</th>
            <th scope="col">Platform</th>
            <th scope="col">License Groups</th>
            <th scope="col">Subscription Year</th>
            <th scope="col">Categories</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="model in models" :key="model.modelSequence">
            <td class="model-id">
              {{ model.modelId }}
            </td>
            <td class="model-name">
              {{model.modelName}}
              <!-- <input class="form-control" :value="model.modelName" /> -->
            </td>
            <td class="model-os">
              {{model.os}}
              <!-- <select class="form-select form-select-sm" v-model="model.os">
                <option v-for="{ id, name } in modelConfigs.os" :key="id">
                  {{ name }}
                </option>
              </select> -->
            </td>
            <td class="model-platform">
              {{model.platform}}
              <!-- <select class="form-select form-select-sm" v-model="model.platform">
                <option v-for="{ id, name } in modelConfigs.platform" :key="id">
                  {{ name }}
                </option>
              </select> -->
            </td>
            <td class="text-start license-group">
              <label class="d-block">
                <input class="form-check-input" type="checkbox" value="free" v-model="model.licenseGroups" disabled/>
                Free
              </label>

              <label class="d-block">
                <input class="form-check-input" type="checkbox" value="standard" v-model="model.licenseGroups" disabled/>
                Standard
              </label>

              <label class="d-block">
                <input class="form-check-input" type="checkbox" value="enterprise" v-model="model.licenseGroups" disabled/>
                Enterprise
              </label>
            </td>
            <td class="text-start">
              <label class="d-block">
                <input class="form-check-input" type="checkbox" :value="{ value: 1, text: 'A Year' }"
                  v-model="model.subscriptionYear" disabled/>
                1
              </label>

              <label class="d-block">
                <input class="form-check-input" type="checkbox" :value="{ value: 2, text: '2 Years(Save 10%)' }"
                  v-model="model.subscriptionYear" disabled/>
                2
              </label>

              <label class="d-block">
                <input class="form-check-input" type="checkbox" :value="{ value: 3, text: '3 Years(Save 20%)' }"
                  v-model="model.subscriptionYear" disabled/>
                3
              </label>
            </td>
            <td>
              <div class="text-start categories">
                <label class="d-block" v-for="{ id, name } in modelConfigs.category" :key="id">
                  <input class="form-check-input" type="checkbox" :value="{ id, name }" v-model="model.categories" disabled/>
                  {{ id }} : {{ name }}
                </label>
              </div>
            </td>
            <td>
              <base-button-with-loading buttonClass="size-100 asdf" size="sm" :loading="loading"
                @confirmed="modelUpdateRoute(model.modelSequence)">
                Edit</base-button-with-loading>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>
.model-list {
  margin-bottom: 100px;
}

.model-id,
.model-name,
.license-group {
  word-break: break-all;
  min-width: 150px;
}

.model-os,
.model-platform {
  min-width: 100px;
}

.categories {
  min-width: 250px;
  height: 100px;
  overflow: auto;
  padding-left: 5px;
}

.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.01em;

  color: #ea5b33;
}

th,
td {
  vertical-align: middle;
  text-align: center;
}
</style>

<script>
import Swal from "sweetalert2";

import Model from "@/compositions/Model.js";
import ModelConfig from "@/compositions/ModelConfig.js";
import BaseButtonWithLoading from "@/components/BaseButtonWithLoading.vue";

import { ref } from "vue";

export default {
  components: {
    BaseButtonWithLoading,
  },
  setup() {
    const models = ref([]); // 모델 목록

    const loading = ref(false);

    // 모델 목록 불러오기
    const listAndCopyModels = async () => {
      await Model.listModels();

      models.value = Model.models.value.map(
        ({ licenseGroups, subscriptionYear, ...modelInfos }) => ({
          licenseGroups: [...licenseGroups],
          subscriptionYear: subscriptionYear.map((e) => ({ ...e })),
          ...modelInfos,
        })
      );

    };

    // const sortLicensGroups = (licenseGroups) => {
    //   const orderedGroup = ["free", "standard", "enterprise"];
    //   return orderedGroup.filter((e) => licenseGroups.includes(e));
    // };

    // 모델 수정 페이지 이동 Input : 모델 고유 id
    const modelUpdateRoute = (modelSequence) => {
      document.location.href = `/model-editor/?modelSequence=${modelSequence}`;
    };

    // const updateModelInfo = async (model) => {
    //   try {
    //     loading.value = true;
    //     await Model.putModelInfo({
    //       ...model,
    //       licenseGroups: sortLicensGroups(model.licenseGroups),
    //       subscriptionYear: [...model.subscriptionYear].sort(
    //         (e1, e2) => e1.value - e2.value
    //       ),
    //     });

    //     await listAndCopyModels();
    //   } catch (err) {
    //     Swal.fire({
    //       title: err.message,
    //       icon: "error",
    //       confirmButtonColor: "#E36159",
    //     });
    //   } finally {
    //     loading.value = false;
    //   }
    // };

    listAndCopyModels();

    ModelConfig.listConfigs();

    return {
      models,
      loading,
      modelConfigs: ModelConfig.modelConfigs,
      // updateModelInfo,
      modelUpdateRoute
    };
  },
};
</script>
