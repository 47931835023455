import { ref, computed } from "vue";
import { setApiEndpoint, getDateTime } from "./setFunction";

const api_hostname = setApiEndpoint(); // api주소
const API_ENDPOINT = `${api_hostname}/cart`; // api endpoint
const API_REFRER_CODE = `${api_hostname}/referer-code`;
const cartItems = ref([]);
const discountPercent = ref(100); // 정상가격
const discountMinus = ref(0); // 할인율

// 카트 목록 받아오기 Input : user id
const getCartItems = async (id) => {
    const response = await fetch(`${API_ENDPOINT}/${id}`);

    if (!response.ok) {
        throw new Error("network Error");
    }

    const { Item: { models } = {} } = await response.json();

    if (models == null) {
        return;
    }

    cartItems.value = models;
};

// 카트목록에 항목 추가 Input : user id, 모델 고유번호, 서폿 유무, 라이센스 등급, 사용기간
const addModelToCart = async ({
    id,
    modelSequence,
    support,
    license,
    subYear,
}) => {
    const response = await fetch(`${API_ENDPOINT}/${id}`, {
        method: "PUT",
        body: JSON.stringify({
            modelSequence,
            support,
            license,
            subYear,
        }),
    });

    if (!response.ok) {
        throw new Error("network error");
    }

    // 서버에서 받아온 데이터
    const responseData = await response.json();

    if (!responseData) throw new Error(`This model is already in the cart.`);
    return true;
};

// 카트 목록 항복 삭제  Input : user id, 모델 순서 번호
const removeModelFromCart = async ({ id, indexes }) => {
    const response = await fetch(`${API_ENDPOINT}/${id}`, {
        method: "DELETE",
        body: JSON.stringify({
            indexes,
        }),
    });

    if (!response.ok) {
        throw new Error("network error");
    }
    await getCartItems(id);
};

const discountRates = [1, 0.9, 0.8]; // 할인율

// 가격 표
const priceByLicense = {
    free: 0,
    standard: 10000,
    enterprise: null,
    developer: 1,
};

// 할인전 가격  Input : 라이센스 등급, 사용 기간
const getBeforePrice = ({ license, subYear }) => {
    if (priceByLicense[license]) {
        return license !== "developer"
            ? priceByLicense[license] *
                  discountRates[Math.min(subYear - 1, 2)] *
                  subYear
            : 50;
    }

    return 0;
};

// 할인율 적용 가격
const getPrice = ({ license, subYear }) => {
    if (priceByLicense[license]) {
        return license !== "developer"
            ? priceByLicense[license] *
                  discountRates[Math.min(subYear - 1, 2)] *
                  subYear *
                  (discountPercent.value / 100)
            : 50;
    }

    return 0;
};

// 모델당 가격 측정후 저장 Input : 라이센스 등급, 사용 기간
const prices = computed(() => cartItems.value.map(getPrice));

// 선택된 모델 할인율이 적용된 가격 총합
const totalPrice = computed(() =>
    cartItems.value
        .filter(({ selected, license }) => selected && license !== "enterprise")
        .reduce((acc, cur) => acc + getPrice(cur), 0)
);

// 선택된 모델 할인율이 적용되기전 가격 총합
const beforeTotalPrice = computed(() =>
    cartItems.value
        .filter(({ selected, license }) => selected && license !== "enterprise")
        .reduce((acc, cur) => acc + getBeforePrice(cur), 0)
);

// 할인된 가격 정보
const discountInfo = computed(() => {
    return {
        price: beforeTotalPrice.value,
        discountPercent: discountMinus.value,
        discountPrice: beforeTotalPrice.value - totalPrice.value,
        finalPrice: totalPrice.value,
    };
});

// 사용자가 할인코드 사용할경우 정보 수정 Input : userid, referer 코드, referercode세부 정보
const updateRefererCode = async (
    id,
    refererCode,
    { discountPercent, discountPrice, finalPrice, price }
) => {
    const logDateTime = getDateTime();
    const response = await fetch(`${API_REFRER_CODE}/${id}/${refererCode}`, {
        method: "POST",
        body: JSON.stringify({
            discountPercent,
            discountPrice,
            finalPrice,
            price,
            logDateTime,
        }),
    });

    if (!response.ok) {
        throw new Error("Not Respone");
    }

    const responseResult = await response.json();
    if (responseResult.statusFlag === false) {
        throw new Error("serverError");
    }
};

export default {
    cartItems,
    getCartItems,
    addModelToCart,
    removeModelFromCart,
    totalPrice,
    getPrice,
    prices,
    discountPercent,
    discountInfo,
    discountMinus,
    updateRefererCode,
    getBeforePrice,
    beforeTotalPrice,
};
