<template>
    <section class="about-section">
        <div class="container">
            <div class="cont-heading text-center">
                <h1>About Us</h1>
                <h6>
                    At SOYNET we persevere to solve the industry specific
                    problems related to Artificial Intelligence.
                    <br />
                    Our solution SoyNet is a high-speed reasoning (execution)
                    engine developed based on C/C++ designed to bring speed to
                    your AI Inference and help your reduce time to market in AI.
                    <br />
                    Some of the major problems for implementing AI models are
                    given below.
                </h6>
            </div>

            <div class="row mx-0">
                <div class="col-10 mx-auto col-sm-6 col-lg-3 mb-4">
                    <div class="problems">
                        <figure>
                            <img
                                src="@/assets/images/about-problem1.png"
                                alt="about promblen1"
                            />
                        </figure>
                        <div class="problems-text">
                            <h3>Too Heavy</h3>
                            <p>
                                AI models are resource heavy. Consuming a lot of
                                GPU resources. Because of this Deep learning
                                models are difficult to implement on IoT
                                devices.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-10 mx-auto col-sm-6 col-lg-3 mb-4">
                    <div class="problems">
                        <figure>
                            <img
                                src="@/assets/images/about-problem2.png"
                                alt="about promblen2"
                            />
                        </figure>
                        <div class="problems-text">
                            <h3>Too difficult</h3>
                            <p>
                                Too difficult for the SMBs to integrate as it
                                requires high level of skil sets. Application
                                developers and AI reserchers work is completely
                                different and its difficult to get an all
                                rounder with all skill sets.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-10 mx-auto col-sm-6 col-lg-3 mb-4">
                    <div class="problems">
                        <figure>
                            <img
                                src="@/assets/images/about-problem3.png"
                                alt="about promblen3"
                            />
                        </figure>
                        <div class="problems-text">
                            <h3>Too Slow</h3>
                            <p>
                                Although AI models are passed in the lab with
                                brilliant results very few of them can be
                                actually implemented in the real environment.
                                its very slow and requires expensive Hardware.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-10 mx-auto col-sm-6 col-lg-3 mb-4">
                    <div class="problems">
                        <figure>
                            <img
                                src="@/assets/images/about-problem4.png"
                                alt="about promblen4"
                            />
                        </figure>
                        <div class="problems-text">
                            <h3>AI evolving faster</h3>
                            <p>
                                There will always be a new developments in AI
                                model that the existing Hardware cannot keep up
                                with.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mx-0">
                <div class="col-lg-6 px-0">
                    <img
                        data-aos="fade-up"
                        class="w-100"
                        src="@/assets/images/about1.jpg"
                        alt="about1"
                    />
                </div>
                <div class="col-lg-6 px-0">
                    <div class="content">
                        <h2>Universal Accelerator</h2>
                        <p>
                            SoyNet supports artificial intelligence models
                            developed on any public frameworks such as
                            TensorFlow / PyTorch / Caffe used in training.
                            <br />
                            SoyNet also supports the execution of models on
                            OpenCL and NVIDIA's CUDA
                        </p>
                        <router-link
                            :to="{ path: '/what-is-soynet' }"
                            class="learn-more"
                            >Learn More<i class="fas fa-chevron-right"></i
                        ></router-link>
                    </div>
                </div>
            </div>

            <div class="row mx-0">
                <div class="col-lg-6 px-0">
                    <div class="content">
                        <h2>Speed up AI inferencing</h2>
                        <p>
                            With SoyNet you can increase the inference speed of
                            your Artificial Intelligence Models as it leverages
                            multicore of GPUs. Support of CPU and TPU are coming
                            soon too. With simple 5 step API calls you can
                            integrate with any application.
                        </p>
                        <router-link
                            :to="{ path: '/what-is-soynet' }"
                            class="learn-more"
                            >Learn More<i class="fas fa-chevron-right"></i
                        ></router-link>
                    </div>
                </div>
                <div class="col-lg-6 px-0 order-lg-last order-first">
                    <img
                        data-aos="fade-up"
                        class="w-100"
                        src="@/assets/images/about2.jpg"
                        alt="about2"
                    />
                </div>
            </div>

            <div class="row mx-0">
                <div class="col-lg-6 px-0">
                    <img
                        data-aos="fade-up"
                        class="w-100"
                        src="@/assets/images/about5.jpg"
                        alt="about5"
                    />
                </div>
                <div class="col-lg-6 px-0">
                    <div class="content">
                        <h2>Deploy Anywhere</h2>
                        <p>
                            SoyNet optimized models can be deployed on
                            cloud-server, On-premises server.
                            <br class="sm-none" />
                            Our models can be embedded in edge devices like
                            Jetson Nano that can be trained on PC
                        </p>
                        <router-link
                            :to="{ path: '/what-is-soynet' }"
                            class="learn-more"
                            >Learn More<i class="fas fa-chevron-right"></i
                        ></router-link>
                    </div>
                </div>
            </div>

            <div class="row mx-0">
                <div class="col-lg-6 px-0">
                    <div class="content">
                        <h2>Model Market</h2>
                        <p>
                            A one-stop shop for all your favorite AI models
                            available at Model Market. These models are
                            optimized by SoyNet hence runs faster without
                            incurring additional GPU. You can now shop these
                            models free of cost* from our Market place.
                            <br />
                            <small>
                                (*Depending on environment complexity some
                                models can be charged)
                            </small>
                        </p>
                        <router-link
                            :to="{ path: '/market' }"
                            class="learn-more"
                            >Learn More<i class="fas fa-chevron-right"></i
                        ></router-link>
                    </div>
                </div>
                <div class="col-lg-6 px-0 order-lg-last order-first">
                    <img
                        data-aos="fade-up"
                        class="w-100"
                        src="@/assets/images/about3.jpg"
                        alt="about3"
                    />
                </div>
            </div>

            <div class="row mx-0">
                <div class="col-lg-6 px-0">
                    <img
                        data-aos="fade-up"
                        class="w-100"
                        src="@/assets/images/about4.jpg"
                        alt="about4"
                    />
                </div>
                <div class="col-lg-6 px-0">
                    <div class="content">
                        <h2>SOYNET Support</h2>
                        <p>
                            We provide Paid Online and Email Support for all
                            models purchased from Model Market. On-Site support
                            is available to cater to special requires using
                            SoyNet solution. With premium subscription you can
                            get our experts tosupport your custom models with
                            SoyNet.
                        </p>
                        <router-link
                            :to="{ path: '/contact-us' }"
                            class="learn-more"
                            >Learn More<i class="fas fa-chevron-right"></i
                        ></router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
.cont-heading {
    max-width: 750px;
    margin: 0 auto;
}

.cont-heading h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    color: #ea5b33;
}

.cont-heading h6 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: #000000;
}

.about-section {
    padding-top: 150px;
    margin-bottom: 100px;
}

.about-section .content {
    padding: 80px 18px 0;
}

.about-section .row {
    padding-top: 120px;
}

.about-section .content h2 {
    font-weight: bold;
    font-size: 30px;
    line-height: 50px;
    color: #000000;
    margin: 5px 0;
}

.about-section .content p {
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: #000000;
}

.about-section .content p small {
    font-size: 12px;
}

.about-section .content .learn-more {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #e36159;
}

.about-section .content .learn-more:hover i {
    margin-left: 10px;
}

.about-section .content .learn-more i {
    margin-left: 5px;
    transition: all 0.4s ease-in-out;
}

.problems {
    box-shadow: 0 0 1rem 0 rgb(0 0 0 / 40%);
    height: 100%;
    padding-top: 10px;
}

.problems-text {
    padding: 0 16px 15px;
}

.problems h3 {
    font-family: fieldwork-hum;
    font-size: 21px;
}

.problems figure img {
    width: 100%;
}

@media screen and (max-width: 996px) {
    .about-section .row {
        padding-top: 50px;
    }
}

@media screen and (max-width: 576px) {
    .about-section {
        margin-bottom: 60px;
    }

    .cont-heading {
        text-align: left !important;
    }

    .cont-heading h1 {
        font-size: 28px;
    }

    .about-section .content {
        padding: 16px 0 0;
    }

    .about-section .content h2 {
        font-size: 18px;
    }

    .about-section .content p {
        font-size: 14px;
    }

    .cont-heading h6 {
        font-size: 14px;
    }
}
</style>

<script>
export default {};
</script>
