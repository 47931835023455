<template>
  <div class="thank-you-page container text-center">
    <h1 class="mb-4">Thank You!</h1>
    <p>We have received your query please wait while we get back to you.</p>
    <hr />
    <router-link class="theme-btn-2 d-inline-block" to="/"
      >Redirect To HomePage</router-link
    >
  </div>
</template>
<style scoped>
.thank-you-page {
  margin: 200px auto;
}

h1 {
  font-weight: bold;
  font-size: 60px;
  line-height: 72px;
  color: #ea5b33;
}

p {
  font-size: 20px;
}

hr {
  background-color: #ea5b33;
  height: 3px;
  width: 30px;
  margin: 30px auto;
  opacity: 1;
}

@media screen and (max-width: 576px) {
  .thank-you-page {
    margin: 150px auto;
  }

  h1 {
    font-size: 48px;
    line-height: 60px;
  }

  p {
    font-size: 16px;
  }
}
</style>

<script>
export default {};
</script>
