<template>
    <base-modal ref="imageModal" modalClass="modal-fullscreen">
        <template #title>
            Image Editor
        </template>
        <div ref="imageEditorContainer"></div>
        <template #footer>
            <button class="theme-btn-2" @click="hide">
                Cancel
            </button>
            <button class="theme-btn" @click="onOk">
                Apply Image
            </button>
        </template>
    </base-modal>
</template>

<style>
    .tui-image-editor-header {
        display: none;
    }

    .tui-image-editor-container .tui-image-editor-main {
        top: 0;
    }
</style>

<script>
    import BaseModal from "@/components/BaseModal.vue";

    import ImageEditor from 'tui-image-editor';
    import "tui-image-editor/dist/tui-image-editor.css";

    import { ref, onMounted } from "vue";

    export default {
        components: {
            BaseModal
        },
        emits: ['ok'],
        setup(props, context) {
            let imageEditor = null;
            const imageEditorContainer = ref(null);
            const imageModal = ref(null);

            onMounted(() => {
                imageEditor = new ImageEditor(imageEditorContainer.value, {
                    includeUI: {
                        uiSize: {
                            width: "100%",
                            height: "100%"
                        },
                        menuBarPosition: "bottom"
                    }
                });
            });

            const loadImageFromFile = async (blob) => {

                const sizeValue = await imageEditor.loadImageFromFile(blob, "image");

                imageEditor.ui.activeMenuEvent();
                imageEditor.ui.resizeEditor({ imageSize: sizeValue });
            };

            const show = () => imageModal.value.show();
            const hide = () => imageModal.value.hide();

            const onOk = async () => {
                const imageFile = await new Promise((res) => {
                    const image = document.createElement('img');
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    image.onload = () => {
                        canvas.width = image.width;
                        canvas.height = image.height;

                        ctx.drawImage(image, 0, 0, image.width, image.height);

                        canvas.toBlob(res);
                    };

                    image.src = imageEditor.toDataURL();
                });

                context.emit('ok', imageFile);

                hide();
            }


            return {
                imageEditorContainer,
                imageModal,
                show,
                hide,
                onOk,
                loadImageFromFile
            };
        }
    }
</script>