<template>
    <section class="details-section">
        <div class="container">
            <div class="back-button">
                <router-link
                    :to="{ path: '/market' }"
                    class="theme-btn-2 d-inline-block"
                    ><i class="fas fa-chevron-left"></i> Back To Model
                    Market</router-link
                >
            </div>
            <div class="row mx-0">
                <div class="col-12 col-md-6">
                    <div class="thumbnail-uploader">
                        <h1 v-if="thumbnailKey == null">
                            Upload thumbnail image here
                        </h1>
                        <img v-else :src="thumbnailKey" />
                        <label>
                            <i class="far fa-plus-square"></i>
                            <input
                                type="file"
                                accept="image/*"
                                @change="previewThumbnail"
                            />
                        </label>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="row h-100 align-items-start details">
                        <div>
                            <label> Categories : </label>
                            <category-tag-list
                                v-model:items="categories"
                                class="mb-2"
                                :editable="true"
                            />
                            <div class="w-50">
                                <label>
                                    Add Category
                                    <select @input="onChange">
                                        <option :value="null" selected disabled>
                                            Select category
                                        </option>
                                        <option
                                            v-for="(
                                                { id, name }, index
                                            ) in modelConfigs.category"
                                            :key="index"
                                            :value="index"
                                            :disabled="
                                                !!categories.find(
                                                    ({ id: catId }) =>
                                                        id === catId
                                                )
                                            "
                                        >
                                            {{ id }} : {{ name }}
                                        </option>
                                    </select>
                                </label>
                            </div>
                        </div>

                        <div class="col-6">
                            <label>
                                Model ID :
                                <input
                                    placeholder="will be generated automatically"
                                    v-model="modelId"
                                    readonly
                                />
                            </label>
                        </div>

                        <div class="col-6">
                            <label>
                                Model Name :
                                <input
                                    placeholder="Enter Model Name"
                                    v-model="modelName"
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                OS :
                                <select v-model="os">
                                    <option :value="null" selected>
                                        Select Supported OS
                                    </option>
                                    <option
                                        v-for="(
                                            { name }, index
                                        ) in modelConfigs.os"
                                        :key="index"
                                        :value="name"
                                    >
                                        {{ name }}
                                    </option>
                                </select>
                            </label>
                        </div>

                        <div>
                            <label>
                                Platform :
                                <select v-model="platform">
                                    <option :value="null" selected>
                                        Select Supported platform
                                    </option>
                                    <option
                                        v-for="(
                                            { name }, index
                                        ) in modelConfigs.platform"
                                        :key="index"
                                    >
                                        {{ name }}
                                    </option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                Soynet Version :
                                <select v-model="soynetversion">
                                    <option :value="null" selected>
                                        Select Soynet Version
                                    </option>
                                    <option
                                        v-for="(
                                            { name }, index
                                        ) in modelConfigs.soynetversion"
                                        :key="index"
                                    >
                                        {{ name }}
                                    </option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label class="mb-0"> License Group : </label>
                            <label class="d-inline-block me-4">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value="free"
                                    v-model="licenseGroups"
                                />
                                Free
                            </label>

                            <label class="d-inline-block me-4">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value="standard"
                                    v-model="licenseGroups"
                                />
                                Standard
                            </label>

                            <label class="d-inline-block me-4">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value="enterprise"
                                    v-model="licenseGroups"
                                />
                                Enterprise
                            </label>
                            <label class="d-inline-block me-4">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value="developer"
                                    v-model="licenseGroups"
                                />
                                Develope test
                            </label>
                        </div>
                        <div>
                            <label class="mb-0"> Subscription Year : </label>
                            <label class="d-inline-block me-4">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    :value="{ value: 1, text: 'A Year' }"
                                    v-model="subscriptionYear"
                                />
                                1
                            </label>

                            <label class="d-inline-block me-4">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    :value="{
                                        value: 2,
                                        text: '2 Years(Save 10%)',
                                    }"
                                    v-model="subscriptionYear"
                                />
                                2
                            </label>

                            <label class="d-inline-block me-4">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    :value="{
                                        value: 3,
                                        text: '3 Years(Save 20%)',
                                    }"
                                    v-model="subscriptionYear"
                                />
                                3
                            </label>
                        </div>
                        <div>
                            <label class="d-inline-block me-4">
                                Admin Only :
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    :value="{ value: true }"
                                    v-model="disabled"
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                Link To Download :
                                <input
                                    placeholder="Enter link To Download"
                                    v-model="linkToDownload"
                                />
                            </label>
                        </div>

                        <hr class="d-block d-md-none" />
                    </div>
                </div>
                <div class="model-performance-container">
                    <div class="model-performance-form">
                        <h4>Model Perfomance</h4>
                        <div class="model-performance-column">
                            <div class="model-performance-column-data">
                                <h6>Model Name</h6>
                                <input v-model="modelName" readonly />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>Model Code</h6>
                                <input v-model="modelPerformance.modelcode" />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>Variable Model Way</h6>
                                <input
                                    v-model="modelPerformance.variablemodelway"
                                />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>Model Brief</h6>
                                <input v-model="modelPerformance.modelbrief" />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>Thesis Date</h6>
                                <input
                                    type="date"
                                    v-model="modelPerformance.thesisdate"
                                />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>Code Date</h6>
                                <input
                                    type="date"
                                    v-model="modelPerformance.codedate"
                                />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>Model Classification</h6>
                                <input
                                    v-model="
                                        modelPerformance.modelclassification
                                    "
                                />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>Model Specific</h6>
                                <input
                                    v-model="modelPerformance.modelspecific"
                                />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>Input Data Type</h6>
                                <input
                                    v-model="modelPerformance.inputdatatype"
                                />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>Input Data Shape</h6>
                                <input
                                    v-model="modelPerformance.inputdatashape"
                                />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>Output Data Dtype</h6>
                                <input
                                    v-model="modelPerformance.outputdatadtype"
                                />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>Output Data Shape</h6>
                                <input
                                    v-model="modelPerformance.outputdatashape"
                                />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>Preproc</h6>
                                <input v-model="modelPerformance.preproc" />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>Postproc</h6>
                                <input v-model="modelPerformance.postproc" />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>New Layer</h6>
                                <input v-model="modelPerformance.newlayer" />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>Thesis Link</h6>
                                <input v-model="modelPerformance.thesislink" />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>Github Link</h6>
                                <input v-model="modelPerformance.githublink" />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>Framework</h6>
                                <input v-model="modelPerformance.framework" />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>Pretrained</h6>
                                <input v-model="modelPerformance.pretrained" />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>Porting Start</h6>
                                <input
                                    type="date"
                                    v-model="modelPerformance.portingstart"
                                />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>Porting End</h6>
                                <input
                                    type="date"
                                    v-model="modelPerformance.portingend"
                                />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>Porting Operator</h6>
                                <input
                                    v-model="modelPerformance.portingoperator"
                                />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>Train-code Name</h6>
                                <input
                                    v-model="modelPerformance.traincodename"
                                />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>Inference-code Name</h6>
                                <input
                                    v-model="modelPerformance.inferencecodename"
                                />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>Weight-down-code Name</h6>
                                <input
                                    v-model="
                                        modelPerformance.weightdowncodename
                                    "
                                />
                            </div>
                            <div class="model-performance-column-data">
                                <h6>Work Code</h6>
                                <input v-model="modelPerformance.workcode" />
                            </div>
                        </div>
                    </div>
                    <div class="model-benchmark-form">
                        <h4>
                            Model Benchmark
                            <select name="" id="" v-model="benchCount">
                                <option
                                    v-for="(value, index) in 5"
                                    :value="index + 1"
                                >
                                    {{ index + 1 }}
                                </option>
                            </select>
                        </h4>
                        <table class="model-benchmark-table">
                            <tr>
                                <th>Benchmark Device</th>
                                <td :colspan="benchCount">
                                    <input
                                        v-model="
                                            modelPerformance.benchmarkdevice
                                        "
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>Data</th>
                                <td :colspan="benchCount">
                                    <input v-model="modelPerformance.data" />
                                </td>
                            </tr>
                            <tr>
                                <th>Batch</th>
                                <td v-for="(value, index) in benchCount">
                                    <input
                                        @keyup="
                                            (event) =>
                                                inputBatchData(event, index)
                                        "
                                        name="batchsize"
                                        :value="
                                            modelPerformance.batch[index]
                                                ?.batchsize
                                        "
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>Precision</th>
                                <td v-for="(value, index) in benchCount">
                                    <input
                                        @keyup="
                                            (event) =>
                                                inputBatchData(event, index)
                                        "
                                        name="precision"
                                        :value="
                                            modelPerformance.batch[index]
                                                ?.precision
                                        "
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>Data Size,Length</th>
                                <td v-for="(value, index) in benchCount">
                                    <input
                                        @keyup="
                                            (event) =>
                                                inputBatchData(event, index)
                                        "
                                        name="datasizeorlength"
                                        :value="
                                            modelPerformance.batch[index]
                                                ?.datasizeorlength
                                        "
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>SoyNet Fps</th>
                                <td v-for="(value, index) in benchCount">
                                    <input
                                        @keyup="
                                            (event) =>
                                                inputBatchData(event, index)
                                        "
                                        name="soynetfps"
                                        :value="
                                            modelPerformance.batch[index]
                                                ?.soynetfps
                                        "
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>SoyNet GPU Memory</th>
                                <td v-for="(value, index) in benchCount">
                                    <input
                                        @keyup="
                                            (event) =>
                                                inputBatchData(event, index)
                                        "
                                        name="soynetgpumemory"
                                        :value="
                                            modelPerformance.batch[index]
                                                ?.soynetgpumemory
                                        "
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>Pytorch Fps</th>
                                <td v-for="(value, index) in benchCount">
                                    <input
                                        @keyup="
                                            (event) =>
                                                inputBatchData(event, index)
                                        "
                                        name="pytorchfps"
                                        :value="
                                            modelPerformance.batch[index]
                                                ?.pytorchfps
                                        "
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>Pytorch GPU Memory</th>
                                <td v-for="(value, index) in benchCount">
                                    <input
                                        @keyup="
                                            (event) =>
                                                inputBatchData(event, index)
                                        "
                                        name="pytorchgpumemory"
                                        :value="
                                            modelPerformance.batch[index]
                                                ?.pytorchgpumemory
                                        "
                                    />
                                </td>
                            </tr>
                            <!-- <tr>
                <th>Model Hash</th>
                <td :colspan="benchCount"> <input v-model="modelPerformance.modelhash" /></td>
              </tr> -->
                            <tr>
                                <th>Benchmark brief</th>
                                <td :colspan="benchCount">
                                    <input
                                        v-model="
                                            modelPerformance.benchmarkbrief
                                        "
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="model-consistency-form">
                        <h4>
                            Model Consistency
                            <select v-model="consistencyCount">
                                <option
                                    v-for="(value, index) in 20"
                                    :value="index + 1"
                                >
                                    {{ index + 1 }}
                                </option>
                            </select>
                        </h4>
                        <table class="model-consistency-table">
                            <tr>
                                <th>#</th>
                                <th>{{ modelPerformance.framework }}</th>
                                <th>SoyNet</th>
                                <th>Diff</th>
                            </tr>
                            <tr v-for="(value, index) in consistencyCount">
                                <th>{{ index }}</th>
                                <td>
                                    <input
                                        type="text"
                                        @keyup="
                                            (event) =>
                                                inputConsistencyData(
                                                    event,
                                                    index
                                                )
                                        "
                                        :value="
                                            modelPerformance.consistency[index]
                                                ?.framework
                                        "
                                        name="framework"
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        @keyup="
                                            (event) =>
                                                inputConsistencyData(
                                                    event,
                                                    index
                                                )
                                        "
                                        :value="
                                            modelPerformance.consistency[index]
                                                ?.soynet
                                        "
                                        name="soynet"
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        @keyup="
                                            (event) =>
                                                inputConsistencyData(
                                                    event,
                                                    index
                                                )
                                        "
                                        :value="
                                            modelPerformance.consistency[index]
                                                ?.diff
                                        "
                                        name="diff"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>Consistency brief</th>
                                <td :colspan="3">
                                    <input
                                        v-model="
                                            modelPerformance.consistencybrief
                                        "
                                        class="model-consistency-brief"
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <editor
                    class="editor"
                    ref="editor"
                    :uploadImage="uploadImage"
                ></editor>

                <div class="text-end">
                    <button
                        class="theme-btn-2 d-inline-block text-center"
                        @click="addModel"
                    >
                        {{
                            modelSequence !== undefined
                                ? "Confirm"
                                : "Add Model"
                        }}
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
.back-button {
    margin: 50px 0;
}

.details-section {
    margin: 0 0 50px;
}

.editor {
    padding: 0 12px;
    margin: 25px 0;
}

.thumbnail-uploader {
    background: #ffffff;
    border: 1.5px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;
    height: 100%;
    padding: 0 24px 0 0;
    position: relative;
}

.thumbnail-uploader input {
    display: none;
}

.thumbnail-uploader img,
.thumbnail-uploader h1,
.thumbnail-uploader label {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.thumbnail-uploader label,
.thumbnail-uploader h1 {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.thumbnail-uploader label {
    font-size: 60px;
    color: rgba(0, 0, 0, 0);
}

.thumbnail-uploader h1 {
    font-weight: bold;
    font-size: 40px;
    color: #9b9b9b;
}

.thumbnail-uploader label:hover {
    color: #bbbbbb;
    background: rgba(255, 255, 255, 0.8);
}

.thumbnail-uploader img {
    object-fit: cover;
    object-position: center;
}

.details input:not([type]),
.details select,
.details textarea {
    background: #ffffff;
    border: 1.5px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;

    font-size: 15px;
    line-height: 21px;
    letter-spacing: -0.01em;

    padding: 12px;

    margin: 0 0 12px;
}

.details input:focus,
.details select,
.details textarea:focus {
    outline: none;
}

.details label {
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 5px;

    color: #000000;

    display: block;
}

.details textarea {
    resize: none;
}

.support-link {
    background: #f1f1f1;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    padding: 14px 15px;
    border-radius: 2px;
}

.model-performance-container {
    padding: 15px;
    margin-top: 30px;
}

.model-performance-container .model-performance-form {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 70px;
}

.model-performance-column {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
}

.model-performance-column-data {
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin-right: 15px;
    /* border-right: 1px #ddd solid; */
    align-items: center;
    width: 30%;
}

.model-performance-column-data h6 {
    width: 40%;
    margin-right: 10px;
}

.model-performance-column-data input {
    width: 60%;
    text-align: right;
    font-size: 18px;
    border: none;
    border-bottom: 1px solid #ddd;
    outline: none;
}

.model-benchmark-form,
.model-consistency-form {
    margin-bottom: 50px;
}

.model-benchmark-table,
.model-consistency-table {
    margin-top: 15px;
    width: 100%;
    border: 1px solid #ddd;
}

.model-benchmark-table tr,
.model-consistency-table tr {
    border-bottom: 1px solid #ddd;
}

.model-benchmark-table th,
.model-consistency-table th {
    width: 20%;
    background-color: #f8f5f5;
}

.model-benchmark-table th,
.model-benchmark-table td,
.model-consistency-table th,
.model-consistency-table td {
    border-right: 1px solid #ddd;
}

.model-benchmark-table input {
    width: 100%;
    padding: 15px 20px;
    border: none;
    text-align: right;
}

.model-consistency-table input {
    width: 100%;
    border: none;
    text-align: right;
}

.model-consistency-brief {
    padding: 15px 20px;
}

@media screen and (max-width: 767px) {
    .thumbnail-uploader {
        height: 0;
        margin: 0 0 20px;
        padding: 0 0 120%;
    }

    .thumbnail-uploader h1 {
        font-size: 25px;
    }

    .back-button {
        margin: 20px 0;
    }
}

@media screen and (max-width: 576px) {
    .thumbnail-uploader {
        padding: 0 0 76%;
    }

    .details label {
        font-size: 14px;
    }

    .theme-btn,
    .theme-btn-2 {
        padding: 17px 13px;
        font-size: 14px;
    }
}
</style>

<script>
import Swal from "sweetalert2";
import { setApiEndpoint } from "@/compositions/setFunction";

const api_hostname = setApiEndpoint();
const API_ENDPOINT = `${api_hostname}/model`;

import model from "@/compositions/Model.js";
import configs from "@/compositions/ModelConfig.js";

import Editor from "@/components/Editor.vue";
import CategoryTagList from "@/components/CategoryTagList.vue";

export default {
    inject: ["getAccessToken"],
    components: {
        Editor,
        CategoryTagList,
    },
    data() {
        const { modelSequence } = this.$route.query || undefined;
        return {
            modelSequence,
            modelId: null,
            categories: [],
            modelName: null,
            os: null,
            licenseGroups: [],
            subscriptionYear: [],
            disabled: true,
            platform: null,
            linkToDownload: null,
            thumbnailKey: null,
            markdown: null,
            thumbnailFile: null,
            soynetversion: null,
            modelPerformance: {
                modelcode: null,
                variablemodelway: null,
                modelbrief: null,
                thesisdate: null,
                codedate: null,
                modelclassification: null,
                modelspecific: null,
                inputdatatype: null,
                inputdatashape: null,
                outputdatadtype: null,
                outputdatashape: null,
                preproc: null,
                postproc: null,
                newlayer: null,
                thesislink: null,
                githublink: null,
                framework: null,
                pretrained: null,
                portingstart: null,
                portingend: null,
                portingoperator: null,
                traincodename: null,
                inferencecodename: null,
                weightdowncodename: null,
                workcode: null,
                batch: [],
                consistency: [],
                consistencybrief: null,
                benchmarkdevice: null,
                benchmarkbrief: null,
                // modelhash: null,
                data: null,
            },
            benchCount: 1,
            consistencyCount: 1,
            modelConfigs: configs.modelConfigs,
        };
    },

    async mounted() {
        try {
            // model config 데이터 받아오기
            await configs.listConfigs();

            // model sequence가 있을경우 Model 정보 데이터 받아오기
            if (this.modelSequence != null) {
                await model.getModelPost(this.modelSequence);

                const {
                    modelId,
                    categories,
                    modelName,
                    os,
                    licenseGroups,
                    subscriptionYear,
                    platform,
                    linkToDownload,
                    thumbnailKey,
                    markdown,
                    disableFlag,
                    soynetversion,
                } = model.modelPost.value;

                if (model.modelPerformance.value) {
                    this.modelPerformance = await model.modelPerformance.value;
                    this.benchCount =
                        model.modelPerformance.value.batch?.length || 1;
                    this.consistencyCount =
                        model.modelPerformance.value.consistency?.length ||
                        this.consistencyCount;
                }

                this.modelId = modelId;
                this.categories = categories;
                this.modelName = modelName;
                this.os = os;
                this.platform = platform;
                this.licenseGroups = licenseGroups;
                this.subscriptionYear = subscriptionYear;
                this.linkToDownload = linkToDownload;
                this.thumbnailKey = thumbnailKey;
                this.markdown = markdown;
                this.disabled = disableFlag;
                this.soynetversion = soynetversion;

                const imageRequest = await fetch(this.thumbnailKey, {
                    headers: {
                        "Cache-Control": "no-cache",
                    },
                });

                if (!imageRequest.ok) {
                    throw new Error("network error");
                }

                this.thumbnailFile = await imageRequest.blob();
            }
        } finally {
            this.$refs.editor.setMarkdown(this.markdown);
        }
    },
    methods: {
        inputBatchData(event, index) {
            // benchmark 데이터 배열로 입력 input : 배열 번호, 값
            const inputName = event.target.name; // 입력한 항목 이름
            const inputValue = event.target.value; // 입력한 항목 값
            this.modelPerformance.batch[index] = {
                ...this.modelPerformance.batch[index],
                [inputName]: inputValue,
            };
        },
        inputConsistencyData(event, index) {
            // consistency 데이터 배열에 저장 input : 배열 번호, 값
            const inputName = event.target.name; // 입력한 항목 이름
            const inputValue = event.target.value; // 입력한 항목 값
            this.modelPerformance.consistency[index] = {
                ...this.modelPerformance.consistency[index],
                [inputName]: inputValue,
            };
        },
        onChange({ target: { value } }) {
            // 카테고리 변경시 데이터 변경
            this.categories = [
                ...new Set([
                    ...this.categories,
                    this.modelConfigs.category[value],
                ]),
            ];
        },
        async uploadImage(imageFile) {
            // 이미지 업로드 함수
            const fileName = `image-${+new Date()}.png`;

            const urlRequest = await fetch(API_ENDPOINT, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: await this.getAccessToken(),
                },
                body: JSON.stringify({
                    prefix: "images",
                    fileName,
                    ContentType: "image/png",
                }),
            });

            if (!urlRequest.ok) {
                throw new Error("network error");
            }
            const { uploadURL, Key } = JSON.parse(await urlRequest.json());

            const uploadRequest = await fetch(uploadURL, {
                method: "PUT",
                body: imageFile,
            });

            if (!uploadRequest.ok) {
                throw new Error("network error");
            }

            return Key;
        },
        async uploadThumbnail() {
            // 썸네일 사진 업로드
            const { type: ContentType } = this.thumbnailFile;
            const fileName = `thumbnail-${+new Date()}.${
                ContentType.split("/")[1]
            }`;

            const urlRequest = await fetch(API_ENDPOINT, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: await this.getAccessToken(),
                },
                body: JSON.stringify({
                    prefix: this.modelSequence,
                    fileName,
                    ContentType,
                }),
            });

            if (!urlRequest.ok) {
                throw new Error("network error");
            }

            const { uploadURL, Key } = JSON.parse(await urlRequest.json());

            const uploadRequest = await fetch(uploadURL, {
                method: "PUT",
                body: this.thumbnailFile,
            });

            if (!uploadRequest.ok) {
                throw new Error("network error");
            }

            return Key;
        },
        async uploadModelDescription() {
            const content = this.$refs.editor.getContent();

            const urlRequest = await fetch(API_ENDPOINT, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: await this.getAccessToken(),
                },
                body: JSON.stringify({
                    prefix: this.modelSequence,
                    fileName: `description-${+new Date()}.md`,
                    ContentType: "text/markdown; charset=UTF-8",
                }),
            });

            if (!urlRequest.ok) {
                throw new Error("network error");
            }

            const { uploadURL, Key } = JSON.parse(await urlRequest.json());

            const uploadRequest = await fetch(uploadURL, {
                method: "PUT",
                body: content,
            });

            if (!uploadRequest.ok) {
                throw new Error("network error");
            }

            return Key;
        },
        async createModel() {
            // 모델 생성
            const request = await fetch(API_ENDPOINT, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: await this.getAccessToken(),
                },
            });

            if (!request.ok) {
                throw new Error("network error");
            }

            return await request.json();
        },
        async updateModel({ thumbnailKey, descriptionKey }) {
            // 모델 데이터 변경
            const summary = this.$refs.editor.summary();
            const modelId = `${this.modelName.trim().replace(/\s/g, "_")}_${
                this.os
            }_${this.platform}`.toLowerCase();

            await model.putModelInfo({
                modelSequence: this.modelSequence,
                modelId,
                categories: this.categories,
                modelName: this.modelName,
                os: this.os,
                licenseGroups: this.sortedLicenseGroups,
                subscriptionYear: [...this.subscriptionYear].sort(
                    (e1, e2) => e1.value - e2.value
                ),
                platform: this.platform,
                linkToDownload: this.linkToDownload,
                summary,
                thumbnailKey,
                descriptionKey,
                disableFlag: this.disabled,
                soynetversion: this.soynetversion,
                modelPerformance: this.modelPerformance,
            });
        },
        validateInputs() {
            if (this.categories == null || this.categories.length < 1) {
                throw new Error("category is empty");
            }

            if (this.modelName == null || this.modelName.length < 1) {
                throw new Error("modelName is empty");
            }

            if (this.platform == null || this.platform.length < 1) {
                throw new Error("platform is empty");
            }

            if (this.os == null || this.os.length < 1) {
                throw new Error("os is empty");
            }

            if (this.thumbnailFile == null) {
                throw new Error("thumbnail is missing");
            }
        },
        async createModelPerformance() {
            // 모델 성능 db생성
            const request = await fetch(
                `${API_ENDPOINT}/${this.modelSequence}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: await this.getAccessToken(),
                    },
                }
            );

            if (!request.ok) {
                throw new Error("network error");
            }

            return await request.json();
        },
        async addModel() {
            try {
                this.validateInputs();

                await Swal.fire({
                    title:
                        this.modelSequence !== undefined
                            ? "Confirm this model to market?"
                            : "Add this model to market?",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    confirmButtonColor: "#E36159",
                    showLoaderOnConfirm: true,
                    allowOutsideClick: () => !Swal.isLoading(),
                    allowEscapeKey: () => !Swal.isLoading(),
                    preConfirm: async (isConfirmed) => {
                        try {
                            if (!isConfirmed) return;
                            if (this.modelSequence == null) {
                                this.modelSequence = await this.createModel();
                            } else {
                                this.createModelPerformance();
                            }
                            const thumbnailKey = await this.uploadThumbnail();
                            const descriptionKey =
                                await this.uploadModelDescription();
                            await this.updateModel({
                                thumbnailKey,
                                descriptionKey,
                            });

                            this.$router.push("/market");

                            return true;
                        } catch (err) {
                            Swal.showValidationMessage(
                                `Request failed: ${err}`
                            );
                        }
                    },
                });
            } catch (err) {
                Swal.fire({
                    title: err.message,
                    icon: "error",
                    confirmButtonColor: "#E36159",
                });
            }
        },
        previewThumbnail({
            target: {
                files: [file],
            },
        }) {
            if (file) {
                const reader = new FileReader();

                reader.onload = () => {
                    this.thumbnailKey = reader.result;
                };

                this.thumbnailFile = file;

                reader.readAsDataURL(file);
            }
        },
    },
    computed: {
        sortedLicenseGroups() {
            const orderedGroup = [
                "free",
                "standard",
                "enterprise",
                "developer",
            ];
            return orderedGroup.filter((e) => this.licenseGroups.includes(e));
        },
    },
};
</script>
