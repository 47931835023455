<template>
  <div class="thank-you-page container text-center">
    <h1 class="mb-4">Thank You For Your Subscription.</h1>
    <p>
      Now you are a part of SOYNET and we will send you update
      <br />on how you can make use of our services to the best.
    </p>
    <hr />
    <div class="row justify-content-center">
      <div class="col-12 col-md-3">
        <router-link class="theme-btn d-inline-block" to="/market"
          >Check Model Market</router-link
        >
      </div>
      <div class="col-12 col-md-3">
        <router-link class="theme-btn-2 d-inline-block" to="/case-study"
          >Read Our Case Studies</router-link
        >
      </div>
      <div class="col-12 col-md-3">
        <router-link class="theme-btn d-inline-block" to="/blogs"
          >Learn more from Blogs</router-link
        >
      </div>
    </div>
  </div>
</template>
<style scoped>
.thank-you-page {
  margin: 200px auto;
}

.theme-btn,
.theme-btn-2 {
  width: 100%;
  max-width: 250px;
  margin-bottom: 5px;
}

h1 {
  font-weight: bold;
  font-size: 48px;
  line-height: 60px;
  color: #ea5b33;
}

p {
  font-size: 20px;
}

hr {
  background-color: #ea5b33;
  height: 3px;
  width: 30px;
  margin: 40px auto;
  opacity: 1;
}

@media screen and (max-width: 576px) {
  .thank-you-page {
    margin: 100px auto;
  }

  h1 {
    font-size: 36px;
    line-height: 40px;
  }

  p {
    font-size: 16px;
  }
}
</style>

<script>
export default {};
</script>
