<template>
    <section>
        <swiper navigation :pagination="{clickable: true}" :centeredSlides="true" :breakpoints="breakpoints">
            <swiper-slide v-for="{ src, title } in galleries" class="model-market-slide">
                <h2>
                    {{ title }}
                </h2>
                <figure class="mb-0">
                    <img class="w-100 rounded" :src="src" alt="">
                </figure>
            </swiper-slide>
        </swiper>
        <div class="container mt-5">
            <h1>
                Model Market is an online market place for the AI models optimized by SoyNet.
                <br>
                Purchase your favorite models by sending us a query <a href="/contact-us">Contact Us</a>
                <br>
                Include Model Name, Environment details and will surely reach out to you.
            </h1>
        </div>
    </section>
</template>

<style scoped>
    figure {
        position: relative;
        padding-top: 45%;
    }

    figure img {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    h2 {
        text-align: center;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.01em;
    }

    h1 {
        font-size: 22px;
        line-height: 36px;
        font-weight: 500;
        text-align: center;
        letter-spacing: -0.01em;
        color: #000000;
        margin: 0 auto;
    }

    @media screen and (max-width:787px) {
        h1 {
            font-size: 18px;
            line-height: 30px;
            margin: 0;
        }
    }
    
    @media screen and (max-width:576px) {
        figure {
            padding-top: 70%;
        }

        h2 {
            text-align: center;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: -0.01em;
        }
    }
</style>

<style>
    .model-market-slide.swiper-slide {
        opacity: 0.6;
        transform: scale(0.9);
    }

    .model-market-slide.swiper-slide-active {
        opacity: 1;
        transform: scale(1);
    }
</style>

<script>
    import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';
    import image1 from '@/assets/images/model_market_card1.png';
    import image2 from '@/assets/images/model_market_card2.png';
    import image3 from '@/assets/images/model_market_card3.png';
    import image4 from '@/assets/images/model_market_card4.png';
    import image5 from '@/assets/images/model_market_card5.png';

    export default {
        components: {
            Swiper,
            SwiperSlide
        },
        data() {
            return {
                galleries: [{
                        src: image1,
                        title: 'YOLOv4'
                    },
                    {
                        src: image2,
                        title: 'Mask R-CNN'
                    },
                    {
                        src: image3,
                        title: 'Pose Estimation'
                    },
                    {
                        src: image4,
                        title: 'Instance Segmentation'
                    },
                    {
                        src: image5,
                        title: 'Video Segmentation'
                    },
                ],
                breakpoints: {
                    577: {
                        slidesPerView: 1.1,
                        spaceBetween: 0
                    },
                    768: {
                        slidesPerView: 1.3,
                        spaceBetween: 0
                    },
                    992: {
                        slidesPerView: 1.6,
                        spaceBetween: 0
                    }
                }
            }
        }
    };
</script>
