<template>
    <section class="main-section">
        <div class="main-video-title">
            <video class="bg-video" src="@/assets/images/about-us_bgmovie_640x360.mp4" muted autoplay loop></video>
            <div class="content container">
                <div class="row mx-0">
                    <div class="main-title col-md-6 d-none d-md-block px-0">
                        <h1>
                            {{ title }}
                        </h1>
                        <p>
                            {{ description }}
                        </p>
                        <div class="d-flex">
                            <router-link class="theme-btn" :to="{ path : '/what-is-soynet'}">
                                Who Are We?
                            </router-link>
                            <router-link class="theme-btn-2 ms-3" :to="{ path : '/contact-us'}">
                                Contact Us
                            </router-link>
                        </div>
                    </div>
                    <div class="main-video col-md-6 col-12 d-flex align-items-center px-0">
                        <video class="w-100" muted controls @canplay="muteVideo" autoplay src="@/assets/images/SoyNet_intro_english_caption.mp4" type="video/mp4"></video>
                    </div>
                </div>
            </div>
        </div>

        <div class="container d-block d-md-none">
            <div class="main-title">
                <h1>
                    {{ title }}
                </h1>
                <p>
                    {{ description }}
                </p>
                <div class="d-flex">
                    <router-link class="theme-btn" :to="{ path : '/what-is-soynet'}">
                        Who Are We?
                    </router-link>
                    <router-link class="theme-btn-2 ms-3" :to="{ path : '/contact-us'}">
                        Contact Us
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
    .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .main-video-title {
        position: relative;
        width: 100%;
    }

    .bg-video {
        width: 100%;
        max-height: 660px;
        object-fit: cover;
        filter: brightness(20%);
        z-index: -1;
    }

    .main-section .main-title h1 {
        font-weight: 700;
        font-size: 40px;
        line-height: 50px;
        color: #FFFFFF;
        margin: 0;
    }

    .main-section .main-title p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 30px;
        color: #FFFFFF;
        margin: 25px 0;
    }

    @media screen and (max-width: 996px) {
        .main-section .main-title h1 {
            font-size: 30px;
        }

        .main-section .main-title p {
            font-size: 14px;
        }

        .theme-btn,
        .theme-btn-2 {
            padding: 15.5px 32px;
        }
    }

    @media screen and (max-width: 767px) {
        .bg-video {
            max-height: 100%;
            min-height: 365px;
        }

        .content {
            padding: 0;
        }

        .main-section .main-title h1 {
            padding-top: 30px;
        }

        .main-section .main-title h1,
        .main-section .main-title p {
            color: #000000;
        }

        .d-flex {
            justify-content: center;
        }
    }
</style>

<script>
    export default {
        setup() {
            const title = "SOYNET helps you bring your AI dream to reality faster. "; // 제목
            const description = "SoyNet is a software based inference acceleration solution developed to provide faster and affordable inference of AI models." // 본문 내용

            const muteVideo = (e) => e.target.muted = true; // 동영상 음소거

            return {
                title,
                description,
                muteVideo
            }
        }
    }
</script>
