<template>
  <div class="tag-list">
    <span
      class="pill"
      :class="{ editable: editable }"
      @click="onClick(index)"
      v-for="({ id, name }, index) in items"
      :key="id"
    >
      {{ name }}
      <i class="fas fa-times" v-if="editable"></i>
    </span>
  </div>
</template>
<style scoped>
.tag-list {
  word-break: break-all;
}

.pill {
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: #e36159;
  background: rgba(227, 97, 89, 0.1);
  border-radius: 5px;
  padding: 7px;
  height: fit-content;
  margin: 2px;
  display: inline-block;
}

.pill.editable {
  cursor: pointer;
}
</style>

<script>
export default {
  props: ["items", "editable"],
  methods: {
    onClick(itemIndex) {
      if (!this.editable) return;
      this.$emit(
        "update:items",
        this.items.filter((e, index) => index !== itemIndex)
      );
    },
  },
};
</script>
