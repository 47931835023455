import { ref } from "vue";

import User from "./User";
import { setApiEndpoint } from "./setFunction";

const api_hostname = setApiEndpoint(); // api 주소
const API_ENDPOINT = `${api_hostname}/model-configs`; // api endpoint

const modelConfigs = ref({}); // 모델 설정 데이터

// 모델 설정 데이터 받아오기
const listConfigs = async () => {
  const response = await fetch(API_ENDPOINT, { method: "GET" });

  if (!response.ok) {
    throw new Error("network error");
  }
  const items = await response.json(); // 서버에서 받은 데이터 

  // 모델 설정 데이터를 합친후 저장
  modelConfigs.value = items.reduce(
    (acc, { configType, items }) => ({
      ...acc,
      [configType]: Object.entries(items).map(([id, options]) => ({
        id,
        ...options,
      })),
    }),
    {}
  );
};

// 모델 설정 분류 추가 (현재는 미사용) Input : 분류 이름, 분류 Id,  이름 
const addModelConfig = async ({ configType, itemId, name }) => {
  const response = await fetch(`${API_ENDPOINT}/${configType}`, {
    method: "POST",
    headers: {
      Authorization: await User.getAccessToken(),
    },
    body: JSON.stringify({
      itemId,
      name,
    }),
  });

  if (!response.ok) {
    const message = await response.json()
    if (message === "The conditional request failed") {
      throw new Error("Duplicated Item ID.");
    }
    throw new Error("Network Error");
  }

  listConfigs();
};

// 모델 설정 항목 수정 Input : 분류 이름, 분류 Id,  이름
const editModelConfig = async ({ configType, itemId, name }) => {
  const response = await fetch(`${API_ENDPOINT}/${configType}/${itemId}`, {
    method: "PUT",
    headers: {
      Authorization: await User.getAccessToken(),
    },
    body: JSON.stringify({
      name,
    }),
  });

  if (!response.ok) {
    throw new Error("Network Error");
  }

  listConfigs();
};

// 모델 설정 항목 삭제 Input : 분류 이름, 분류 Id
const deleteModelConfig = async ({ configType, itemId }) => {
  const response = await fetch(`${API_ENDPOINT}/${configType}/${itemId}`, {
    method: "DELETE",
    headers: {
      Authorization: await User.getAccessToken(),
    },
  });

  if (!response.ok) {
    throw new Error("Network Error");
  }

  listConfigs();
};

export default {
  modelConfigs,
  listConfigs,
  addModelConfig,
  editModelConfig,
  deleteModelConfig,
};
