import { ref } from "vue";

import User from "./User";
import { setApiEndpoint } from "./setFunction";

const api_hostname = setApiEndpoint(); // api주소
const API_ENDPOINT = `${api_hostname}/my-page`; // api endpoint

const remainingCount = ref(null);
const myPageModels = ref([]); // 사용자가 가지고있는 모델 데이터
const filterModels = ref([]); // statusFlag가 0이 아닌 모델 데이터
const signedModelList = ref([]); // 결제가 필요한 모델 데이터

// 모델 목록 Input : userId
const listMyPageModels = async (id) => {
    const response = await fetch(`${API_ENDPOINT}/${id}`, {
        headers: {
            Authorization: await User.getAccessToken(),
        },
    });

    if (!response.ok) {
        throw new Error("network Error");
    }

    let { Item: { models } = {} } = await response.json(); // 서버에서 받아온 데이터
    myPageModels.value = models || []; // 서버에서 받아온 Model 데이터
    // remainingCount.value = count || 0; // 서버에서 받아온 사용자 잔여 다운로드 횟수

    // statusFlag가 0이 아닌 모델 데이터 판별
    filterModels.value = myPageModels.value.filter(
        (value) => value.statusFlag !== 0
    );

    signedModelList.value = myPageModels.value.filter((model, index) => {
        model.indexNum = index;
        return model.contractStatus === "signed";
    });
};

// 모델 중복 체크 Input : userId, 모델 고유번호, 사용등급, 같은모델 유무, 모델이름
const sameModelCheck = async ({
    id,
    modelSequence,
    license,
    sameModel,
    modelName,
}) => {
    const response = await fetch(`${API_ENDPOINT}/${id}`, {
        method: "PUT",
        headers: {
            Authorization: await User.getAccessToken(),
        },
        body: JSON.stringify({ id, modelSequence, license, sameModel }),
    });

    const responseBody = await response.json(); // 라이센스 데이터

    if (responseBody === false) {
        throw new Error(
            `This model is already in use. \n ModelName : ${modelName}`
        );
    }
};

// 모델 추가 Input : 모델 정보, 추가한 시간, 할인가격, 사용한 할인 코드
const addModelToList = async ({
    id,
    modelSequence,
    modelName,
    modelId,
    license,
    pricingAmount,
    subYear,
    envelopeId,
    contractStatus,
    statusDateTime,
    dateTime,
    codeDiscount,
    refererCode,
    authority,
    soynetversion,
}) => {
    const response = await fetch(`${API_ENDPOINT}/${id}`, {
        method: "PUT",
        headers: {
            Authorization: await User.getAccessToken(),
        },
        body: JSON.stringify({
            modelSequence,
            modelName,
            modelId,
            license,
            pricingAmount,
            contractStatus,
            subYear,
            envelopeId,
            statusDateTime,
            dateTime,
            codeDiscount,
            refererCode,
            authority,
            soynetversion,
        }),
    });

    if (!response.ok) {
        throw new Error("network Error");
    }

    await listMyPageModels(id);
};

// 키 목록 Input : 순서 번호
const getLicenseKeys = ({ index }) =>
    myPageModels.value[index]?.licenseKeys || [];

// /** 무료 키 발행 잔여 횟수 확인, Input: 사용자이메일, 라이센스등급*/
// const remainingCountCheck = async (id, license) => {
//     const response = await fetch(`${API_ENDPOINT}/${id}`, {
//         method: "POST",
//         headers: {
//             Authorization: await User.getAccessToken(),
//         },
//         body: JSON.stringify({
//             issuance_mode: license === "free" ? "trial" : "permanent",
//         }),
//     });

//     return await response.json();
// };

/** key 파일 다운로드 Input: 사용자이메일, 모델 순서번호, gpuid, 모델 이름, 라이센스 키*/
const generateLicenseKey = async (
    id,
    index,
    gpuId,
    license,
    license_key,
    modelName
) => {
    // 키생성 완료 여부
    try {
        const response = await fetch(
            `${API_ENDPOINT}/${id}/${index}/licensekey`,
            {
                method: "PUT",
                headers: {
                    Authorization: await User.getAccessToken(),
                },
                body: JSON.stringify({
                    gpuId,
                    issuance_mode: license === "free" ? "trial" : "permanent",
                    modelName,
                    license,
                }),
            }
        );

        if (!response.ok) {
            throw new Error("network Error");
        }

        const createAtag = document.createElement("a");
        const keyFile = new Blob([license_key], { type: "text/plain" });
        createAtag.href = URL.createObjectURL(keyFile);
        createAtag.download =
            license === "free" ? "license_trial.key" : `license_${gpuId}.key`;
        document.body.appendChild(createAtag);
        createAtag.click();
        document.body.removeChild(createAtag);
    } catch (err) {
        throw new Error(err);
    }

    await listMyPageModels(id);
};

// 기간 수정 Input : userid, 모델순서, 모델 가격, 모델 사용 기간, 계약서 Id, 모델 활성화/비활성화 정보
const updateSubscription = async ({
    id,
    index,
    pricingAmount,
    subYear,
    envelopeId,
    statusDateTime,
}) => {
    const response = await fetch(
        `${API_ENDPOINT}/${id}/${index}/subscription`,
        {
            method: "PUT",
            headers: {
                Authorization: await User.getAccessToken(),
            },
            body: JSON.stringify({
                pricingAmount,
                subYear,
                envelopeId,
                statusDateTime,
            }),
        }
    );

    if (!response.ok) {
        throw new Error("network Error");
    }

    await listMyPageModels(id);
};

// 모델 삭제 Input : userid,모델 순서, 모델 고유번호, 모델이름, 모델 id, 모델 등급
const deleteModel = async (
    id,
    index,
    { modelSequence, modelName, modelId, license },
    dateTime
) =>
    (
        await fetch(`${API_ENDPOINT}/${id}/${index}`, {
            headers: {
                Authorization: await User.getAccessToken(),
            },
            body: JSON.stringify({
                modelSequence,
                modelName,
                modelId,
                license,
                dateTime,
            }),
            method: "POST",
        })
    ).ok;

export default {
    myPageModels,
    listMyPageModels,
    generateLicenseKey,
    getLicenseKeys,
    addModelToList,
    updateSubscription,
    deleteModel,
    filterModels,
    sameModelCheck,
    // remainingCount,
    signedModelList,
    // remainingCountCheck,
};
