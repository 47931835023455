<template>
    <div>
        <teleport to="#destination">
            <base-modal ref="modal" modalClass="modal-lg modal-dialog-centered">
                <template #title> Payment Models </template>
                <div class="table-responsive">
                    <template v-if="paidModels.length > 0">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item table-container">
                                <h2 class="accordion-header">
                                    <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseOne"
                                        aria-expanded="true"
                                        aria-controls="flush-collapseOne"
                                    >
                                        User Models
                                    </button>
                                </h2>
                                <div
                                    id="flush-collapseOne"
                                    class="accordion-collapse collapse show"
                                    data-bs-parent="#accordionFlushExample"
                                >
                                    <div class="accordion-body">
                                        <table
                                            class="table"
                                            cellspacing="0"
                                            cellpadding="0"
                                        >
                                            <thead>
                                                <tr>
                                                    <th scope="col">
                                                        Model Name
                                                    </th>
                                                    <th scope="col">
                                                        Model Id
                                                    </th>
                                                    <th scope="col">
                                                        Contract Status
                                                    </th>
                                                    <th scope="col">
                                                        Amount ($)
                                                    </th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody
                                                v-for="(
                                                    model, index
                                                ) in paidModels"
                                                :key="model.modelSequence"
                                            >
                                                <tr
                                                    v-if="
                                                        model.license !==
                                                            'free' &&
                                                        model.license !==
                                                            'enterprise'
                                                    "
                                                >
                                                    <td>
                                                        {{ model.modelName }}
                                                    </td>
                                                    <td>
                                                        {{ model.modelId }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            model.contractStatus
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            model.pricingAmount.toLocaleString()
                                                        }}
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            v-model="
                                                                model.selected
                                                            "
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseTwo"
                                    >
                                        Payment Kind
                                    </button>
                                </h2>
                                <div
                                    id="flush-collapseTwo"
                                    class="accordion-collapse collapse"
                                    data-bs-parent="#accordionFlushExample"
                                >
                                    <div class="accordion-body">
                                        <table class="payment-kind-table">
                                            <tr>
                                                <td colspan="1">
                                                    <label
                                                        ><input
                                                            type="radio"
                                                            name="paymentKind"
                                                            class="payment-radio-input"
                                                            value="paypal"
                                                            v-model="
                                                                checkPayment
                                                            " /><img
                                                            src="@/assets/images/PayPalLogo2.svg"
                                                            alt="PayPal"
                                                    /></label>
                                                </td>
                                                <td colspan="1">
                                                    <label
                                                        ><input
                                                            type="radio"
                                                            name="paymentKind"
                                                            class="payment-radio-inpu t"
                                                            value="toss"
                                                            v-model="
                                                                checkPayment
                                                            " /><img
                                                            src="@/assets/images/tossLogo.jpg"
                                                            alt="Toss"
                                                    /></label>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="model-paid-area">
                            <div class="model-paid-total-price">
                                <h4 v-if="checkPayment === 'toss'">
                                    Total Price: ₩{{
                                        krwTotalPrice.toLocaleString()
                                    }}
                                </h4>
                                <h4 v-else-if="checkPayment === 'paypal'">
                                    Total Price: ${{
                                        totalPrice.toLocaleString()
                                    }}
                                </h4>
                            </div>
                        </div>
                    </template>
                    <div
                        class="no-item text-center"
                        v-if="paidModels.length < 1"
                    >
                        <h1>No Item</h1>
                    </div>
                </div>
                <template #footer>
                    <button
                        class="theme-btn"
                        id="payment-method"
                        @click="tossPaymentFunc"
                        :disabled="
                            paidModels.length < 1 || checkedModel.length < 1
                        "
                        v-if="checkPayment === 'toss'"
                    >
                        Payment
                    </button>
                    <form
                        :action="formAddress"
                        method="post"
                        v-else-if="checkPayment === 'paypal'"
                    >
                        <input
                            type="hidden"
                            name="business"
                            :value="businessValue"
                        />
                        <input type="hidden" name="cmd" value="_xclick" />
                        <input
                            type="hidden"
                            name="item_name"
                            :value="checkedModelId"
                        />
                        <input
                            type="hidden"
                            name="item_number"
                            :value="checkModelLicense"
                        />
                        <input type="hidden" name="currency_code" value="USD" />
                        <input
                            type="hidden"
                            name="amount"
                            :value="totalPrice"
                        />
                        <input type="hidden" name="charset" value="UTF-8" />
                        <input type="hidden" name="custom" :value="userId" />
                        <input
                            type="hidden"
                            NAME="return"
                            :value="domainAddress"
                        />
                        <input
                            type="hidden"
                            name="cancel_return"
                            :value="domainAddress"
                        />
                        <input
                            type="hidden"
                            name="notify_url"
                            :value="apiEndpoint"
                        />
                        <button
                            class="theme-btn"
                            name="submit"
                            :disabled="
                                paidModels.length < 1 || checkedModel.length < 1
                            "
                        >
                            Payment
                        </button>
                    </form>
                    <button
                        class="theme-btn"
                        :disabled="
                            paidModels.length < 1 || checkedModel.length < 1
                        "
                        v-else
                    >
                        Payment
                    </button>
                    <button class="theme-btn cancel-btn" @click="hide">
                        Cancel
                    </button>
                </template>
            </base-modal>
        </teleport>
    </div>
</template>

<style scoped>
td,
th {
    word-break: break-word;
}

.payment-kind-table td {
    width: 50%;
    text-align: center;
    word-break: none;
}

label {
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 5px;

    color: #000000;

    display: block;
}

.theme-btn {
    padding: 13.5px;
}

.table-container {
    width: 100%;
    max-height: 400px;
    z-index: 2;
    overflow: auto;
    margin-bottom: 30px;
}

table {
    width: 100%;
    border-collapse: unset;
    max-height: 400px;
}

.model-paid-area {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin: 30px 0;
}

.model-paid-total-price {
    margin-left: auto;
}

.model-paid-total-price h4 {
    font-weight: bold;
    margin: 0;
}

.payment-radio-input {
    margin-right: 10px;
}

.cancel-btn {
    background-color: #6e7881 !important;
    border: #6e7881 !important;
}

.cancel-btn:hover {
    color: #ddd;
}

.no-item {
    padding-bottom: 100px;
}
</style>

<script>
import BaseModal from "@/components/BaseModal.vue";
import { computed, ref, inject } from "vue";
import {
    setApiEndpoint,
    serverClassification,
} from "../../../compositions/setFunction";
import { loadTossPayments } from "@tosspayments/payment-sdk";
import { v4 as uuidv4 } from "uuid";

import BaseButtonWithLoading from "@/components/BaseButtonWithLoading.vue";
import Swal from "sweetalert2";

export default {
    components: { BaseModal, BaseButtonWithLoading },
    setup() {
        const userId = inject("userInfo").value.email;
        const userName = inject("userInfo").value.name;
        const userCountry = inject("userInfo").value.country;
        const modal = ref(null); // 결제 해야하는 모델 목록 모달 스위치
        const apiAddress = setApiEndpoint(); // api 주소
        const apiEndpoint = `${apiAddress}/paypal-listener`; // api 상세 주소
        const domainAddress = document.location.origin; // 결제후 돌아올 주소
        const formAddress = ref(null); // PayPal form action 주소
        const businessValue = ref(null); // PayPal 사용 계정
        const exChangeRateKrw = ref(null); // 매일 12시 갱신되는 환율 계산 ex) $1 = 1300₩
        const checkPayment = ref("paypal"); // 사용자가 결제 방식 선택
        const paidModels = ref([]); // 결제 해야하는 모델 목록
        const serverCheck = serverClassification(); // 도메인에 따라 테스트/실 서버 구분

        // 환율 계산하는 함수 (현재 실시간 환율은 유료 서비스라 무료중에 괜찮은 api 이용중)
        const getExchangeRate = async () => {
            const responseKrw = await fetch(
                "https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/usd/krw.min.json"
            );

            const exchangeRate = await responseKrw.json();
            exChangeRateKrw.value = exchangeRate.krw;
        };

        // 대한민국 국적일경우 환율 함수 실행
        if (userCountry === "Korea, Republic of") {
            checkPayment.value = "toss";
        }

        getExchangeRate();

        // PayPal 서버 분할
        if (serverCheck) {
            formAddress.value = process.env.VUE_APP_PAYPAL_URL;
            businessValue.value = process.env.VUE_APP_PAYPAL_EMAIL;
        } else {
            formAddress.value = process.env.VUE_APP_PAYPAL_TEST_URL;
            businessValue.value = process.env.VUE_APP_PAYPAL_TEST_EMAIL;
        }

        // 결제 해야하는 모델 목록 모달 화면 띄움, 모델 정보 저장 Input : 결제 해야할 모델 데이터
        const show = (models) => {
            paidModels.value = models;
            modal.value.show();
        };

        // 사용자가 체크한 모델 확인
        const checkedModel = computed(() =>
            paidModels.value.filter(({ selected }) => selected === true)
        );

        // 사용자가 체크한 모델에 가격 총합
        const totalPrice = computed(() =>
            paidModels.value
                .filter(({ selected }) => selected === true)
                .reduce((acc, cur) => acc + cur.pricingAmount, 0)
        );

        // 체크한 모델에 환율에 계산된 총합
        const krwTotalPrice = computed(() =>
            Math.ceil(exChangeRateKrw.value * totalPrice.value)
        );

        // 사용자가 체크한 모델 이름들 ex) 'yolorV5_ubuntu_x7,maskCnn_ubuntu_x7...'
        const checkedModelId = computed(() =>
            checkedModel.value.map((value, index) => value.modelId).join(",")
        );

        // 사용자가 체크한 모델 라이센스 ex) 'standard,standard,developer...' 127자 까지
        const checkModelLicense = computed(() =>
            checkedModel.value.map((value, index) => value.license).join(",")
        );

        const hide = () => modal.value.hide(); // 결제 해야하는 모델 목록 모달 화면 숨김

        /** toss payments 결제 */
        const tossPaymentFunc = async () => {
            const clientKey = serverCheck
                ? process.env.VUE_APP_TOSS_KEY
                : process.env.VUE_APP_TOSS_TEST_KEY;

            const successUrl = `${document.location.origin}/thank-you/payment/?modelId=${checkedModelId.value}&modelLicense=${checkModelLicense.value}`; // 결제 성공시 이동할 페이지 설정
            const uuid = uuidv4(); // 랜덤 주문번호

            const tossPayments = await loadTossPayments(clientKey);
            tossPayments
                .requestPayment("카드", {
                    amount: krwTotalPrice.value,
                    orderId: uuid,
                    orderName: checkedModelId.value,
                    customerName: userName,
                    successUrl: successUrl,
                    failUrl: `${document.location.origin}/fail`,
                })
                .catch(function (error) {
                    Swal.fire({
                        title: error.message,
                        icon: "error",
                        confirmButtonColor: "#E36159",
                    });
                    // console.log(error.message);
                    if (error.code === "USER_CANCEL") {
                        // 결제 고객이 결제창을 닫았을 때 에러 처리
                    } else if (error.code === "INVALID_CARD_COMPANY") {
                        // 유효하지 않은 카드 코드에 대한 에러 처리
                    }
                });
        };

        return {
            modal,
            show,
            hide,
            paidModels,
            totalPrice,
            checkedModel,
            checkedModelId,
            userId,
            checkModelLicense,
            apiEndpoint,
            domainAddress,
            formAddress,
            businessValue,
            tossPaymentFunc,
            krwTotalPrice,
            checkPayment,
        };
    },
};
</script>
