import { ref, readonly } from "vue";
import { Auth } from "aws-amplify";

const userInfo = ref(null); // 사용자 정보
const error = ref(null); // 오류 내용

// 사용자 토큰 발행
const getAccessToken = async () =>
    (await Auth.currentSession())?.idToken.jwtToken;

// 사용자 로그인 체크
const checkLoginState = async () => {
    try {
        const {
            attributes: {
                email,
                name,
                sub,
                "custom:company": company,
                "custom:country": country,
                // "custom:phoneNumber": phoneNumber,
                "custom:jobtitle": jobtitle,
            },
            signInUserSession: {
                accessToken: {
                    payload: { "cognito:groups": [authority] = [] },
                },
            },
        } = await Auth.currentAuthenticatedUser();

        // 로그인 확인되면 정보 저장
        userInfo.value = {
            email,
            name,
            authority,
            company,
            country,
            // phoneNumber,
            jobtitle,
        };

        const userScan = await Auth.currentAuthenticatedUser(); // 사용자 정보 받아오기
        const userAttr = userScan.attributes; // 사용자 상세 정보

        // dynamo db에 보낼 데이터
        const paramData = {
            userId: userAttr.sub,
            email: userAttr.email,
            name: userAttr.name,
            country: userAttr["custom:country"],
            company: userAttr["custom:company"],
            // "phoneNumber": userAttr["custom:phoneNumber"],
            jobtitle: userAttr["custom:jobtitle"],
            group: authority,
        };

        updateUserInfoTrigger(paramData); // 사용자 정보 로그 저장
        // loginUserTrigger(sub);
        return true;
    } catch (e) {
        userInfo.value = null;
        error.value = e;

        return false;
    }
};

// 사용자 권한 확인 Input : 사용자 권한 정보
const checkAuthority = async (allowedRoles = []) =>
    allowedRoles.includes(
        (await checkLoginState()) && userInfo.value.authority
    );

// 로그아웃 기능
const signOut = async () => {
    try {
        await Auth.signOut();

        userInfo.value = null;
    } catch (e) {
        userInfo.value = null;
        error.value = e;
    }
};

// 로그인 기능 Input : user이메일, 비밀번호
const signIn = async (userEmail, password) => {
    try {
        const {
            attributes: { email, name, sub },
            signInUserSession: {
                accessToken: {
                    payload: { "cognito:groups": [authority] = [] },
                },
            },
        } = await Auth.signIn(userEmail, password);

        userInfo.value = {
            email,
            name,
            authority,
        };

        const userScan = await Auth.currentAuthenticatedUser();
        const userAttr = userScan.attributes;

        // dynamo db에 보낼 데이터
        const paramData = {
            userId: sub,
            email: userAttr.email,
            name: userAttr.name,
            country: userAttr["custom:country"],
            company: userAttr["custom:company"],
            // "phoneNumber": userAttr["custom:phoneNumber"],
            group: authority,
        };
        updateUserInfoTrigger(paramData);
    } catch (e) {
        userInfo.value = null;
        error.value = e;

        throw e;
    }
};

// 비밀번호 재설정 Input : user이메일, 전 비밀번호, 현 비밀번호
const setNewPassword = async ({ email, oldPassword, newPassword }) => {
    const user = await Auth.signIn(email, oldPassword);
    await Auth.changePassword(user, oldPassword, newPassword);
    await Auth.signOut();
};

// 비밀번호 변경 Input : 전 비밀번호, 현 비밀번호
const changePassword = async ({ oldPassword, newPassword }) => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
};

// 사용자 정보 수정시 로그 저장 Input : 사용자이름, 나라, 회사, 전화번호
const updateUserInfo = async ({
    name,
    country,
    company,
    // phoneNumber,
    jobtitle,
}) => {
    const user = await Auth.currentAuthenticatedUser();
    const userEmail = user.attributes.email;
    const {
        signInUserSession: {
            accessToken: {
                payload: { "cognito:groups": [authority] = [] },
            },
        },
    } = user;
    const userId = user.attributes.sub;

    await Auth.updateUserAttributes(user, {
        name,
        "custom:country": country || "",
        "custom:company": company || "",
        // "custom:phoneNumber": phoneNumber || "",
        "custom:jobtitle": jobtitle || "",
    });

    // dynamo db에 보낼 데이터
    const paramData = {
        userId,
        email: userEmail,
        name,
        country,
        company,
        // phoneNumber,
        authority,
        jobtitle,
    };

    updateUserInfoTrigger(paramData);
    await checkLoginState();
};

// 사용자 정보 수정시 lambda함수 실행 Input : user정보
const updateUserInfoTrigger = async (paramData) => {
    const API_ENDPOINT = `https://32b5odmd65.execute-api.ap-northeast-2.amazonaws.com/user`;
    const bodyData = JSON.stringify({
        id: paramData.userId,
        email: paramData.email,
        name: paramData.name,
        country: paramData.country || "",
        company: paramData.company || "",
        // "phoneNumber": paramData.phoneNumber || "",
        jobtitle: paramData.jobtitle || "",
        group: paramData.group || "user",
    });

    // lambda 함수 호출
    try {
        await fetch(API_ENDPOINT, {
            method: "POST",
            headers: {
                Authorization: await getAccessToken(),
                "Content-Type": "application/json",
            },
            body: bodyData,
        });
    } catch (err) {
        console.log(err);
    }
};

// 사용자 로그인시 Last date 수정 | input : idParam
const loginUserTrigger = async (idParam) => {
    const API_ENDPOINT = `https://32b5odmd65.execute-api.ap-northeast-2.amazonaws.com/login`;
    const bodyData = JSON.stringify({
        id: idParam,
    });

    // lambda 함수 호출
    try {
        await fetch(API_ENDPOINT, {
            method: "POST",
            headers: {
                Authorization: await getAccessToken(),
                "Content-Type": "application/json",
            },
            body: bodyData,
        });
    } catch (err) {
        console.log(err);
    }
};

// 로그인 사용자 삭제
async function deleteUser() {
    try {
        const result = await Auth.deleteUser();
    } catch (error) {
        console.log("Error deleting user", error);
    }
}

export default {
    userInfo: readonly(userInfo),
    error: readonly(error),
    checkLoginState,
    checkAuthority,
    setNewPassword,
    signOut,
    signIn,
    getAccessToken,
    updateUserInfo,
    changePassword,
};
