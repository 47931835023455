<template>
  <main>
    <router-view name="header" />
    <router-view />
    <router-view name="footer" />
    <cookie-notification/>
  </main>

</template>

<script>
  import user from '@/compositions/User.js';
  import AOS from 'aos';
  import CookieNotification from "@/components/CookieNotification.vue";
  
  export default {
    name: 'App',
    components: {
      CookieNotification,
    },
    data(){

    },
    setup() {
      AOS.init({
        duration: 1800
      });

      user.checkLoginState();
    },
    provide: {
      ...user,
    }
  }
</script>
