// import User from "./User";
import { ref } from "vue";
import { setApiEndpoint, getDateTime } from "../compositions/setFunction.js";

const api_hostname = setApiEndpoint(); // api 주소
const API_ENDPOINT = `${api_hostname}/referer-code`; // api endpoint

const refererCodeList = ref({}); // 할인코드 목록
const sliceRefererCode = ref({}); // 할인코드 목록에서 보여줄 항목개수만큼 자른 목록
const refererCodePage = ref({}); // 할인코드 목록에 페이지버튼 개수
const sliceRefererCodePageBtn = ref({}); // 페이지 버튼 최대개수만큼 담긴다
const showItemLen = 10; // 한 페이지에 보여줄 할인 코드 개수
const showPageBtnLen = 5; // 페이지버튼 개수 제한

// Referer Code목록 가져오기 Input : 페이지 번호
const scanRefererCode = async (pageNum) => {
  const response = await fetch(API_ENDPOINT);
  if (!response.ok) {
    throw new Error("network error");
  }

  refererCodeList.value = await response.json(); // 서버에서 받은 데이터

  // page당 보여주는 유저 할인코드
  sliceRefererCode.value = refererCodeList.value.slice(
    pageNum * showItemLen - showItemLen,
    pageNum * showItemLen
  );


  // 페이지 버튼 개수 구하기
  const pageBtn =
    refererCodeList.value.length % showItemLen === 0
      ? refererCodeList.value.length / showItemLen
      : refererCodeList.value.length / showItemLen + 1;

  // 페이지 버튼
  refererCodePage.value = refererCodeList.value
    .slice(0, pageBtn)
    .map((res, cnt) => cnt + 1);

  // 페이지 버튼이 최대 정해진수 만큼 나오게 하기 위해 정해진수마다 block이 1씩 추가.
  const block = Math.ceil(pageNum / showPageBtnLen);

  // 페이지 버튼 블락에 수에 따라 시작점을 찾아 그 순서부터 개수만큼 나옴.
  sliceRefererCodePageBtn.value = refererCodePage.value.slice(
    block * showPageBtnLen - showPageBtnLen,
    block * showPageBtnLen
  );

};

// 생성한code db에 생성 Input : 할인 코드, 할인율
const addRefererCode = async ({ refererCode, discountPercent }) => {
  const getDateTimeData = getDateTime(); // 현재 시간 받아오기
  const response = await fetch(API_ENDPOINT, {
    body: JSON.stringify({
      code: refererCode,
      discountPercent,
      logDateTime: getDateTimeData,
    }),
    method: "PUT",
  });

  if (!response.ok) {
    throw new Error("network error");
  }
};

// Referer Code percent 수정 Input : userId, 할인 코드, 할인율
const editRefererCode = async ({ id, refererCode, discountPercent }) => {
  const getDateTimeData = getDateTime();
  const response = await fetch(`${API_ENDPOINT}/${id}`, {
    body: JSON.stringify({
      code: refererCode,
      discountPercent,
      logDateTime: getDateTimeData,
    }),
    method: "POST",
  });

  if (!response.ok) {
    throw new Error("Server Error");
  }
};

// Referer Code 삭제 Input : userId, 할인 코드
const deleteRefererCode = async ({ id, refererCode }) => {
  const response = await fetch(`${API_ENDPOINT}/${id}/${refererCode}`, {
    method: "DELETE",
  });

  if (!response.ok) {
    throw new Error("failed Delete");
  }
};

export default {
  refererCodeList,
  sliceRefererCode,
  scanRefererCode,
  addRefererCode,
  editRefererCode,
  deleteRefererCode,
  refererCodePage,
  sliceRefererCodePageBtn,
  showPageBtnLen
};
