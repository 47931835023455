<template>
    <article>
        <about-main-section />
        <about-us-section />
        <about-customer-section />
        <about-partner-section />
        <about-faq-section />
        <about-bottom-section />
        <about-event-modal-vue />
    </article>
</template>

<script>
import AboutMainSection from './AboutMainSection.vue';
import AboutUsSection from './AboutUsSection.vue';
import AboutSoynetSection from './AboutSoynetSection.vue';
import AboutCustomerSection from './AboutCustomerSection.vue';
import AboutFaqSection from './AboutFaqSection.vue';
import AboutPartnerSection from './AboutPartnerSection.vue';
import AboutBottomSection from './AboutBottomSection.vue';
import AboutEventModalVue from '@/views/layouts/AboutEventModal3_popup.vue';



export default {
    components: {
        AboutMainSection,
        AboutUsSection,
        AboutSoynetSection,
        AboutCustomerSection,
        AboutFaqSection,
        AboutPartnerSection,
        AboutBottomSection,
        AboutEventModalVue
    },
};
</script>
