<template>
    <div class="container-xl model-config">
        <h1 class="title">Model Config</h1>
        <div class="text-end">
            <button class="theme-btn" @click="addModelConfig(configList)">
                Add Model Config
            </button>
        </div>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Config Type</th>
                        <th scope="col">ID</th>
                        <th scope="col">Name</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="{ configType, id, name } in configList"
                        :key="`${configType}-${id}`"
                    >
                        <td>
                            {{ configType }}
                        </td>
                        <td>
                            {{ id }}
                        </td>
                        <td>
                            {{ name }}
                        </td>
                        <td class="edit-config">
                            <button
                                class="btn btn-link"
                                @click="
                                    editModelConfig({ configType, id, name })
                                "
                            >
                                <i class="fas fa-cog"></i>
                            </button>
                            <button
                                class="btn btn-link"
                                @click="
                                    deleteModelConfig({
                                        configType,
                                        itemId: id,
                                    })
                                "
                            >
                                <i class="fas fa-trash-alt"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <model-config-input-modal ref="inputModal" />
        <model-config-edit-modal ref="editModal" />
    </div>
</template>
<style scoped>
.model-config {
    margin-bottom: 100px;
}
.theme-btn {
    padding: 9px;
    font-size: 14px;
}

th,
td {
    vertical-align: middle;
    text-align: center;
}

tfoot td {
    border: 0;
}

.title {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.01em;

    color: #ea5b33;
}

.edit-config button {
    color: #6f6f6f;
    cursor: pointer;
}

.edit-config button:hover {
    color: #000000;
}
</style>
<script>
import ModelConfig from "@/compositions/ModelConfig.js";

import ModelConfigInputModal from "./ModelConfigInputModal.vue";
import ModelConfigEditModal from "./ModelConfigEditModal.vue";

import { computed, ref } from "vue";

import Swal from "sweetalert2";

export default {
    components: {
        ModelConfigInputModal,
        ModelConfigEditModal,
    },
    setup() {
        ModelConfig.listConfigs();

        const inputModal = ref(null); // 모델 설정 추가시 모달 열림
        const editModal = ref(null); // 모델 설정 수정시 모달 열림

        // 모델 설정 추가
        const addModelConfig = (configList) =>
            inputModal.value.show(configList);

        const editModelConfig = (configs) => editModal.value.show(configs); // 모델 설정 수정 Input : 모델설정 데이터

        // 모델 설정 삭제 Input : 모델 설정 데이터
        const deleteModelConfig = async (configs) => {
            try {
                const { isConfirmed } = await Swal.fire({
                    title: "Delete Model Config?",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    confirmButtonColor: "#E36159",
                });

                if (!isConfirmed) return;
                await ModelConfig.deleteModelConfig(configs);
            } catch (err) {
                Swal.fire({
                    title: err.message,
                    icon: "error",
                    confirmButtonColor: "#E36159",
                });
            }
        };

        // 모델 설정 데이터 목록
        const configList = computed(() =>
            Object.entries(ModelConfig.modelConfigs.value).reduce(
                (acc, [key, values]) => [
                    ...acc,
                    ...values.map((value) => ({ ...value, configType: key })),
                ],
                []
            )
        );

        return {
            configList,
            inputModal,
            editModal,
            addModelConfig,
            editModelConfig,
            deleteModelConfig,
        };
    },
};
</script>
