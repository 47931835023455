<template>
    <teleport to="#destination">
        <base-modal ref="modal" modalClass="modal-md modal-dialog-centered">
            <template #title> Add Model Config </template>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Type</th>
                            <th scope="col">ID</th>
                            <th scope="col">Name</th>
                        </tr>
                    </thead>
                    <tbody class="keys">
                        <tr>
                            <td>
                                <div class="add-config-type-container">
                                    <select
                                        v-model="selectType"
                                        :onchange="selectInputChange"
                                    >
                                        <option value="">Direct input</option>
                                        <option
                                            v-for="oldConfig in configTypeList"
                                            :key="`${oldConfig.configType}-${id}`"
                                            :value="oldConfig.configType"
                                        >
                                            {{ oldConfig.configType }}
                                        </option>
                                    </select>
                                    <input
                                        type="text"
                                        v-model="configType"
                                        placeholder="Enter Item Type"
                                        :disabled="selectType !== ''"
                                    />
                                </div>
                            </td>
                            <td>
                                <input
                                    type="text"
                                    v-model="itemId"
                                    placeholder="Enter Item Id"
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    v-model="name"
                                    placeholder="Enter Item Name"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <template #footer>
                <base-button-with-loading
                    class="w-100"
                    :loading="loading"
                    @confirmed="addModelConfig"
                >
                    Confirm
                </base-button-with-loading>
            </template>
        </base-modal>
    </teleport>
</template>

<style scoped>
.keys input {
    background: #ffffff;
    border: 1.5px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;

    font-size: 15px;
    line-height: 21px;
    letter-spacing: -0.01em;

    padding: 12px;
}

.theme-btn {
    padding: 13.5px;
}

.keys td {
    word-break: break-all;
}

.add-config-type-container {
    width: 100%;
    display: flex;
    flex-flow: row;
}

.add-config-type-container select {
    width: 70%;
}
</style>

<script>
import BaseModal from "@/components/BaseModal.vue";
import { ref } from "vue";

import ModelConfig from "@/compositions/ModelConfig.js";
import Swal from "sweetalert2";

import BaseButtonWithLoading from "@/components/BaseButtonWithLoading.vue";

export default {
    components: { BaseModal, BaseButtonWithLoading },
    setup() {
        const modal = ref(null); // 모델 설정 추가 모달 스위치
        const loading = ref(false);

        const selectType = ref(""); // 모델 타입 select 값
        const configType = ref(""); // 모델 설정 항목 타입
        const itemId = ref(null); // 모델 설정 항목 id
        const name = ref(null); // 모델 설정 항목 이름
        const configTypeList = ref(null);

        // 모델 설정 추가 모달창 화면 띄우기
        const show = (typeList) => {
            typeList = typeList.filter(
                (arr, index, callback) =>
                    index ===
                    callback.findIndex((t) => t.configType === arr.configType)
            );

            configTypeList.value = typeList;
            modal.value.show();
        };

        const hide = () => modal.value.hide(); // 모델 설정 추가 모달창 닫기

        const selectInputChange = (e) => {
            configType.value = event.target.value;
        };

        // 모델 설정 추가
        const addModelConfig = async () => {
            try {
                loading.value = true;

                const { isConfirmed } = await Swal.fire({
                    title: "Add Model Config?",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    confirmButtonColor: "#E36159",
                });

                if (!isConfirmed) return;

                await ModelConfig.addModelConfig({
                    configType: configType.value,
                    itemId: itemId.value,
                    name: name.value,
                });

                configType.value = "";
                itemId.value = "";
                name.value = "";

                hide();
            } catch (err) {
                Swal.fire({
                    title: err.message,
                    icon: "error",
                    confirmButtonColor: "#E36159",
                });
            } finally {
                loading.value = false;
            }
        };

        return {
            modal,
            show,
            hide,
            loading,
            addModelConfig,
            selectInputChange,
            configTypeList,
            selectType,
            configType,
            itemId,
            name,
        };
    },
};
</script>
