<template>
    <section class="details-section">
        <div class="container">
            <div class="back-button">
                <router-link
                    :to="{ path: '/market' }"
                    class="theme-btn-2 d-inline-block"
                    ><i class="fas fa-chevron-left"></i> Back To Model
                    Market</router-link
                >
            </div>
            <div class="row mx-0">
                <div class="col-12 col-md-6 thumbnail">
                    <figure class="m-0">
                        <img class="rounded" :src="modelPost.thumbnailKey" />
                    </figure>
                </div>

                <div class="col-12 col-md-6">
                    <div class="row h-100 align-items-start details">
                        <h1>{{ modelPost.modelName }}</h1>
                        <div class="col-6">
                            <h2>Model ID :</h2>
                            <p>
                                {{ modelPost.modelId }}
                            </p>
                        </div>

                        <div class="col-6">
                            <h2>Model Name :</h2>
                            <p>
                                {{ modelPost.modelName }}
                            </p>
                        </div>
                        <div class="col-6">
                            <h2>OS :</h2>
                            <p>
                                {{ modelPost.os }}
                            </p>
                        </div>
                        <div class="col-6">
                            <h2>Platform :</h2>
                            <p>
                                {{ modelPost.platform }}
                            </p>
                        </div>
                        <div class="col-6" v-if="modelPost.soynetversion">
                            <h2>SoyNet Version</h2>
                            <p>
                                {{ modelPost.soynetversion.toUpperCase() }}
                            </p>
                        </div>
                        <div>
                            <h2>Categories</h2>
                            <p>
                                <category-tag-list
                                    v-model:items="modelPost.categories"
                                />
                            </p>
                        </div>
                        <div>
                            <h2>Link To Download :</h2>
                            <p class="support-link">
                                <a
                                    :href="modelPost.linkToDownload"
                                    target="_blank"
                                    v-if="loginCheck"
                                >
                                    {{ modelPost.linkToDownload }}
                                </a>
                                <a href="/auth/sign-in" v-else>
                                    You can check it after logging in.
                                </a>
                            </p>
                        </div>

                        <hr class="d-block d-md-none" />

                        <div class="row">
                            <div class="col-6">
                                <label class="w-100">
                                    License type :
                                    <select
                                        class="form-select"
                                        v-model="license"
                                    >
                                        <template
                                            v-for="licenseName in modelPost.licenseGroups"
                                        >
                                            <template v-if="isConetentWriter">
                                                <option
                                                    :key="licenseName"
                                                    :value="licenseName"
                                                >
                                                    {{ licenseName }}
                                                </option>
                                            </template>
                                            <template v-else>
                                                <option
                                                    v-if="
                                                        licenseName !==
                                                        'developer'
                                                    "
                                                    :key="licenseName"
                                                    :value="licenseName"
                                                >
                                                    {{ licenseName }}
                                                </option>
                                            </template>
                                        </template>
                                    </select>
                                </label>
                            </div>
                            <div class="col-6">
                                <h2>Support :</h2>
                                <p>
                                    {{ support ? "Yes" : "No" }}
                                </p>
                            </div>
                            <div>
                                <label class="w-100">
                                    Subscription Year
                                    <select
                                        class="form-select"
                                        v-model="subYear"
                                        :disabled="license !== 'standard'"
                                    >
                                        <option
                                            v-for="{
                                                value,
                                                text,
                                            } in modelPost.subscriptionYear"
                                            :key="value"
                                            :value="value"
                                        >
                                            {{ text }}
                                        </option>
                                    </select>
                                </label>
                            </div>
                            <h1 class="sub-total">
                                {{
                                    price != null
                                        ? `$ ${price.toLocaleString()}`
                                        : "Contact Sales"
                                }}
                            </h1>
                        </div>

                        <div class="row mx-0 justify-content-end">
                            <div class="col-6">
                                <base-button-with-loading
                                    class="w-100"
                                    :loading="loading"
                                    @confirmed="addModelToCart"
                                >
                                    Add To Cart
                                </base-button-with-loading>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div ref="contentContainer"></div>
            <viewer class="model-description" ref="contentViewer"></viewer>

            <div
                class="model-performance-container"
                v-if="modelPerformance.githublink"
            >
                <div class="model-performance-form">
                    <h4>Model Perfomance</h4>
                    <div class="model-performance-column">
                        <!-- <div class="model-performance-column-data">
                            <h6>Model Name</h6>
                            <p>{{ modelPost.modelName || "" }}</p>
                        </div> -->
                        <div
                            class="model-performance-column-data"
                            v-if="isConetentWriter"
                        >
                            <h6>Model Code</h6>
                            <p>{{ modelPerformance.modelcode || "" }}</p>
                        </div>
                        <div
                            class="model-performance-column-data"
                            v-if="isConetentWriter"
                        >
                            <h6>Variable Model Way</h6>
                            <p>
                                {{ modelPerformance.variablemodelway || "" }}
                            </p>
                        </div>
                        <!-- <div class="model-performance-column-data">
                            <h6>Model Brief</h6>
                            <p>{{ modelPerformance.modelbrief || "" }}</p>
                        </div> -->
                        <div class="model-performance-column-data">
                            <h6>Model Classification</h6>
                            <p>
                                {{
                                    modelPerformance.modelclassification ||
                                    "None"
                                }}
                            </p>
                        </div>
                        <div class="model-performance-column-data">
                            <h6>Framework</h6>
                            <p>{{ modelPerformance.framework || "" }}</p>
                        </div>
                        <div class="model-performance-column-data">
                            <h6>Code Date</h6>
                            <p>{{ modelPerformance.codedate || "" }}</p>
                        </div>
                        <div class="model-performance-column-data">
                            <h6>Thesis Date</h6>
                            <p>{{ modelPerformance.thesisdate || "" }}</p>
                        </div>
                        <div
                            class="model-performance-column-data"
                            v-if="isConetentWriter"
                        >
                            <h6>Model Specific</h6>
                            <p>
                                {{ modelPerformance.modelspecific || "" }}
                            </p>
                        </div>
                        <div class="model-performance-column-data">
                            <h6>Input Data Type</h6>
                            <p>
                                {{ modelPerformance.inputdatatype || "" }}
                            </p>
                        </div>
                        <div class="model-performance-column-data">
                            <h6>Output Data Dtype</h6>
                            <p>
                                {{ modelPerformance.outputdatadtype || "" }}
                            </p>
                        </div>
                        <div class="model-performance-column-data">
                            <h6>Thesis Link</h6>
                            <a :href="modelPerformance.thesislink">{{
                                modelPerformance.thesislink || ""
                            }}</a>
                        </div>
                        <div class="model-performance-column-data">
                            <h6>Input Data Shape</h6>
                            <p>
                                {{ modelPerformance.inputdatashape || "" }}
                            </p>
                        </div>
                        <div class="model-performance-column-data">
                            <h6>Output Data Shape</h6>
                            <p>
                                {{ modelPerformance.outputdatashape || "" }}
                            </p>
                        </div>
                        <div
                            class="model-performance-column-data"
                            v-if="isConetentWriter"
                        >
                            <h6>Preproc</h6>
                            <p>{{ modelPerformance.preproc || "" }}</p>
                        </div>
                        <div
                            class="model-performance-column-data"
                            v-if="isConetentWriter"
                        >
                            <h6>Postproc</h6>
                            <p>{{ modelPerformance.postproc || "" }}</p>
                        </div>
                        <div
                            class="model-performance-column-data"
                            v-if="isConetentWriter"
                        >
                            <h6>New Layer</h6>
                            <p>{{ modelPerformance.newlayer || "" }}</p>
                        </div>

                        <!-- <div class="model-performance-column-data">
                            <h6>Github Link</h6>
                            <a :href="modelPerformance.githublink">{{
                                modelPerformance.githublink || ""
                            }}</a>
                        </div> -->
                        <div
                            class="model-performance-column-data"
                            v-if="isConetentWriter"
                        >
                            <h6>Pretrained</h6>
                            <p>{{ modelPerformance.pretrained || "" }}</p>
                        </div>
                        <div
                            class="model-performance-column-data"
                            v-if="isConetentWriter"
                        >
                            <h6>Porting Start</h6>
                            <p>{{ modelPerformance.portingstart || "" }}</p>
                        </div>
                        <div
                            class="model-performance-column-data"
                            v-if="isConetentWriter"
                        >
                            <h6>Porting End</h6>
                            <p>{{ modelPerformance.portingend || "" }}</p>
                        </div>
                        <div
                            class="model-performance-column-data"
                            v-if="isConetentWriter"
                        >
                            <h6>Porting Operator</h6>
                            <p>
                                {{ modelPerformance.portingoperator || "" }}
                            </p>
                        </div>
                        <div
                            class="model-performance-column-data"
                            v-if="isConetentWriter"
                        >
                            <h6>Train-code Name</h6>
                            <p>
                                {{ modelPerformance.traincodename || "" }}
                            </p>
                        </div>
                        <div
                            class="model-performance-column-data"
                            v-if="isConetentWriter"
                        >
                            <h6>Inference-code Name</h6>
                            <p>
                                {{ modelPerformance.inferencecodename || "" }}
                            </p>
                        </div>
                        <div
                            class="model-performance-column-data"
                            v-if="isConetentWriter"
                        >
                            <h6>Weight-down-code Name</h6>
                            <p>
                                {{ modelPerformance.weightdowncodename || "" }}
                            </p>
                        </div>
                        <div
                            class="model-performance-column-data"
                            v-if="isConetentWriter"
                        >
                            <h6>Work Code</h6>
                            <p>{{ modelPerformance.workcode || "" }}</p>
                        </div>
                        <div
                            class="model-performance-column-data"
                            v-if="isConetentWriter"
                            :style="'border : none'"
                        ></div>
                    </div>
                </div>
                <div
                    class="model-benchmark-form"
                    v-if="modelPerformance.batch.length !== 0"
                >
                    <h4>Model Benchmark</h4>
                    <table class="model-benchmark-table">
                        <tr>
                            <th>Benchmark Device</th>
                            <td :colspan="benchCount">
                                <p>{{ modelPerformance.benchmarkdevice }}</p>
                            </td>
                        </tr>
                        <tr>
                            <th>Data</th>
                            <td :colspan="benchCount">
                                <p>{{ modelPerformance.data }}</p>
                            </td>
                        </tr>
                        <tr>
                            <th>Batch</th>
                            <td v-for="(value, index) in benchCount">
                                {{ modelPerformance.batch[index]?.batchsize }}
                            </td>
                        </tr>
                        <tr>
                            <th>Precision</th>
                            <td v-for="(value, index) in benchCount">
                                {{ modelPerformance.batch[index]?.precision }}
                            </td>
                        </tr>
                        <tr>
                            <th>Data Size, Length</th>
                            <td v-for="(value, index) in benchCount">
                                {{
                                    modelPerformance.batch[index]
                                        ?.datasizeorlength
                                }}
                            </td>
                        </tr>
                        <tr>
                            <th>SoyNet Fps</th>
                            <td v-for="(value, index) in benchCount">
                                {{ modelPerformance.batch[index]?.soynetfps }}
                            </td>
                        </tr>
                        <tr>
                            <th>SoyNet GPU Memory</th>
                            <td v-for="(value, index) in benchCount">
                                {{
                                    modelPerformance.batch[index]
                                        ?.soynetgpumemory
                                }}
                            </td>
                        </tr>
                        <tr>
                            <th>Pytorch Fps</th>
                            <td v-for="(value, index) in benchCount">
                                {{ modelPerformance.batch[index]?.pytorchfps }}
                            </td>
                        </tr>
                        <tr>
                            <th>Pytorch GPU Memory</th>
                            <td v-for="(value, index) in benchCount">
                                {{
                                    modelPerformance.batch[index]
                                        ?.pytorchgpumemory
                                }}
                            </td>
                        </tr>
                        <tr v-if="isConetentWriter">
                            <th>Benchmark brief</th>
                            <td :colspan="benchCount">
                                <p>{{ modelPerformance.benchmarkbrief }}</p>
                            </td>
                        </tr>
                    </table>
                </div>
                <div
                    class="model-consistency-form"
                    v-if="modelPerformance.consistency.length !== 0"
                >
                    <h4>Model Consistency</h4>
                    <table class="model-consistency-table">
                        <tr>
                            <th>#</th>
                            <th>{{ modelPerformance.framework }}</th>
                            <th>SoyNet</th>
                            <th>Diff</th>
                        </tr>
                        <tr
                            v-for="(value, index) in consistencyCount"
                            class="model-consistency"
                        >
                            <th>{{ index }}</th>
                            <td>
                                <p>
                                    {{
                                        modelPerformance.consistency[index]
                                            ?.framework
                                    }}
                                </p>
                            </td>
                            <td>
                                <p>
                                    {{
                                        modelPerformance.consistency[index]
                                            ?.soynet
                                    }}
                                </p>
                            </td>
                            <td>
                                <p>
                                    {{
                                        modelPerformance.consistency[index]
                                            ?.diff
                                    }}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th>Consistency brief</th>
                            <td :colspan="3">
                                <p>{{ modelPerformance.consistencybrief }}</p>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
.details-section {
    margin-bottom: 100px;
}

.back-button {
    margin: 50px 0;
}

.thumbnail {
    padding: 0 24px 0 0;
}

.thumbnail figure {
    height: 100%;
    width: 100%;
    padding-bottom: 110%;
    position: relative;
}

.thumbnail figure img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 584px;
    object-fit: scale-down;
    /* object-fit: cover; */
}

.model-description {
    margin: 50px 0 0;
}

.details h1 {
    font-weight: bold;
    font-size: 30px;
    line-height: 45px;
    letter-spacing: -0.01em;

    color: #000000;

    margin-bottom: 15px;
}

.details h2 {
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 5px;

    color: #000000;
}

.details p {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 10px;

    color: #000000;
}

.details label {
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 10px;

    color: #000000;
}

.details select {
    margin: 5px 0 0;
}

h1.sub-total {
    font-weight: bold;
    font-size: 40px;
    line-height: 55px;
    letter-spacing: -0.01em;

    color: #000000;
    text-align: end;
    margin: 20px 0 30px;
}

.support-link {
    background: #f1f1f1;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    padding: 14px 15px;
    border-radius: 2px;
}

.model-performance-container {
    margin-top: 150px;
}

.model-performance-container .model-performance-form {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 70px;
}

.model-performance-column {
    /* display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap; */
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 14px;
    place-items: center;
    margin-top: 30px;
}

.model-performance-column-data {
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: center;
    border: 1px solid #ddd;
    margin: 10px 0vw 0 0;
    box-sizing: border-box;
    width: 100%;
    max-width: 360px;
    height: 100%;
    position: relative;
}

.model-performance-column-data:after {
    content: "";
    display: block;
}

.model-performance-column-data h6 {
    width: 25%;
    margin: 0;
    margin-right: 10px;
    font-weight: bold;
}

.model-performance-column-data p,
.model-performance-column-data a {
    width: 75%;
    text-align: right;
    font-size: 14px;
    border: none;
    outline: none;
    word-break: break-all;
    margin: 0;
}

.model-performance-column-data a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.model-benchmark-form {
    margin-top: 100px;
}

.model-benchmark-table,
.model-consistency-table {
    margin-top: 30px;
    width: 100%;
    border: 1px solid #ddd;
}

.model-consistency-form {
    margin-top: 100px;
}

.model-benchmark-table tr,
.model-consistency-table tr {
    border-bottom: 1px solid #ddd;
}

.model-benchmark-table th,
.model-consistency-table th {
    width: 20%;
    background-color: #f8f5f5;
}

.model-consistency-table th {
    width: 20%;
}

.model-benchmark-table th,
.model-benchmark-table td,
.model-consistency-table th,
.model-consistency-table td {
    border-right: 1px solid #ddd;
    padding: 15px 20px;
}

.model-consistency th,
.model-consistency td {
    padding: 5px;
}

.model-benchmark-table p,
.model-consistency-table p {
    width: 100%;
    margin: 0;
    border: none;
    text-align: center;
    word-break: break-all;
}

@media (max-width: 1023px) {
    .model-performance-container {
        display: none;
    }
}

@media (max-width: 767px) {
    .thumbnail {
        margin: 0 0 20px;
        padding: 0;
    }

    .back-button {
        margin: 20px 0;
    }
}

@media (max-width: 576px) {
    .thumbnail {
        max-width: 100vw;
        width: 100vw;
        margin: 0 -17px 20px;
    }

    .thumbnail figure {
        padding-bottom: 76%;
    }

    .details h2 {
        font-size: 14px;
    }

    h1.sub-total {
        font-size: 30px;
        line-height: 45px;
    }

    .details p {
        font-size: 14px;
    }

    .theme-btn,
    .theme-btn-2 {
        padding: 17px 13px;
        font-size: 14px;
    }
}
</style>

<script>
import Swal from "sweetalert2";

import model from "@/compositions/Model.js";
import cart from "@/compositions/Cart.js";
import User from "../../../compositions/User.js";
import BaseButtonWithLoading from "@/components/BaseButtonWithLoading.vue";

import { useRoute, useRouter } from "vue-router";
import { ref, inject, computed, watch } from "vue";

import Viewer from "@/components/Viewer.vue";
import CategoryTagList from "@/components/CategoryTagList.vue";

export default {
    components: {
        Viewer,
        BaseButtonWithLoading,
        CategoryTagList,
    },
    setup() {
        const id = inject("userInfo").value?.email; // 사용자 이메일
        const loading = ref(false); // 로딩 상태
        const loginCheck = ref(null); // 로그인 상태
        const allowedRoles = ["admin", "developer"]; // 관리자 권한 목록
        const userInfo = inject("userInfo"); // 사용자 정보

        const router = useRouter();

        const license = ref(null); // 모델 라이센스
        const subYear = ref(1); // 모델 사용 기간
        const benchCount = ref(1); // benchCount 개수
        const consistencyCount = ref(1); // consistency 개수

        const contentViewer = ref(null); // 모델 markdown
        const route = useRoute(); // Vue Route기능

        // route이름이 market-detail일 경우 ModelPost에 nodelSequence데이터 넣기
        watch(
            () => route.params,
            ({ modelSequence }) =>
                route.name === "market-detail" && initModelPost(modelSequence)
        );

        // Model에 데이터를 받아온다
        const initModelPost = async () => {
            const { modelSequence } = route.params;
            await model.getModelPost(modelSequence);

            // 모델성능 데이터가 있을경우 개수 만큼 count갱신
            if (model.modelPerformance.value) {
                benchCount.value =
                    (await model.modelPerformance.value.batch.length) || 1;
                consistencyCount.value =
                    (await model.modelPerformance.value.consistency.length) ||
                    1;
            }

            license.value = model.modelPost.value.licenseGroups[0];
            subYear.value = model.modelPost.value.subscriptionYear[0].value;

            // markdown데이터
            contentViewer.value.initViewer(model.modelPost.value.markdown);
        };

        initModelPost(); // 함수호출

        // 모델의 가격측정
        const price = computed(() =>
            cart.getBeforePrice({
                license: license.value,
                subYear: subYear.value,
            })
        );

        // support기능을 제공 하는지 체크
        const support = computed(() => license.value !== "free");

        // cart목록에 추가
        const addModelToCart = async () => {
            const { modelSequence } = route.params;
            try {
                // enterprise일 경우 경로 이동
                if (license.value === "enterprise") {
                    router.push("/contact-us");
                    return;
                }

                // 사용자 로그인 확인
                if (id == null) {
                    const { isConfirmed } = await Swal.fire({
                        title: "You shoud log in first.",
                        text: "Would you like to go to Login page?",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        confirmButtonColor: "#E36159",
                    });

                    if (isConfirmed) router.push("/auth/sign-in");
                    return;
                }

                loading.value = true;

                const { isConfirmed } = await Swal.fire({
                    title: "The Item is now added to your cart.",
                    text: "Would you like to review your cart?",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    confirmButtonColor: "#E36159",
                });

                if (!isConfirmed) return;

                await cart.addModelToCart({
                    id,
                    modelSequence,
                    support: support.value,
                    license: license.value,
                    subYear: support.value ? +subYear.value : 1,
                });

                router.push("/cart");
            } catch (err) {
                await Swal.fire({
                    title: err.message,
                    icon: "error",
                    confirmButtonColor: "#E36159",
                });
            } finally {
                loading.value = false;
            }
        };

        // 현재 로그인 상태 확인
        const loginStatus = async () => {
            loginCheck.value = await User.checkLoginState(); // 로그인 상태 체크하는 함수 호출 ex) true/false
        };

        loginStatus(); // 함수 실행

        // 사용자가 관리자 권한이 있는지 체크
        const isConetentWriter = computed(() =>
            allowedRoles.includes(userInfo.value?.authority)
        );
        return {
            contentViewer,
            modelPost: model.modelPost,
            modelPerformance: model.modelPerformance,
            benchCount,
            initModelPost,
            license,
            price,
            support,
            subYear,
            addModelToCart,
            loading,
            loginCheck,
            isConetentWriter,
            consistencyCount,
        };
    },
};
</script>
