<template>
    <section class="partner-section" data-aos="fade-up">
        <div class="container">
            <div class="cont-heading text-center">
                <h1>Partners</h1>
                <h6>
                    We are proud partners with Esteemed organizations to explore
                    new markets.
                </h6>
            </div>
            <div class="row mx-0 justify-content-center">
                <div class="col-sm-4 col-12">
                    <a
                        href="http://www.saltlux.com/en/index.do"
                        target="_blank"
                    >
                        <figure>
                            <img
                                src="@/assets/images/saltlux.png"
                                alt="saltlux"
                            />
                        </figure>
                    </a>
                </div>
                <div class="col-sm-4 col-12">
                    <a
                        href="http://medicalstandard.com/ai_solution_soynet.php"
                        target="_blank"
                    >
                        <figure>
                            <img
                                src="@/assets/images/medicalstandard.png"
                                alt="medical standard"
                            />
                        </figure>
                    </a>
                </div>
                <div class="col-sm-4 col-12">
                    <a href="https://www.seculayer.com/en/" target="_blank">
                        <figure>
                            <img
                                src="@/assets/images/seculayer.png"
                                alt="seculayer"
                            />
                        </figure>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
.partner-section {
    padding-top: 90px;
}

.partner-section span {
    font-weight: bold;
}

.partner-section figure {
    position: relative;
    height: 120px;
    width: 100%;
}

.partner-section img {
    max-width: 90%;
    max-height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
}

.cont-heading h1 {
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
    align-items: center;
    color: #ea5b33;
}

.cont-heading h6 {
    max-width: 427px;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    margin: 0 auto;
    color: #000000;
}

@media screen and (max-width: 576px) {
    .partner-section {
        padding-top: 60px;
    }

    .cont-heading {
        text-align: left !important;
    }

    .cont-heading h1 {
        font-size: 24px;
    }

    .cont-heading h6 {
        margin: 0;
    }
}
</style>

<script>
export default {};
</script>
