<template>
  <div class="container-xxl componentSwitchArea">
    <template
      v-for="(swtichComp, index) in componentSwitchArr"
      :key="swtichComp"
    >
      <button v-if="showCompIndex === index + 1" class="active">
        {{ swtichComp }}
      </button>
      <button v-else @click="changeShowComp(index + 1)">
        {{ swtichComp }}
      </button>
    </template>
  </div>
</template>

<style scoped>
.componentSwitchArea {
  -ms-overflow-style: none;
  text-align: right;
  margin-bottom: 30px;
  overflow: auto;
  white-space: nowrap;
}

.componentSwitchArea::-webkit-scrollbar {
  display: none;
}
button {
  border: none;
  background-color: #fff;
  margin: 0 5px;
}
.active {
  border-bottom: 3px solid #ea5b33 !important;
}
</style>

<script>
import AdminPage from "../../../compositions/AdminPage";
export default {
  setup() {
    // 컴포넌트 이름
    const componentSwitchArr = [
      "ModelConfig",
      "RegisterdModel",
      "UserModelList",
      "RefererCode",
      "UserList"
    ];
    AdminPage.showComponent.value = 1; // 페이지 로드시 보여질 컴포넌트 숫자
    const showCompIndex = AdminPage.showComponent; // admin페이지에서 보여질 컴포넌트

    // 컴포넌트 변경 Input : component 번호
    const changeShowComp = (compIndex) =>
      (AdminPage.showComponent.value = compIndex);
    return {
      showCompIndex,
      changeShowComp,
      componentSwitchArr,
    };
  },
};
</script>
