<template>
  <div class="auth-form">
    <div>
      <div class="d-flex justify-content-between">
        <h1>Reset Password</h1>
        <div class="sign-up-link">
          <p>
            Return to
            <a href="/auth/sign-in">Sign In</a>
          </p>
        </div>
      </div>
      <p>
        Enter the email address associated with your account and we’ll send you
        a link to reset your password.
      </p>
    </div>
    <div>
      <label>
        E-mail
        <input type="text" v-model="email" placeholder="Enter your email" />
      </label>
    </div>
    <div>
      <base-button-with-loading class="w-100" :loading="loading" @confirmed="resetPassword">
        Continue
      </base-button-with-loading>
    </div>
  </div>
</template>

<style scoped>
.auth-form {
  -ms-overflow-style: none;
  position: absolute;
  padding: 15px 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: 100%;
  overflow: auto;
  max-width: 500px;
}

.auth-form::-webkit-scrollbar {
  display: none;
}

.auth-form h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.01em;

  color: #000000;
}

.auth-form p {
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 25px;
}

.auth-form label {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.01em;
  color: #000000;
  margin-bottom: 40px;

  display: block;
}

.auth-form input {
  background: #ffffff;
  border: 1.5px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;

  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.01em;

  padding: 15px;

  margin-top: 5px;
}

.sign-up-link p {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.01em;
  margin: 0;

  color: #000000;
}

.sign-up-link a {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.01em;
  color: #e36159;
  text-decoration: none;
}

@media screen and (max-width: 991px) {
  .auth-form {
    padding: 17px;
  }

  .auth-form h1 {
    font-size: 24px;
    line-height: 30px;
  }
}
</style>

<script>
import { Auth } from "aws-amplify";
import BaseButtonWithLoading from "@/components/BaseButtonWithLoading.vue";

import Swal from "sweetalert2";

export default {
  components: {
    BaseButtonWithLoading,
  },
  data() {
    return {
      email: null, // 사용자 이메일
      loading: false,
    };
  },
  methods: {
    async resetPassword() {
      // 기존 비밀번호 초기화
      try {
        this.loading = true;
        await Auth.forgotPassword(this.email);

        this.$router.push({
          path: "/auth/sign-up-confirm/",
          query: { email: this.email, type: "resetPassword" },
        });
      } catch (err) {
        if (err.message === "Username/client id combination not found.")
          err.message = "User not found.";

        Swal.fire({
          title: err.message,
          icon: "error",
          confirmButtonColor: "#E36159",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
