import { ref, readonly } from "vue";

import User from "./User";
import { setApiEndpoint } from "./setFunction";

const api_hostname = setApiEndpoint(); // api 주소
const API_ENDPOINT = `${api_hostname}/model`; // api endpoint

const models = ref([]); // 모델 정보 전부 뽑아온 데이터
const modelPerformanceData = ref(false); // 모델 성능 전부 뽑아온 데이터
const modelPerformance = ref(false); // 하나의 모델 성능 데이터
const filterModels = ref([]); // 검색단어가 포함된 모델
const modelPost = ref({}); // 모델 상세 데이터

// 모델 목록 뽑아오는 함수, Input : 검색데이터(string)
const listModels = async (searchModel) => {
    const response = await fetch(API_ENDPOINT, { method: "GET" });
    searchModel = searchModel.toLowerCase() || ""; // 검색을 사용하지 않는 페이지에서 에러 방지

    if (response.ok) {
        const responseData = await response.json();
        models.value = responseData[0].Items; // responseData에 첫번째 배열은 모델 정보
        modelPerformanceData.value = responseData[1].Items || false; // responseData에 첫번째 배열은 모델 성능 정보

        // 검색된 단어가 포함된 모델 뽑아오기
        filterModels.value =
            searchModel !== ""
                ? models.value.filter(
                      (modelValue) =>
                          modelValue.modelName
                              .toLowerCase()
                              .indexOf(searchModel) !== -1
                  )
                : models.value;
    }
};

// 모델 Markdown데이터 받아오기, Input : 모델 S3경로
const getContent = async (descriptionKey) => {
    const content = await fetch(descriptionKey, { method: "GET" });

    if (content.ok) {
        return await content.text();
    }
};

// 모델 정보 받아오기, Input : 모델 고유번호
const getModelPost = async (targetSequence) => {
    if (models.value.length < 1) {
        await listModels();
    }

    // 모델 고유번호와 일치하는 모델을 변수에 저장
    const postDetails = models.value.find(
        ({ modelSequence }) => modelSequence === targetSequence
    );

    // 모델 고유번호와 일치하는 모델 성능 데이터 변수 저장, 모델 성능 데이터가 없을경우 false처리
    modelPerformance.value = modelPerformanceData.value
        ? modelPerformanceData.value.find(
              ({ modelSequence }) => modelSequence === targetSequence
          )
        : false;

    // 모델에 해당하는 S3경로 받아오기
    const { descriptionKey } = postDetails;

    const markdown = descriptionKey && (await getContent(descriptionKey));

    modelPost.value = {
        ...postDetails,
        markdown,
    };
};

// 사용자의 모델 사용 기간 연장을 위해 모델 데이터 받아오기, Input : 모델 고유 번호
const getModelInfo = async (modelSequence) => {
    const response = await fetch(`${API_ENDPOINT}/${modelSequence}`, {
        method: "GET",
    });

    if (!response.ok) {
        throw new Error("network error");
    }

    // 서버에서 받아온 모델 정보
    const { Item } = await response.json();

    return Item;
};

// 모델 삭제 Input : 모델 고유 번호
const deleteModel = async (modelSequence) =>
    (
        await fetch(`${API_ENDPOINT}/${modelSequence}`, {
            headers: {
                Authorization: await User.getAccessToken(),
            },
            method: "DELETE",
        })
    ).ok;

// 생성된 모델 정보를 기입, Input : 모델 고유번호, 모델 id, 카테고리, 모델이름 등 모델정보
const putModelInfo = async ({
    modelSequence,
    modelId,
    categories,
    modelName,
    os,
    licenseGroups,
    subscriptionYear,
    platform,
    linkToDownload,
    summary,
    thumbnailKey,
    descriptionKey,
    disableFlag,
    soynetversion,
    modelPerformance,
}) => {
    const response = await fetch(`${API_ENDPOINT}/${modelSequence}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: await User.getAccessToken(),
        },
        body: JSON.stringify({
            modelSequence,
            modelId,
            categories,
            modelName,
            os,
            licenseGroups,
            subscriptionYear,
            platform,
            linkToDownload,
            summary,
            thumbnailKey,
            descriptionKey,
            disableFlag,
            soynetversion,
            modelPerformance,
        }),
    });

    if (!response.ok) {
        throw new Error("network error");
    }
};

export default {
    models: readonly(models),
    modelPost: readonly(modelPost),
    listModels,
    getModelPost,
    getModelInfo,
    deleteModel,
    putModelInfo,
    filterModels,
    modelPerformanceData,
    modelPerformance,
};
