<template>
  <section>
    <div>
      <div class="case-study-main w-100">
        <img :src="caseStudy.thumbnail" alt="">
        <div class="text-center text-white case-study-title">
          <h5>
            Case Study
          </h5>
          <h1>
            {{ caseStudy.customer }}
          </h1>
        </div>
      </div>

      <div class="container">
        <div class="content-title">
          <h1>
            {{ caseStudy.title }}
          </h1>
        </div>
        <viewer ref="contentViewer"></viewer>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .container {
    margin: 100px auto;
  }

  .case-study-main {
    position: relative;
  }

  .case-study-main img {
    width: 100%;
    object-fit: cover;
    object-position: bottom;
    max-height: 470px;
    min-height: 275px;
    filter: brightness(60%);
    z-index: -1;
  }

  .case-study-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
  }

  .case-study-title h1 {
    font-weight: bold;
    font-size: 50px;
    line-height: 75px;
    letter-spacing: -0.01em;

    color: #FFFFFF;
  }

  .case-study-title h5 {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.01em;

    color: #FFFFFF;
  }

  .content-title {
    margin: 0 0 68px;
  }

  .content-title h1 {
    font-weight: 500;
    font-size: 48px;
    text-align: center;
    letter-spacing: -0.01em
  }


  @media screen and (max-width : 576px) {
    .case-study-title h1 {
      font-size: 32px;
      line-height: 48px;
    }

    .content-title h1 {
      font-size: 24px;
    }

    .content-title {
      margin: 0px 0 18px;
    }
  }
</style>
<script>
  import CaseStudy from "@/compositions/CaseStudy.js";

  import Viewer from '@/components/Viewer.vue';

  import { onMounted, ref } from "vue";


  export default {
    components : {
      Viewer
    },
    props: ['id'],
    setup({ id }) {
      const contentViewer = ref(null);

      onMounted(async () => {
        await CaseStudy.getCaseStudy(id);
        
        contentViewer.value.initViewer(CaseStudy.caseStudy.value.markdown);
      });

      return {
        contentViewer,
        caseStudy: CaseStudy.caseStudy
      };
    }
  };
</script>
