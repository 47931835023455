<template>
    <section class="details-section">
      <div class="container">
        <div class="model-performance-container" v-if="modelPerformance" v-for="(model) in modelPerformance">
            <h1>Model Name : {{ model.modelName }}</h1>
          <div class="model-performance-form">
            <h4>Model Perfomance</h4>
            <div class="model-performance-column">
              <div class="model-performance-column-data">
                <h6>Model Name</h6>
                <p>{{ model.modelName || "None" }}</p>
              </div>
              <div class="model-performance-column-data" v-if="isConetentWriter">
                <h6>Model Code</h6>
                <p>{{ model.modelcode || "None" }}</p>
              </div>
              <div class="model-performance-column-data" v-if="isConetentWriter">
                <h6>Variable Model Way</h6>
                <p>{{ model.variablemodelway || "None" }}</p>
              </div>
              <div class="model-performance-column-data">
                <h6>Model Brief</h6>
                <p>{{ model.modelbrief || "None" }}</p>
              </div>
              <div class="model-performance-column-data">
                <h6>Thesis Date</h6>
                <p>{{ model.thesisdate || "None" }}</p>
              </div>
              <div class="model-performance-column-data">
                <h6>Code Date</h6>
                <p>{{ model.codedate || "None" }}</p>
              </div>
              <div class="model-performance-column-data">
                <h6>Model Classification</h6>
                <p>{{ model.modelclassification || "None" }}</p>
              </div>
              <div class="model-performance-column-data" v-if="isConetentWriter">
                <h6>Model Specific</h6>
                <p>{{ model.modelspecific || "None" }}</p>
              </div>
              <div class="model-performance-column-data">
                <h6>Input Data Type</h6>
                <p>{{ model.inputdatatype || "None" }}</p>
              </div>
              <div class="model-performance-column-data">
                <h6>Input Data Shape</h6>
                <p>{{ model.inputdatashape || "None" }}</p>
              </div>
              <div class="model-performance-column-data">
                <h6>Output Data Dtype</h6>
                <p>{{ model.outputdatadtype || "None" }}</p>
              </div>
              <div class="model-performance-column-data">
                <h6>Output Data Shape</h6>
                <p>{{ model.outputdatashape || "None" }}</p>
              </div>
              <div class="model-performance-column-data" v-if="isConetentWriter">
                <h6>Preproc</h6>
                <p>{{ model.preproc || "None" }}</p>
              </div>
              <div class="model-performance-column-data" v-if="isConetentWriter">
                <h6>Postproc</h6>
                <p>{{ model.postproc || "None" }}</p>
              </div>
              <div class="model-performance-column-data" v-if="isConetentWriter">
                <h6>New Layer</h6>
                <p>{{ model.newlayer || "None" }}</p>
              </div>
              <div class="model-performance-column-data">
                <h6>Thesis Link</h6>
                <a :href="model.thesislink">{{ model.thesislink || "None" }}</a>
              </div>
              <div class="model-performance-column-data">
                <h6>Github Link</h6>
                <a :href="model.githublink">{{ model.githublink || "None" }}</a>
              </div>
              <div class="model-performance-column-data">
                <h6>Framework</h6>
                <p>{{ model.framework || "None" }}</p>
              </div>
              <div class="model-performance-column-data" v-if="isConetentWriter">
                <h6>Pretrained</h6>
                <p>{{ model.pretrained || "None" }}</p>
              </div>
              <div class="model-performance-column-data" v-if="isConetentWriter">
                <h6>Porting Start</h6>
                <p>{{ model.portingstart || "None" }}</p>
              </div>
              <div class="model-performance-column-data" v-if="isConetentWriter">
                <h6>Porting End</h6>
                <p>{{ model.portingend || "None" }}</p>
              </div>
              <div class="model-performance-column-data" v-if="isConetentWriter">
                <h6>Porting Operator</h6>
                <p>{{ model.portingoperator || "None" }}</p>
              </div>
              <div class="model-performance-column-data" v-if="isConetentWriter">
                <h6>Train-code Name</h6>
                <p>{{ model.traincodename || "None" }}</p>
              </div>
              <div class="model-performance-column-data" v-if="isConetentWriter">
                <h6>Inference-code Name</h6>
                <p>{{ model.inferencecodename || "None" }}</p>
              </div>
              <div class="model-performance-column-data" v-if="isConetentWriter">
                <h6>Weight-down-code Name</h6>
                <p>{{ model.weightdowncodename || "None" }}</p>
              </div>
              <div class="model-performance-column-data" v-if="isConetentWriter">
                <h6>Work Code</h6>
                <p>{{ model.workcode || "None" }}</p>
              </div>
              <div class="model-performance-column-data" v-if="isConetentWriter" :style="'border : none'">
              </div>
            </div>
          </div>
          <div class="model-benchmark-form" v-if="model.batch.length !== 0">
            <h4>Model Benchmark
            </h4>
            <table class="model-benchmark-table">
              <tr>
                <th>Benchmark Device</th>
                <td :colspan="model.batch.length">
                  <p>{{ model.benchmarkdevice }}</p>
                </td>
              </tr>
              <tr>
                <th>Data</th>
                <td :colspan="model.batch.length">
                  <p>{{ model.data }}</p>
                </td>
              </tr>
              <tr>
                <th>Batch</th>
                <td v-for="(value, index) in model.batch">{{ model.batch[index].batchsize }}</td>
              </tr>
              <tr>
                <th>Precision</th>
                <td v-for="(value, index) in model.batch">{{ model.batch[index].precision }}</td>
              </tr>
              <tr>
                <th>Data Size, Length</th>
                <td v-for="(value, index) in model.batch">{{ model.batch[index].datasizeorlength }}</td>
              </tr>
              <tr>
                <th>SoyNet Fps</th>
                <td v-for="(value, index) in model.batch">{{ model.batch[index].soynetfps }}</td>
              </tr>
              <tr>
                <th>SoyNet GPU Memory</th>
                <td v-for="(value, index) in model.batch">{{ model.batch[index].soynetgpumemory }}</td>
              </tr>
              <tr>
                <th>Pytorch Fps</th>
                <td v-for="(value, index) in model.batch">{{ model.batch[index].pytorchfps }}</td>
              </tr>
              <tr>
                <th>Pytorch GPU Memory</th>
                <td v-for="(value, index) in model.batch">{{ model.batch[index].pytorchgpumemory }}</td>
              </tr>
              <tr v-if="isConetentWriter">
                <th>Benchmark brief</th>
                <td :colspan="model.batch.length">
                  <p>{{ model.benchmarkbrief }}</p>
                </td>
  
              </tr>
  
            </table>
          </div>
          <div class="model-consistency-form" v-if="model.consistency.length !== 0">
            <h4>Model Consistency
            </h4>
            <table class="model-consistency-table">
              <tr>
                <th>#</th>
                <th>{{ model.framework }}</th>
                <th>SoyNet</th>
                <th>Diff</th>
              </tr>
              <tr v-for="(value, index) in  model.consistency" class="model-consistency">
                <th>{{ index }}</th>
                <td>
                  <p>{{ model.consistency[index].framework }} </p>
                </td>
                <td>
                  <p>{{ model.consistency[index].soynet }} </p>
                </td>
                <td>
                  <p>{{ model.consistency[index].diff }} </p>
                </td>
              </tr>
              <tr>
                <th>Consistency brief</th>
                <td :colspan="model.consistency.length">
                  <p>{{ model.consistencybrief }}</p>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div ref="contentContainer"></div>
  
        <viewer class="model-description" ref="contentViewer"></viewer>
      </div>
    </section>
  </template>
  
  <style scoped>

  
  .model-performance-container {
    margin: 30px 0;
    border-top: 1px solid #ddd;
  }

  .model-performance-container h1{
    margin: 30px 0 0;
  }
  
  .model-performance-container .model-performance-form {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 70px;
  
  }
  
  .model-performance-column {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .model-performance-column-data {
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: center;
    width: 33%;
    border: 1px solid #ddd;
    margin: 10px 0vw 0 0;
    /* margin-right: 10px; */
  }
  
  .model-performance-column-data h6 {
    width: 25%;
    margin: 0;
    margin-right: 10px;
    font-weight: bold;
  }
  
  .model-performance-column-data p,
  .model-performance-column-data a {
    width: 75%;
    text-align: right;
    font-size: 14px;
    border: none;
    outline: none;
    word-break: break-all;
    margin: 0;
  }
  
  .model-performance-column-data a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .model-benchmark-table,
  .model-consistency-table {
    margin: 15px 0 30px;
    width: 100%;
    border: 1px solid #ddd;
  }
  
  .model-consistency-form {
    margin-top: 50px;
  }
  
  .model-benchmark-table tr,
  .model-consistency-table tr {
    border-bottom: 1px solid #ddd;
  }
  
  .model-benchmark-table th,
  .model-consistency-table th {
    width: 20%;
    background-color: #f8f5f5;
  }
  
  .model-consistency-table th {
    width: 20%;
  }
  
  .model-benchmark-table th,
  .model-benchmark-table td,
  .model-consistency-table th,
  .model-consistency-table td {
    border-right: 1px solid #ddd;
    padding: 15px 20px;
  }
  
  .model-consistency th, .model-consistency td{
    padding: 5px;
  }
  
  .model-benchmark-table p,
  .model-consistency-table p {
    width: 100%;
    margin: 0;
    border: none;
    text-align: center;
    word-break: break-all;
  }
  
  @media screen and (max-width: 767px) {
    .thumbnail {
      margin: 0 0 20px;
      padding: 0;
    }
  
    .back-button {
      margin: 20px 0;
    }
  }
  
  @media screen and (max-width: 576px) {
    .thumbnail {
      max-width: 100vw;
      width: 100vw;
      margin: 0 -17px 20px;
    }
  
    .thumbnail figure {
      padding-bottom: 76%;
    }
  
    .details h2 {
      font-size: 14px;
    }
  
    h1.sub-total {
      font-size: 30px;
      line-height: 45px;
    }
  
    .details p {
      font-size: 14px;
    }
  
    .theme-btn,
    .theme-btn-2 {
      padding: 17px 13px;
      font-size: 14px;
    }
  }
  </style>
  
  <script>
  import Swal from "sweetalert2";
  
  import model from "@/compositions/Model.js";
  import cart from "@/compositions/Cart.js";
  import User from "../../../compositions/User.js"
  import BaseButtonWithLoading from "@/components/BaseButtonWithLoading.vue";
  
  import { useRoute, useRouter } from "vue-router";
  import { ref, inject, computed, watch } from "vue";
  
  import Viewer from "@/components/Viewer.vue";
  import CategoryTagList from "@/components/CategoryTagList.vue";
  
  export default {
    components: {
      Viewer,
      BaseButtonWithLoading,
      CategoryTagList,
    },
    setup() {
      const loginCheck = ref(null); // 로그인 상태
      const allowedRoles = ["admin", "developer"]; // 관리자 권한 목록
      const userInfo = inject("userInfo"); // 사용자 정보 받아오기
      
      // ModelPost에 정보를 받아오기
      const initModelPost = async () => {
        await model.listModels("");
      };
  
      // 현재 로그인 상태 확인
      const loginStatus = async () => {
        loginCheck.value = await User.checkLoginState(); // 로그인 상태 체크하는 함수 호출 ex) true/false
      }
      
      // 함수 실행
      loginStatus();
      initModelPost();
  
      // 사용자 권한이 관리자 권한인지 확인
      const isConetentWriter = computed(() =>
        allowedRoles.includes(userInfo.value?.authority)
      );

      return {
        modelPost: model.modelPost,
        modelPerformance: model.modelPerformanceData,
        loginCheck,
        isConetentWriter,
      };
    },
  };
  </script>
  