<template>
    <section class="blog-editor-section">
        <div class="container">
            <div class="blogs-title">
                <input
                    type="text"
                    placeholder="Enter Your Title"
                    v-model="title"
                />
                <input
                    type="text"
                    placeholder="Enter Your Url Title"
                    class="blogs-url-title"
                    v-model="urlTitle"
                />
                <div class="thumbnail-uploader">
                    <h1 v-if="thumbnail == null">
                        Upload thumbnail image here
                    </h1>
                    <img v-else :src="thumbnail" />
                    <label>
                        <i class="far fa-plus-square"></i>
                        <input
                            type="file"
                            accept="image/*"
                            @change="previewThumbnail"
                        />
                    </label>
                </div>
            </div>

            <editor
                class="editor"
                ref="editor"
                :uploadImage="uploadImage"
            ></editor>

            <div class="text-end">
                <button class="theme-btn" @click="publish">publish</button>
            </div>
        </div>
    </section>
</template>

<style scoped>
.blog-editor-section {
    margin: 50px 0;
}

.editor {
    margin-bottom: 50px;
}

.blogs-title input,
.thumbnail-uploader {
    background: #ffffff;
    border: 1.5px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;
}

.blogs-title input {
    font-weight: bold;
    font-size: 60px;
    line-height: 70px;
    letter-spacing: -0.01em;

    padding: 15px;
    margin: 15px 0;
}

.blogs-title .blogs-url-title {
    font-weight: bold;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.01em;
    padding: 15px;
    margin: 15px 0;
}

.thumbnail-uploader {
    position: relative;
    padding-bottom: 45%;

    margin: 0 0 15px;
}

.thumbnail-uploader input {
    display: none;
}

.thumbnail-uploader img,
.thumbnail-uploader h1,
.thumbnail-uploader label {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.thumbnail-uploader label,
.thumbnail-uploader h1 {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.thumbnail-uploader label {
    font-size: 60px;
    color: rgba(0, 0, 0, 0);
}

.thumbnail-uploader h1 {
    font-weight: bold;
    font-size: 40px;
    color: #9b9b9b;
}

.thumbnail-uploader label:hover {
    color: #bbbbbb;
    background: rgba(255, 255, 255, 0.8);
}

.thumbnail-uploader img {
    object-fit: cover;
    object-position: center;
}

@media screen and (max-width: 576px) {
    .blogs-title input {
        font-size: 18px;
        line-height: 24px;
    }

    .thumbnail-uploader h1 {
        font-size: 16px;
    }
}
</style>

<script>
import Swal from "sweetalert2";

import blog from "@/compositions/Blog.js";

import Editor from "@/components/Editor.vue";
import { setApiEndpoint } from "@/compositions/setFunction";

const api_hostname = setApiEndpoint(); // api 주소
const API_ENDPOINT = `${api_hostname}/blog`; // api endpoint

export default {
    inject: ["getAccessToken"],
    components: {
        Editor,
    },
    data() {
        const { id } = this.$route.query; // 사용자 이메일
        return {
            title: null, // 제목
            thumbnailFile: null, // 미리보기 사진 파일
            thumbnail: null, // 미리보기 사진
            date: null, // 날짜
            markdown: null, // 마크다운
            id,
            urlTitle: null, // url title 내용
        };
    },
    async mounted() {
        try {
            // post id가 존재할경우 블로그 데이터를 가져온다
            if (this.id != null) {
                await blog.getBlogPost(this.id);

                const { title, thumbnail, markdown, urlTitle } =
                    blog.blogPost.value;

                this.title = title;
                this.thumbnail = thumbnail;
                this.markdown = markdown;
                this.urlTitle = urlTitle;

                // 이미지 파일 불러오게
                const imageRequest = await fetch(this.thumbnail, {
                    headers: {
                        "Cache-Control": "no-cache",
                    },
                });

                if (!imageRequest.ok) {
                    throw new Error("network error");
                }

                this.thumbnailFile = await imageRequest.blob(); // 불러온 파일 데이터
            }
        } finally {
            this.$refs.editor.setMarkdown(this.markdown);
        }
    },
    methods: {
        async uploadImage(imageFile) {
            // 이미지 업로드 Input : 이미지 파일
            const fileName = `image-${+new Date()}.png`; // 저장할 파일 이름

            const urlRequest = await fetch(API_ENDPOINT, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: await this.getAccessToken(),
                },
                body: JSON.stringify({
                    prefix: "images",
                    fileName,
                    ContentType: "image/png",
                }),
            });

            if (!urlRequest.ok) {
                throw new Error("network error");
            }

            const { uploadURL, Key } = JSON.parse(await urlRequest.json());

            // uploadUrl과 Key값을 db에 저장
            const uploadRequest = await fetch(uploadURL, {
                method: "PUT",
                body: imageFile,
            });

            if (!uploadRequest.ok) {
                throw new Error("network error");
            }

            return Key;
        },
        async uploadThumbnail(id) {
            // 미리보기 사진 업로드 Input : post id
            const { type: ContentType } = this.thumbnailFile; // 미리보기 사진 타입
            const fileName = `thumbnail-${+new Date()}.${
                ContentType.split("/")[1]
            }`; // 파일 이름

            const urlRequest = await fetch(API_ENDPOINT, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: await this.getAccessToken(),
                },
                body: JSON.stringify({
                    prefix: id,
                    fileName,
                    ContentType,
                }),
            });

            if (!urlRequest.ok) {
                throw new Error("network error");
            }

            const { uploadURL, Key } = JSON.parse(await urlRequest.json());

            // uploadUrl과 Key값을 db에 저장
            const uploadRequest = await fetch(uploadURL, {
                method: "PUT",
                body: this.thumbnailFile,
            });

            if (!uploadRequest.ok) {
                throw new Error("network error");
            }

            return Key;
        },
        async uploadBlogContent(id) {
            // markdown 업로드
            const content = this.$refs.editor.getContent(); // markdown 데이터

            const urlRequest = await fetch(API_ENDPOINT, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: await this.getAccessToken(),
                },
                body: JSON.stringify({
                    prefix: id,
                    fileName: `content-${+new Date()}.md`,
                    ContentType: "text/markdown; charset=UTF-8",
                }),
            });

            if (!urlRequest.ok) {
                throw new Error("network error");
            }

            const { uploadURL, Key } = JSON.parse(await urlRequest.json());

            const uploadRequest = await fetch(uploadURL, {
                method: "PUT",
                body: content,
            });

            if (!uploadRequest.ok) {
                throw new Error("network error");
            }

            return Key;
        },
        async postBlogToDB({ id, contentKey, thumbnailKey }) {
            // blog Post DB에 저장 Input : post id, markdown Key, 미리보기 파일
            const summary = this.$refs.editor.summary();

            const dbRequest = await fetch(`${API_ENDPOINT}/${id}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: await this.getAccessToken(),
                },
                body: JSON.stringify({
                    title: this.title,
                    contentKey,
                    thumbnailKey,
                    summary,
                    urlTitle: this.urlTitle,
                }),
            });

            if (!dbRequest.ok) {
                throw new Error("network error");
            }
        },
        async publish() {
            try {
                if (this.title == null || this.title.length < 1) {
                    throw new Error("Title is empty");
                }

                if (this.urlTitle == null || this.urlTitle.length < 1) {
                    throw new Error("Url Title is empty");
                }

                if (this.thumbnailFile == null) {
                    throw new Error("thumbnail is missing");
                }

                if (this.$refs.editor.isEmpty()) {
                    throw new Error("Contents are empty");
                }
                await Swal.fire({
                    title: "Publish this post?",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    confirmButtonColor: "#E36159",
                    showLoaderOnConfirm: true,
                    allowOutsideClick: () => !Swal.isLoading(),
                    allowEscapeKey: () => !Swal.isLoading(),
                    preConfirm: async (isConfirmed) => {
                        try {
                            if (!isConfirmed) return;
                            const id = this.id || +new Date();
                            const thumbnailKey = await this.uploadThumbnail(id);
                            const contentKey = await this.uploadBlogContent(id);

                            await this.postBlogToDB({
                                id,
                                thumbnailKey,
                                contentKey,
                            });
                            this.$router.push("/blogs");

                            return true;
                        } catch (err) {
                            Swal.showValidationMessage(
                                `Request failed: ${err}`
                            );
                        }
                    },
                });
            } catch (err) {
                Swal.fire({
                    title: err.message,
                    icon: "error",
                    confirmButtonColor: "#E36159",
                });
            }
        },
        previewThumbnail({
            target: {
                files: [file],
            },
        }) {
            if (file) {
                const reader = new FileReader();

                reader.onload = () => {
                    this.thumbnail = reader.result;
                };

                this.thumbnailFile = file;

                reader.readAsDataURL(file);
            }
        },
    },
};
</script>
