<template>
  <div>
    <p>
      Soynet Co., Ltd. (the “Company”) complies with this following Privacy
      Policy (this “Privacy Policy”) to protect the rights and personal
      information of its data subjects (the “Customer”) pursuant to the Personal
      Information Protection Act and to process the Customer’s complaints
      effectively. This Privacy Policy applies to all of the Company’s services.
      However, if the Company enacts and implements a separate privacy policy
      for any individual service, the separate privacy policy, which will be
      posted on the respective service’s website, shall prevail over this
      Privacy Policy.
    </p>

    <ol>
      <li class="mb-4">
        <span class="fw-bold">
          Purpose of Processing Personal Information
        </span>
        <p>
          The Company processes the Customer’s personal information for the
          following purposes and will not use the Customer’s personal
          information for any other reasons unless it obtains the prior consent
          of the Customer:
          <ul>
            <li>
                [Subscription and management of services, provision of service to the Customer, marketing and advertising, analysis for the improvement of services, etc.] 
            </li>
          </ul>
        </p>
      </li>
      <li class="mb-4">
        <span class="fw-bold">
         Criteria and Method of Collection of Personal Information 
        </span>
        <ol type="a">
            <li>
                    The Company processes the following criteria of personal information:
                <ol >
                    <li>
                        Subscription and Management of Services 
                        <ul>
                            <li>
                                [email address, phone number]
                            </li>
                        </ul>
                    </li>
                    <li>
                        Marketing and advertising
                        <ul>
                            <li>
                             [Google, Facebook, LinkedIn, Naver]
                            </li>
                        </ul>
                    </li>
                    <li>
                        Analysis for the improvement of services 
                        <ul>
                            <li>
                                [Personalized content and recommendation]
                            </li>
                        </ul>
                    </li>
                </ol>
            </li>
            <li>
                Record of the Customer’s use and access of the services may be created automatically and collected when the Customer uses the services. 
            </li>
            <li>
                Method of collecting personal information
                <ul>
                    <li>
                        [Application, website, written forms, telephone, email address]
                    </li>
                </ul>
            </li>       
        </ol>
      </li>
      <li class="mb-4">
          <span class="fw-bold">
            Retention Period and Processing of Personal Information
          </span>
          <ol type="a">
            The Company processes and retains the personal information during the period of retention and use consented by the Customer when the personal information was collected, or the period of retention and use pursuant to the applicable laws and regulations.   
            The specific period of retention and use for the Customer’s personal information are as follows: 
            <ul>
                <li>
                    Personal information collected for the purposes of subscription and management of services, provision of services, marketing and advertising: [1 year after the termination of the Service Agreement or the Customer’s membership.]
                </li>
                <li>
                    Personal information collected to be analyzed for service improvement purposes: [1 year after the termination of the Service Agreement or the Customer’s membership.]
                </li>
                <li>
                    Records on withdrawal of contract or subscription, records of payment and supply of goods, etc. under the Consumer Protection Act in Electronic Commerce: 5 years 
                </li>
            </ul>
          </ol>
      </li>
      <li class="mb-4">
        <span class="fw-bold">
          Provision of Personal Information to Third Parties
        </span>
        <p>
            The Company does not provide the Customer’s personal information to any third-parties.
        </p>
      </li>
      <li class="mb-4">
        <span class="fw-bold">
            Rights and Obligations of Customers and Their Legal Representatives and the Exercise of Rights  
        </span>
        <ol type="a">
            <li>
                The Customer may exercise the following rights related to the protection of their personal information at any time: 
                <ol>
                    <li>
                        Right to request access to any personal information collected
                    </li>
                    <li>
                      Right to request the correction of any inaccurate personal information
                    </li>
                    <li>
                      Right to request the deletion of any personal information 
                    </li>
                    <li>
                      Right to request the suspension of any personal information processing 
                    </li>
                </ol>
            </li>
            <li>
                The Customer may exercise the rights stipulated in Section 6(a) by notifying the Company in writing or through email or facsimile, etc. The Company shall process such requests without delay. 
            </li>
            <li>
                If the Customer requests the correction or deletion of any personal information, the Company will stop using or providing the respective personal information until the information is fully corrected or deleted. 
            </li>
            <li>
                The Customer’s legal representative or any other person delegated by the Customer may exercise the rights stipulated in Section 6(a). In such cases, the Customer must submit a letter of delegation. 
            </li>
        </ol>
      </li>
      <li class="mb-4">
        <span class="fw-bold">
            Destruction of Personal Information 
        </span>
        <ol type="a">
            <li>
                The Company shall destroy all personal information without delay when the period of retention and use of the personal information expires. The procedure and method of destruction of personal information are as follows: 
                <ul>
                    <li>
                        Destruction procedure: The Company shall destroy the Customer’s personal information in accordance with the Company’s internal policy or applicable laws after attaining the purpose of processing or the expiration of the retention period. 
                    </li>
                    <li>
                        Destruction method: The Company shall use technical methods that prevent the reproduction of the personal information to ensure the destruction of all personal information recorded and stored in electronic files. Any personal information recorded and stored on paper shall be destroyed by incineration or through a shredder.  
                    </li>
                </ul>
            </li>
        </ol>
      </li>
      <li class="mb-4">
        <span class="fw-bold">
            Installation, Operation, and Rejection of Automatic Personal Information Collection Devices
        </span>
        <ol type="a">
            <li>
                The Company uses 'cookies' to store and frequently retrieve usage information to provide users with individually customized services. Cookies are a small amount of information sent to the user's PC browser by the server (HTTP), which are used to operate the website, etc., and are also stored in the user's hard disk in computer.
                <ol>
                    <li>
                        Purpose of using cookies: It is used to provide users with optimized information, through identifying the user’s visit and whether or not the user has logged in.  
                    </li>
                    <li>
                        Installation, operation, and rejection of cookies: Users have the option with regards to the installation of cookies. Therefore, the users can accept all cookies via options in the mobile device, order to request for a confirmation each time a cookie is saved, or refuse to save all cookies. 
                        <ul>
                            <li>
                                Setting method (e.g. for Internet Explorer): Tools at the top of the web browser > Internet Options > Personal Information
                            </li>
                        </ul>
                    </li>
                </ol>
            </li>
            <li>
                However, if you refuse to store cookies, it may be difficult to use some services of Company that require login.
            </li>
        </ol>
      </li>
      <li class="mb-4">
        <span class="fw-bold">
            Use and Provision of Personal Information within the Scope Reasonably Related to the Purpose of Collection
        </span>
        <p>
            Within a reasonable scope of the original purpose of collection, the Company may use or provide personal information to a third party without the consent of the Customer after considering each of the following criteria;   
        </p>
        <ul type="a">
            <li>
                Whether the use or provision is related to the original purpose of collection: Judgment is based on whether the original purpose of collection and the purpose of subsequent use or provision is related in terms of their nature or characteristics;  
            </li>
            <li>
                Whether the subsequent use or provision of personal information was foreseeable considering the circumstance in which the personal information was collected or under other practices of personal information processing: Judgment is based on the relationship between the Company and the Customer, the level of technology and the rate of development, and general circumstances (practice) established over a substantial amount of time; 
            </li>
            <li>
                Whether the Customer’s interest is infringed unreasonably: Judgment is based on whether the Customer’s interest is substantially infringed in relation to the subsequent purpose of use and whether any safety measures are taken in consideration of such possibility of infringement; and 
            </li>
            <li>
                Whether necessary safety measures, such as pseudonymization and encryption, have been taken to ensure the safety of the personal information: Judgment is based on whether any safety measures are taken in consideration of possible infringements. 
            </li>
        </ul>
      </li>
      <li class="mb-4">
        <span class="fw-bold">
            Person in Charge of Personal Information Protection 
        </span>
        <ol type="a">
            <li>
                The Company has designated the following person to be in charge of protecting Customer’s Personal Information and handling complaints related to personal information. The contact information for the person in charge of protecting personal information is as follows: 
                <table class="table w-50 p-3">
                    <thead>
                        <tr>
                            <th colspan="2">
                                Person in Charge of Personal Information 
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                Name 
                            </td>
                            <td>
                                Yoo KyungSeok
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Title 
                            </td>
                            <td>
                                CSO
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Email
                            </td>
                            <td>
                                Ks.yoo@soynet.io
                            </td>
                        </tr>
                    </tbody>
                </table>
            </li>
            <li>
                The Customer may contact the person in charge of personal information protection or the department in charge of personal information protection for any personal information-related inquiries, complaints, damage relief, etc., that arises while using the Company’s services.
            </li>
        </ol>
      </li>
      <li class="mb-4">
        <span class="fw-bold">
          Measures to Ensure Safety of Personal Information
        </span>
        <p>
            The Company takes the following technical, administerial, and physical measures to ensure the safety of the Customer’s personal information pursuant to Article 29 of the Personal Information Protection Act: 
        </p>
        <ol type="a">
            <li>
                The Company conducts regular internal audits (quarterly) to secure the stability of its regular internal audits for real-time personal information processing. 
            </li>
            <li>
                The Company has established and shall implement the established internal management plans, periodic employee training, etc.
            </li>
        </ol>
      </li>
      <li class="mb-4">
        <span class="fw-bold">
            Changes to the Privacy Policy 
        </span>
        <p>
            This Privacy Policy shall apply from its Effective Date, and any changes to this Privacy Policy, including the addition, deletion, or modification of its contents under applicable laws or internal policies, shall be notified through a notice posted seven (7) days prior to the effective date of the modified Privacy Policy.  
        </p>
      </li>
    </ol>
    <p>
        (<span class="fw-bold">Addendum</span>)
        <br>
        This Privacy Policy shall come into effect on [1/5/2022] (“Effective Date”). 
    </p>

    <!-- 


 -->
  </div>
</template>
<style scoped>
    li.mb-4::marker {
        font-weight : bold
    }
</style>
<script>
export default {};
</script>
