<template>
  <section class="faq-section" data-aos="fade-up">
    <div class="container">
      <div class="cont-heading text-center">
        <h1>Frequently Asked Questions</h1>
      </div>
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              What is a SoyNet?
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#headingOne"
          >
            <div class="accordion-body">
              <p>
                SoyNet is a high-speed reasoning (execution) engine developed
                based on C/C++ designed to bring speed to your AI Inference and
                help your reduce time to market in AI.
              </p>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              What kind of AI Models SoyNet supports?
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#headingTwo"
          >
            <div class="accordion-body">
              <p>
                SoyNet supports variety of models including computer vision,
                Time series, NLP, GAN etc.
              </p>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              What are the price of SoyNet optimized models?
            </button>
          </h2>
          <div
            id="collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#headingThree"
          >
            <div class="accordion-body">
              <p>
                Most of the famous public models optimized by SoyNet is free of
                cost. However, depending on the environment there can be some
                cost involved in case if it needs additional support from SOYNET
                experts.
              </p>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFour">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              What if I have a model and I want to optimize using SoyNet ?
            </button>
          </h2>
          <div
            id="collapseFour"
            class="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#headingFour"
          >
            <div class="accordion-body">
              <p>
                We are here. If you have your custom models and you want SoyNet
                to optimize it call our Sales team or drop us an email on:
                sales@soynet.io. You have to choose premium plan for custom
                models (Private models).
              </p>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFive">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              My weight parameter has changed how can I update the SoyNet models
              for inference ?
            </button>
          </h2>
          <div
            id="collapseFive"
            class="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#headingFive"
          >
            <div class="accordion-body">
              <p>
                Please refer to the documentation. If you need further support,
                please sign up for Standard (Public Model) or Premium (Private
                Model) plan.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.cont-heading h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  color: #ea5b33;
}

.faq-section {
  padding-top: 180px;
}

.faq-section .accordion {
  padding-top: 60px;
}

.faq-section .accordion .accordion-item {
  border: 0;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 20px;
  margin-top: 20px;
}

.faq-section .accordion .accordion-item .accordion-button {
  padding: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
}

.faq-section .accordion .accordion-item .accordion-button:focus {
  border: 0;
  box-shadow: none;
}

.faq-section .accordion .accordion-item .accordion-button:not(.collapsed) {
  box-shadow: none;
  background-color: transparent;
}

.faq-section .accordion .accordion-item .accordion-body {
  padding-left: 0;
  padding-right: 0;
}

.faq-section .accordion .accordion-item .accordion-body p {
  font-size: 16px;
  line-height: 30px;
  color: #7e7e7e;
}

@media screen and (max-width: 576px) {
  .faq-section {
    padding-top: 60px;
  }

  .faq-section .accordion {
    padding-top: 30px;
  }

  .cont-heading {
    text-align: left !important;
  }

  .cont-heading h1 {
    font-size: 24px;
    letter-spacing: -0.01em;
  }

  .faq-section .accordion .accordion-item .accordion-button {
    font-size: 14px;
  }

  .faq-section .accordion .accordion-item .accordion-body p {
    font-size: 14px;
  }
}
</style>

<script>
export default {};
</script>
