<template>
    <section class="blog-post-section">
        <div class="container blog-post">
            <div class="back-button">
                <router-link
                    :to="{ path: '/blogs' }"
                    class="theme-btn-2 d-inline-block"
                    ><i class="fas fa-chevron-left"></i> Back To
                    Blogs</router-link
                >
            </div>

            <div class="blog-title">
                <p class="blog-post-date">
                    {{
                        new Date(+id).toLocaleDateString("en-US", {
                            month: "long",
                            year: "numeric",
                            day: "2-digit",
                        })
                    }}
                </p>
                <h1 class="blog-post-title">
                    {{ blogPost.title }}
                </h1>

                <img
                    class="blog-post-image rounded"
                    :src="blogPost.thumbnail"
                />
            </div>

            <viewer ref="contentViewer"></viewer>
        </div>
    </section>
</template>

<style scoped>
.blog-title {
    margin: 50px 0;
}

.blog-post-date {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.01em;
    margin: 0;

    color: #b3b3b3;
}

.blog-post-title {
    font-weight: bold;
    font-size: 60px;
    line-height: 80px;
    letter-spacing: -0.01em;

    color: #000000;
}

.blog-post-image {
    width: 100%;
    object-fit: cover;
}

.blog-post-section {
    margin: 50px 0 80px;
    min-height: 80vh;
}

.blog-post {
    width: 100%;
    max-width: 936px;
    margin: 30px auto;
}

.back-button {
    margin: 50px 0;
}

.theme-btn-2 {
    padding: 17.5px 13px;
}

@media screen and (max-width: 576px) {
    .blog-post-title {
        font-size: 18px;
        line-height: 24px;
    }

    .blog-post-section {
        margin: 20px 0 45px;
    }

    .blog-post {
        margin: 20px auto;
    }

    .back-button {
        margin-bottom: 20px;
    }

    .theme-btn-2 {
        padding: 17.5px 13px;
    }
}
</style>
<script>
import blog from "@/compositions/Blog.js";

import Viewer from "@/components/Viewer.vue";

import { useRoute } from "vue-router";

import { onMounted, ref } from "vue";

export default {
    components: {
        Viewer,
    },
    setup() {
        const { id } = useRoute().query;
        const contentViewer = ref(null); // blog markdown 값
        const searchURL = new URL(window.location);
        window.history.pushState({}, "", searchURL);

        onMounted(async () => {
            await blog.getBlogPost(id); // blog post 조회

            contentViewer.value.initViewer(blog.blogPost.value.markdown);
        });

        return {
            contentViewer,
            blogPost: blog.blogPost,
        };
    },
};
</script>
