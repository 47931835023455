<template>
  <div ref="viewerContainer"></div>
</template>

<style>
.toastui-editor-contents {
  font-size: 18px !important;
  color: #000000;
  font-family: fieldwork, sans-serif;
}

.toastui-editor-contents img {
  margin: 4px auto 10px !important;
  display: block;
}

@media screen and (max-width: 576px) {
  .toastui-editor-contents {
    font-size: 14px !important;
  }
}
</style>

<script>
import Viewer from "@toast-ui/editor/dist/toastui-editor-viewer";
import uml from "@toast-ui/editor-plugin-uml";

import codeSyntaxHighlight from "@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight-all.js";

import { ref } from "vue";

export default {
  setup() {
    const viewerContainer = ref(null);

    const initViewer = (initialValue) => {
      new Viewer({
        el: viewerContainer.value,
        initialValue,
        plugins: [uml, codeSyntaxHighlight],
      });
    };
    return {
      viewerContainer,
      initViewer,
    };
  },
};
</script>
