<template>
    <section class="support-section">
        <div class="container">
            <div class="heading-cont">
                <h1>SoyNet Supports a Variety of AI Models</h1>
                <h6>
                    SoyNet’s software-based inference accelerator platform
                </h6>
            </div>
            <figure>
                <img class="w-100" src="@/assets/images/model_market_bottom.png">
            </figure>
        </div>
    </section>
</template>

<style scoped>
    .support-section {
        margin-top: 100px;
    }

    .heading-cont h1 {
        font-weight: bold;
        font-size: 36px;
        line-height: 54px;
        letter-spacing: -0.01em;
        color: #E36159;
    }

    .heading-cont h6 {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: #000000;
    }

    figure {
        margin-bottom: 120px;
    }

    @media screen and (max-width:576px) {
        .support-section {
            margin-top: 60px;
        }

        .heading-cont h1 {
            font-size: 24px;
        }

        .heading-cont h6 {
            font-size: 14px;
        }

        figure {
            margin-bottom: 70px;
        }

    }
</style>

<script>
    export default {};
</script>
