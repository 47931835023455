<template>
  <div class="auth-form">
    <div>
      <h1>
        {{ titleText }}
      </h1>
      <p>
        The {{ codeText }} has sucessfully sent to
        <strong> {{ email }} </strong>.
      </p>
    </div>
    <div>
      <label>
        {{ codeText }}
        <input
          type="text"
          v-model="code"
          placeholder="Enter confirmaition code"
        />
      </label>
    </div>
    <div>
      <base-button-with-loading
        class="w-100"
        :loading="loading"
        @confirmed="confirmCode"
      >
        Confirm
      </base-button-with-loading>
    </div>
  </div>
</template>

<style scoped>
.auth-form {
  -ms-overflow-style: none;
  position: absolute;
  padding: 15px 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: 100%;
  overflow: auto;
  max-width: 500px;
}

.auth-form::-webkit-scrollbar {
  display: none;
}

.auth-form h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.01em;

  color: #000000;
}

.auth-form p {
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 25px;
}

.auth-form label {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.01em;
  color: #000000;
  margin-bottom: 15px;

  display: block;
}

.auth-form input {
  background: #ffffff;
  border: 1.5px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;

  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.01em;

  padding: 15px;

  margin-top: 5px;
}

@media screen and (max-width: 991px) {
  .auth-form {
    padding: 17px 17px;
  }

  .auth-form h1 {
    font-size: 24px;
    line-height: 30px;
  }
}
</style>

<script>
import { Auth } from "aws-amplify";

import Swal from "sweetalert2";
import BaseButtonWithLoading from "@/components/BaseButtonWithLoading.vue";

export default {
  components: {
    BaseButtonWithLoading,
  },
  data() {
    const { email, type } = this.$route.query; //query에서 받아온 데이터

    const { titleText, codeText, confirmAction } =
      {
        resetPassword: {
          // 비밀번호 초기화
          titleText: "Reset Password",
          codeText: "Reset code",
          confirmAction: async () => {
            const tempPassword = Math.random().toString(36).substr(2, 8); // 임시 비밀번호
            await Auth.forgotPasswordSubmit(
              this.email,
              this.code,
              tempPassword
            );

            this.$router.push({
              path: "/auth/new-password",
              query: { email: this.email, tempPassword },
            });
          },
        },
        emailConfirmation: {
          // 이메일 인증
          titleText: "Confirm your email",
          codeText: "Confirmation code",
          confirmAction: async () => {
            await Auth.confirmSignUp(this.email, this.code);
            this.$router.push("/thank-you/sign-up");
          },
        },
      }[type] || {};

    return {
      code: null,
      email,
      type,
      titleText,
      codeText,
      confirmAction,
      loading: false,
    };
  },
  methods: {
    async confirmCode() {
      try {
        this.loading = true;
        await this.confirmAction();
      } catch (err) {
        Swal.fire({
          title: err.message,
          icon: "error",
          confirmButtonColor: "#E36159",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
