<template>
  <article>
    <section class="contact-us-section">
      <div class="contact-us-main w-100">
        <img src="@/assets/images/contact-main.png" alt="" />
      </div>
      <div class="text-center text-white w-100 contact-title">
        <h1>Fill in the query and we will reach out</h1>
      </div>

      <div class="container">
        <div class="position-relative contact-us-form">
          <div class="input-cont">
            <label>
              Full Name
              <input type="text" placeholder="Enter your name" v-model="name" />
            </label>
          </div>
          <div class="input-cont">
            <label>
              E-mail
              <input
                type="email"
                placeholder="Enter your e-mail"
                v-model="email"
              />
            </label>
          </div>
          <div class="input-cont">
            <label>
              Phone Number
              <input
                type="text"
                placeholder="Enter your Phone Number"
                v-model="phoneNumber"
              />
            </label>
          </div>
          <div class="input-cont">
            <label>
              Message
              <textarea
                placeholder="Enter your message"
                rows="8"
                v-model="message"
              ></textarea>
            </label>
          </div>
          <button class="theme-btn w-100" @click="sendEmail">Send</button>
          <div class="sns-icons">
            <a href="https://www.linkedin.com/company/soynet" target="_blank">
              <i class="fab fa-linkedin-in"></i>
            </a>
            <a href="https://www.facebook.com/soynet.io" target="_blank">
              <i class="fab fa-facebook-f"></i>
            </a>
            <a
              href="https://www.youtube.com/watch?v=pAy8uVSIYo8&t=31s&ab_channel=SOYNET"
              target="_blank"
            >
              <i class="fab fa-youtube"></i>
            </a>
            <a
              href="https://join.slack.com/t/soynet-quj8418/shared_invite/zt-190hfbrrq-Z6m_zEGF6d7MlzIrBiBg2g"
              target="_blank"
            >
              <i class="fab fa-slack"></i>
            </a>
            <a href="mailto:sales@soynet.io">
              <i class="fas fa-envelope"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
    <iframe
      class="google-map"
      loading="lazy"
      allowfullscreen
      :src="`https://www.google.com/maps/embed/v1/place?language=en&key=${API_KEY}&q=${mapQuery}`"
    >
    </iframe>
  </article>
</template>

<style scoped>
.contact-us-main {
  margin-bottom: 50px;
}

.contact-us-main img {
  width: 100%;
  object-fit: cover;
  object-position: 50% 75%;
  max-height: 470px;
  min-height: 275px;
  z-index: -1;

  filter: brightness(90%);
}

.contact-title h1 {
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: -0.01em;

  color: #000000;
}

.contact-us-form {
  max-width: 520px;
  margin: 70px auto 90px;
}

.input-cont {
  margin-bottom: 20px;
}

.input-cont label {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.01em;
  color: #000000;
  margin-bottom: 15px;

  display: block;
}

.input-cont input,
.input-cont textarea {
  background: #ffffff;
  border: 1.5px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;

  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.01em;

  padding: 15px;

  margin-top: 5px;
}

.input-cont input:focus,
.input-cont textarea:focus {
  outline: none;
}

.input-cont textarea {
  resize: none;
}

.google-map {
  height: 55vh;
  width: 100%;
  border: 0;
}

.sns-icons {
  margin-top: 50px;
  text-align: center;
}

.sns-icons a {
  display: inline-block;
  text-decoration: none;
  margin-right: 10px;
}

.sns-icons i {
  color: white;
  font-size: 20px;
  background: #e36159;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 576px) {
  .contact-title h1 {
    font-size: 24px;
    line-height: 32px;
  }

  .contact-us-form {
    margin: 42px auto 70px;
  }
}
</style>

<script>
import Swal from "sweetalert2";
import { setApiEndpoint } from "@/compositions/setFunction";

const api_hostname = setApiEndpoint();
const API_KEY = "AIzaSyBCHmJZQP6u1Houz6Az2AVH9UIkbIKzZc8";
const mapQuery = "SOYNET";

const EMAIL_ENDPOINT =
  `${api_hostname}/inquiry`;

export default {
  data() {
    return {
      name: null,
      email: null,
      message: null,
      phoneNumber: null,
      API_KEY,
      mapQuery,
    };
  },
  methods: {
    async sendEmail() {
      try {
        if (this.name == null) throw new Error("Name is Empty");
        if (this.email == null) throw new Error("Email is Empty");
        if (this.phoneNumber == null) throw new Error("Phone Number is Empty");
        if (this.message == null) throw new Error("No Message");

        const { isConfirmed } = await Swal.fire({
          title: "Would you like to send Message?",
          showCancelButton: true,
          confirmButtonText: "Yes",
          confirmButtonColor: "#E36159",
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !Swal.isLoading(),
          allowEscapeKey: () => !Swal.isLoading(),
          preConfirm: async (isConfirmed) => {
            try {
              if (!isConfirmed) return;
              const response = await fetch(EMAIL_ENDPOINT, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  name: this.name,
                  message: this.message,
                  email: this.email,
                  phoneNumber: this.phoneNumber,
                }),
              });

              if (!response.ok) {
                throw new Error("Network Error");
              }

              this.name = null;
              this.email = null;
              this.message = null;
              this.phoneNumber = null;
              return true;
            } catch (err) {
              Swal.showValidationMessage(`Request failed: ${err}`);
            }
          },
        });

        if (isConfirmed) this.$router.push("/thank-you/contact-us");
      } catch (err) {
        Swal.fire({
          title: err.message,
          icon: "error",
          confirmButtonColor: "#E36159",
        });
      }
    },
  },
};
</script>
