<template>
    <article>
        <blogs-main-section />
        <!--<blogs-card-section />-->
    </article>
</template>

<script>
import BlogsMainSection from './BlogsMainSection.vue';
// import BlogsCardSection from './BlogsCardSection.vue';

export default {
    components: {
        BlogsMainSection,
        // BlogsCardSection
    },
};
</script>
