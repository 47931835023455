<template>
    <div class="modal" ref="modal" tabindex="-1">
        <div class="modal-dialog" :class="modalClass">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <slot name="title"> </slot>
                    </h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        :on-click="hide"
                    ></button>
                </div>
                <div class="modal-body">
                    <slot> </slot>
                </div>
                <div class="modal-footer">
                    <slot name="footer">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            data-bs-dismiss="modal"
                        >
                            Ok
                        </button>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { Modal } from "bootstrap";

export default {
    props: ["modalClass"],
    setup() {
        const modal = ref(null);
        const modalInstance = ref(null);

        onMounted(() => (modalInstance.value = new Modal(modal.value)));

        const show = () => modalInstance.value.show();
        const hide = () => modalInstance.value.hide();

        return {
            modal,
            show,
            hide,
        };
    },
};
</script>
